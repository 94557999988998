import styled from 'styled-components';
import { Colors, Fonts } from '@whispir/ui-lib-v2';

export const EmptyStatePlaceholder = styled.div<{ isOver: boolean }>`
  ${Fonts.body}
  display: flex;
  background-color: ${({ isOver }) =>
    isOver ? Colors.accent_3 : Colors.accent_1};
  color: ${Colors.accent_5};
  width: 100%;
  height: 96px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
