import { v4 as uuid } from 'uuid';
import { kebabCase } from 'lodash';
import { SocialIconsOptionsPickerFieldsProps } from '@whispir/ui-lib-v2';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { getPngImagePath } from '../../../utils/imageUtils';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import {
  StyledIconWrapper,
  StyledIconRoundSection,
  StyledIconsSection,
  StyledCustomImage,
  emailIconDimension,
  gridGapRow,
  gridGapColumn,
  ICON_ROUND_PADDING,
  StyledIconStyleMenu,
  ICON_PADDING,
} from './DisplaySocialIcons.styles';
import { IconCase } from './DisplaySocialIcons.support';

export type SocialIconsDataTypes = KnownContentTypeAndVersionMap['display-social-icons']['1'];

type IconTypes =
  | 'Facebook'
  | 'TikTok'
  | 'Instagram'
  | 'LinkedIn'
  | 'Twitter'
  | 'WeChat'
  | 'WhatsApp'
  | 'Youtube'
  | 'Website'
  | 'Email'
  | 'Discord'
  | 'Pinterest'
  | 'Vimeo'
  | 'Reddit'
  | 'Custom Icon';

type IconStyleTypes =
  | 'colour-filled'
  | 'light-filled'
  | 'dark-filled'
  | 'colour-squares'
  | 'light-squares'
  | 'dark-squares'
  | 'light-icons'
  | 'dark-icons';

type IconHolderProps = {
  data: SocialIconsOptionsPickerFieldsProps['item'];
} & SocialIconsDataTypes;

type SvgViewerProps = IconHolderProps & {
  loadIcon: IconTypes;
};

type IconStyleMenuItemProps = {
  label: string;
  iconName: IconTypes;
  iconStyle: IconStyleTypes;
};

function handleLoadIcon<T>(
  data: SocialIconsOptionsPickerFieldsProps['item'],
  imageViewer: () => T,
  svgViewer: (loadIcon: IconTypes) => T,
): T {
  let loadIcon = data.selectedIcon || 'Website';
  if (loadIcon === 'Custom Icon') {
    if (data.imageSourceText) {
      return imageViewer();
    } else {
      loadIcon = 'Website';
    }
  }
  return svgViewer(loadIcon as IconTypes);
}

const addClassToMenuItemIcon = (iconStyle) => {
  if (iconStyle === 'light-icons' || iconStyle === 'dark-icons') {
    return 'styled-icon-round-section--transparent';
  }

  if (iconStyle === 'light-filled' || iconStyle === 'light-squares') {
    return 'styled-icon-round-section--outline';
  }
};

const formatUrl = (urlText: string) => {
  const reHttp = /^(https?:)?\/\//;
  const reOnlySlash = /^\//;
  return reHttp.test(urlText)
    ? urlText
    : reOnlySlash.test(urlText)
    ? `/${urlText}`
    : `//${urlText}`;
};

const getUrlFormat = (data: IconHolderProps['data']) => {
  const { selectedIcon, urlText = '' } = data;
  if (selectedIcon === 'Email') {
    return `mailto:${urlText}`;
  }
  return formatUrl(urlText);
};

const IconStyleMenuItem = ({
  label,
  iconName,
  iconStyle,
}: IconStyleMenuItemProps) => {
  const scale = 20;
  return (
    <>
      <StyledIconStyleMenu>
        <span>{label}</span>
        <StyledIconRoundSection
          iconStyle={iconStyle}
          scale={scale}
          selectedIcon={iconName}
          className={
            'styled-icon-round-section ' + addClassToMenuItemIcon(iconStyle)
          }
        >
          <IconCase
            iconName={iconName}
            title={iconName}
            name={iconName + uuid()}
          />
        </StyledIconRoundSection>
      </StyledIconStyleMenu>
    </>
  );
};

const ImageViewer = (props: IconHolderProps) => {
  const { data, spacing, iconStyle } = props;
  return (
    <StyledIconWrapper
      spacing={spacing}
      href={getUrlFormat(data)}
      className="icon-wrapper"
    >
      <StyledCustomImage
        style={{
          width: `${props.scale + ICON_ROUND_PADDING * 2}px`,
          height: `${props.scale + ICON_ROUND_PADDING * 2}px`,
          borderRadius: [
            'colour-squares',
            'light-squares',
            'dark-squares',
          ].some((s) => iconStyle === s)
            ? '0%'
            : '50%',
        }}
        src={data.imageSourceText}
        alt={data.altText}
        title={data.altText}
      />
    </StyledIconWrapper>
  );
};

const SvgViewer = (props: SvgViewerProps) => {
  const { loadIcon, data, scale, spacing, iconStyle } = props;
  return (
    <StyledIconWrapper
      spacing={spacing}
      href={getUrlFormat(data)}
      className="icon-wrapper"
    >
      <StyledIconRoundSection
        iconStyle={iconStyle}
        scale={scale}
        selectedIcon={loadIcon}
      >
        <IconCase
          iconName={
            loadIcon === 'Pinterest' ? `${loadIcon}-${iconStyle}` : loadIcon
          }
          title={data.altText}
          name={loadIcon + data.id}
        />
      </StyledIconRoundSection>
    </StyledIconWrapper>
  );
};

const IconHolder = (props: IconHolderProps) => {
  const { data } = props;

  return handleLoadIcon<JSX.Element>(
    data,
    () => <ImageViewer {...props} />,
    (loadIcon) => <SvgViewer {...props} loadIcon={loadIcon} />,
  );
};

export const DisplaySocialIconsContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-social-icons'
> = {
  type: 'display-social-icons',
  label: 'Social',
  icon: 'SocialNew',
  versions: {
    '1': {
      JsxFunction: ({ contentItem }) => {
        const { data } = contentItem;
        const {
          backgroundColor,
          socialIcons,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          spacing,
        } = data;

        return (
          <ContentContainer
            style={{
              paddingTop: Math.abs(paddingTop - gridGapRow(spacing)),
              paddingBottom: Math.abs(paddingBottom - gridGapRow(spacing)),
              paddingLeft: Math.abs(paddingLeft - gridGapColumn(spacing)),
              paddingRight: Math.abs(paddingRight - gridGapColumn(spacing)),
              backgroundColor,
              overflow: 'hidden',
            }}
          >
            <StyledIconsSection {...data}>
              {socialIcons.map((socialIcon) => (
                <IconHolder
                  {...data}
                  data={socialIcon}
                  key={kebabCase(socialIcon.label + socialIcon.id)}
                />
              ))}
            </StyledIconsSection>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem: { data },
        } = props;
        const {
          socialIcons,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          spacing,
          scale,
          iconStyle,
          backgroundColor,
          iconAlign,
        } = data;

        const gridGapRowSpacing = gridGapRow(spacing);
        const gridGapColumnSpacing = gridGapColumn(spacing);
        const applyEmailPadding = (padding, spacing) =>
          Math.abs(padding - spacing);
        const iconDimension = emailIconDimension(scale, ICON_ROUND_PADDING);
        const appliedPaddingTop = applyEmailPadding(
          paddingTop,
          gridGapRowSpacing,
        );
        const appliedPaddingBottom = applyEmailPadding(
          paddingBottom,
          gridGapRowSpacing,
        );
        const appliedPaddingLeft = applyEmailPadding(
          paddingLeft,
          gridGapColumnSpacing,
        );
        const appliedPaddingRight = applyEmailPadding(
          paddingRight,
          gridGapColumnSpacing,
        );

        const renderImage = (socialIcon, isCustomIcon) => `
          <mj-social-element 
            css-class="img-center-cover"
            src="${
              isCustomIcon
                ? socialIcon.imageSourceText
                : getPngImagePath(
                    socialIcon.selectedIcon,
                    iconStyle,
                    scale,
                    'email-social-icons',
                  )
            }" 
            alt="${socialIcon.altText}" 
            title="${socialIcon.altText}" 
            href="${getUrlFormat(socialIcon)}"
            icon-size="${iconDimension}px"
            line-height=".8" 
            border-radius="${
              ['colour-squares', 'light-squares', 'dark-squares'].some(
                (s) => iconStyle === s,
              )
                ? '0px'
                : '' + iconDimension / 2 + 'px'
            }" 
            padding="${ICON_PADDING + gridGapRowSpacing}px ${
          ICON_PADDING + gridGapColumnSpacing
        }px"
          />
        `;

        const getIcons = socialIcons?.map((socialIcon) => {
          const iconHtml = handleLoadIcon<string>(
            socialIcon,
            () => {
              return renderImage(socialIcon, true);
            },
            (loadIcon) => {
              socialIcon.selectedIcon = loadIcon;
              return renderImage(socialIcon, false);
            },
          );

          return iconHtml;
        });
        return `
            <mj-social
              padding-top='${appliedPaddingTop}px'
              padding-bottom='${appliedPaddingBottom}px'
              padding-left='${appliedPaddingLeft}px'
              padding-right='${appliedPaddingRight}px'
              container-background-color=${backgroundColor}
              align="${iconAlign}"
              inner-padding="0px"
            >
              ${getIcons}
            </mj-social>
        `;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        fullWidth: false,
        iconStyle: 'colour-filled',
        iconAlign: 'center',
        scale: 32,
        socialIcons: [
          {
            id: uuid(),
            title: 'Social Icon',
            selectedIcon: 'Facebook',
            value: true,
            urlText: 'facebook.com/',
            label: '',
            urlTextAdornment: 'https://',
            altText: 'Facebook',
          },
          {
            id: uuid(),
            title: 'Social Icon',
            selectedIcon: 'LinkedIn',
            value: true,
            urlText: 'linkedin.com/',
            label: '',
            urlTextAdornment: 'https://',
            altText: 'LinkedIn',
          },
          {
            id: uuid(),
            title: 'Social Icon',
            selectedIcon: 'Twitter',
            value: true,
            urlText: 'twitter.com/',
            label: '',
            urlTextAdornment: 'https://',
            altText: 'Twitter',
          },
        ],
        spacing: 10,
      }),
      PropertiesPanel: (props) => {
        const {
          contentItem: {
            data: { fullWidth },
          },
        } = props;
        const getSubMenuItem = (label, name) => ({
          label: (
            <IconStyleMenuItem
              label={label}
              iconName="Facebook"
              iconStyle={name}
            />
          ),
          value: name,
        });

        return generateControls(props, [
          {
            label: 'STYLE',
            controlsConfig: {
              iconStyle: {
                controlType: 'dropdown',
                contentDataKey: 'iconStyle',
                data: {
                  label: 'Icon Style',
                  options: [
                    getSubMenuItem('Colour Filled', 'colour-filled'),
                    getSubMenuItem('Light Filled', 'light-filled'),
                    getSubMenuItem('Dark Filled', 'dark-filled'),
                    getSubMenuItem('Colour Squares', 'colour-squares'),
                    getSubMenuItem('Light Squares', 'light-squares'),
                    getSubMenuItem('Dark Squares', 'dark-squares'),
                    getSubMenuItem('Light Icons', 'light-icons'),
                    getSubMenuItem('Dark Icons', 'dark-icons'),
                  ],
                },
              },
              scale: {
                controlType: 'slider',
                contentDataKey: 'scale',
                data: {
                  label: 'Scale',
                  minValue: 20,
                  maxValue: 100,
                  step: 1,
                },
              },
              spacing: {
                controlType: 'slider',
                contentDataKey: 'spacing',
                data: {
                  label: 'Spacing',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                },
              },
              fullWidth: {
                controlType: 'boolean',
                contentDataKey: 'fullWidth',
                data: {
                  label: 'Full Width',
                },
              },
            },
          },
          {
            label: 'Options',
            controlsConfig: {
              socialIcons: {
                controlType: 'social-icons-options-picker',
                contentDataKey: 'socialIcons',
                data: {
                  label: 'SOCIAL ICONS',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              ...(!fullWidth && {
                iconAlign: {
                  controlType: 'alignment-selector',
                  contentDataKey: 'iconAlign',
                  data: {
                    label: 'Alignment',
                    variant: 'items',
                  },
                },
              }),
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
