import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { useLoading } from './useLoading';

export const useGetContent = ({ api }: { api: AxiosInstance }) => {
  const getContent = useCallback(
    async (body) => {
      const { data } = await api.get(`/content/${body.contentId}`);

      return data;
    },
    [api],
  );

  return useLoading(getContent);
};
