import styled, { css } from 'styled-components';
import { Direction } from '../utils/types';

export const VerticalWrapper = styled.div<{
  position: Direction;
}>`
  ${(p) =>
    (p.position === 'top' || p.position === 'bottom') &&
    css`
      position: relative;
    `}

  > *:first-child {
    position: absolute;

    ${(p) => {
      if (p.position === 'bottom')
        return css`
          bottom: -7px;
        `;

      if (p.position === 'left')
        return css`
          left: -7px;
          top: 0;
        `;

      if (p.position === 'right')
        return css`
          right: -7px;
          top: 0;
        `;

      return css`
        top: -7px;
      `;
    }}
  }
`;

export const HorizontalWrapper = styled.div<{ position: Direction }>`
  position: relative;

  > *:first-child {
    position: absolute;

    ${(p) => {
      if (p.position === 'bottom')
        return css`
          bottom: -7px;
        `;

      return css`
        top: -7px;
      `;
    }}
  }
`;

export const ChildrenWrapper = styled.div`
  width: 100%;
`;
