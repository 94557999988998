import { Icon, Tooltip } from '@whispir/ui-lib-v2';
import styled from 'styled-components';

type Provider = {
  label: string;
  icon: Parameters<typeof Icon>[number]['icon'];
};

type LinkHelperTextProps = {
  providers: Array<Provider>;
};

const StyledHelperText = styled.span`
  padding-top: 8px;
  display: flex;

  .icon-container {
    align-items: center;
    margin-left: 8px;
  }

  .Icon {
    height: 18px;
  }
`;

export const LinkHelperText = ({ providers }: LinkHelperTextProps) => (
  <StyledHelperText>
    <span>Embed from:</span>
    {providers.map(({ label, icon }) => (
      <Tooltip
        key={icon}
        arrow={true}
        placement="bottom"
        ariaLabel={label}
        title={label}
      >
        <span className="icon-container">
          <Icon icon={icon} />
        </span>
      </Tooltip>
    ))}
  </StyledHelperText>
);
