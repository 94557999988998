import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { getContentItemWidthToUse } from '../../../utils/contentItemUtils';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';

const PLACEHOLDER_SRC =
  'https://d1ccvuha0qk3lj.cloudfront.net/uploads/general/img_placeholder.png';

export const DisplayImageContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-image'
> = {
  type: 'display-image',
  label: 'Image',
  icon: 'Image',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, RenderVariableText }) => {
        const { data } = contentItem;
        const {
          src,
          width,
          href,
          altText,
          backgroundColor,
          paddingTop,
          paddingBottom,
          paddingRight,
          paddingLeft,
          openInNewTab,
          textAlign,
        } = data;

        return (
          <ContentContainer
            style={{
              backgroundColor,
              paddingTop,
              paddingBottom,
              paddingRight,
              paddingLeft,
            }}
          >
            <a
              href={RenderVariableText(href)}
              rel={`${openInNewTab ? 'noreferrer' : ''}`}
              target={`${openInNewTab ? '_blank' : '_self'}`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:
                  textAlign === 'center'
                    ? 'center'
                    : textAlign === 'left'
                    ? 'flex-start'
                    : 'flex-end',
              }}
            >
              <img
                src={RenderVariableText(src || PLACEHOLDER_SRC)}
                alt={RenderVariableText(altText)}
                style={{
                  display: 'block',
                  width: `${width}%`,
                }}
              />
            </a>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem: { data },
          RenderVariableText,
          columnMaxWidthPx,
        } = props;
        const {
          src,
          width,
          href,
          altText,
          backgroundColor,
          paddingTop,
          paddingBottom,
          paddingRight,
          paddingLeft,
          openInNewTab,
          textAlign,
        } = data;

        const widthInPx = (600 - paddingLeft - paddingRight) * (width / 100);

        const widthToUse = getContentItemWidthToUse({
          width: widthInPx,
          columnMaxWidthPx,
          paddingLeft,
          paddingRight,
        });
        return `
          <mj-image
            container-background-color="${backgroundColor}"
            padding-bottom="${paddingBottom}px"
            padding-left="${paddingLeft}px"
            padding-right="${paddingRight}px"
            padding-top="${paddingTop}px"
            width="${widthToUse}px"
            align="${textAlign}"
            padding="0px"
            alt="${RenderVariableText(altText)}"
            href="${RenderVariableText(href)}"
            src="${RenderVariableText(src || PLACEHOLDER_SRC)}"
            rel="${openInNewTab ? 'noreferrer' : ''}"
            target="${openInNewTab ? '_blank' : '_self'}"
          />`;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        src: '',
        width: 100,
        href: '',
        altText: '',
        openInNewTab: false,
        textAlign: 'center',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              src: {
                controlType: 'image-upload',
                contentDataKey: 'src',
                data: {
                  label: 'Image',
                },
              },
              href: {
                controlType: 'variable-text-input',
                contentDataKey: 'href',
                data: {
                  label: 'Image Link',
                  variables,
                },
              },
              openInNewTab: {
                controlType: 'boolean',
                contentDataKey: 'openInNewTab',
                data: {
                  label: 'Open in new tab',
                },
              },
              altText: {
                controlType: 'variable-text-input',
                contentDataKey: 'altText',
                data: {
                  label: 'Alt Description',
                  variables,
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              width: {
                controlType: 'slider',
                contentDataKey: 'width',
                data: {
                  label: 'Width',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: '%',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              textAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'textAlign',
                data: {
                  label: 'Alignment',
                  variant: 'items',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
