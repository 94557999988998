import styled, { css } from 'styled-components';
import { Colors } from '@whispir/ui-lib-v2';
import { Z_INDEX_MAP } from './utils/constants';

export const HoverIcon = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  fill: ${Colors.accent_5};
  border-radius: 2px;
  background-color: ${Colors.accent_2};
  box-sizing: border-box;
  position: absolute;
  top: -2.25rem;
  left: 0;
  z-index: ${Z_INDEX_MAP.draggableHoverIcon};

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const Overlay = styled.div<{ isSelected: boolean }>`
  position: relative;

  ${(props) =>
    props.isSelected &&
    css`
      outline: 2px solid ${Colors.accent_5};
      // Increase z-index to allow the Selected component outline to appear above the Hovered component outline
      z-index: ${Z_INDEX_MAP.selectedItem};
    `}

  ${(props) =>
    !props.isSelected &&
    css`
      &:hover {
        cursor: pointer;
        outline: 2px solid ${Colors.accent_3};
        // Increase z-index to allow the the Hovered component outline to appear above siblings
        z-index: ${Z_INDEX_MAP.hoverItem};

        ${HoverIcon} {
          display: flex;
        }
      }
    `}
`;

export const ClickShield = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX_MAP.clickShield};
  pointer-events: none;
`;
