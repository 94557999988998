import styled, { css } from 'styled-components';
import { Colors, Fonts } from '@whispir/ui-lib-v2';
import { Z_INDEX_MAP } from '../utils/constants';
import { ContentItemDroppableType } from '../utils/types';

export const IconWrapper = styled.div<{
  isVertical?: boolean;
  type: ContentItemDroppableType;
}>`
  z-index: ${(props) => Z_INDEX_MAP[props.type]};
  border-radius: 2rem;
  background-color: ${Colors.accent_4_5};
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isVertical
      ? css`
          height: 68px;
          width: 24px;
        `
      : css`
          width: 68px;
          height: 24px;
        `};

  svg {
    fill: ${Colors.white};
  }
`;

export const Wrapper = styled.div<{
  isVertical?: boolean;
  shouldDisplay?: boolean;
  type: ContentItemDroppableType;
}>`
  z-index: ${(props) => Z_INDEX_MAP[props.type]};
  position: relative;
  display: none;
  background-color: ${Colors.accent_5};
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isVertical
      ? css`
          height: 100%;
          width: 2px;
          border-left: 6px solid ${Colors.accent_4};
          border-right: 6px solid ${Colors.accent_4};
        `
      : css`
          width: 100%;
          height: 2px;
          border-top: 6px solid ${Colors.accent_4};
          border-bottom: 6px solid ${Colors.accent_4};
        `};

  ${(props) =>
    props.shouldDisplay &&
    css`
      display: flex;
    `}
`;

export const EmptyStatePlaceholder = styled.div<{ isOver: boolean }>`
  ${Fonts.body}
  display: flex;
  background-color: ${({ isOver }) =>
    isOver ? Colors.accent_3 : Colors.accent_1};
  color: ${Colors.accent_5};
  width: 100%;
  height: 96px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
