import { IconTypes } from '@whispir/ui-lib-v2';
import {
  KnownBaseContentItem,
  KnownLayoutContentItem,
  KnownMapContentItem,
} from '../../../content/schemas/KnownContentTypeAndVersionMap';
import { AnyContentItem } from '../../../framework/AnyContentItem';
import { ALL_DRAGGABLE_TYPES, DRAGGABLE_TYPES, COLUMN_MAP } from './constants';

export type Direction = 'top' | 'bottom' | 'left' | 'right' | undefined;

export type ColumnType = keyof typeof COLUMN_MAP;

export type ContentItemDraggableType = typeof ALL_DRAGGABLE_TYPES[number];
export type ContentItemDroppableType = Exclude<
  ContentItemDraggableType,
  'sidebar'
>;

type Path = string;

export type RowReference = {
  layoutType: typeof DRAGGABLE_TYPES.ROW;
  contentId: string;
  children: Array<ColumnReference>;
};

export type ColumnReference = {
  layoutType: typeof DRAGGABLE_TYPES.COLUMN;
  children: Array<ActualContentItemReference>;
};

export type ActualContentItemReference = {
  layoutType: typeof DRAGGABLE_TYPES.COMPONENT;
  contentId: string;
};

export type SidebarContentItemReference = {
  layoutType: typeof DRAGGABLE_TYPES.SIDEBAR;
  contentType: string;
};

export type AllDraggableReferences =
  | RowReference
  | ColumnReference
  | ActualContentItemReference
  | SidebarContentItemReference;

// TODO: this `path` is really the pathTo, could be refactored to separate the
// DND paths from the DraggableReferences
export type DragItemPayload = AllDraggableReferences & {
  path?: Path;
};

export function isValidDropItem(item: unknown): item is AllDraggableReferences {
  return (item as AllDraggableReferences).layoutType !== undefined;
}

export function isLayoutContentItem(
  contentItem: AnyContentItem,
): contentItem is KnownLayoutContentItem {
  return (contentItem as KnownLayoutContentItem).type === 'layout-column';
}

export function isDisplayMapContentItem(
  contentItem: AnyContentItem,
): contentItem is KnownMapContentItem {
  return (contentItem as KnownMapContentItem).type === 'display-map';
}

export function isFormContentItem(
  contentItem: AnyContentItem,
): contentItem is KnownBaseContentItem {
  return (contentItem as KnownBaseContentItem).type.startsWith('form');
}

export function isActualContentItemReference(
  item: unknown,
): item is ActualContentItemReference {
  return (
    (item as ActualContentItemReference).layoutType ===
    DRAGGABLE_TYPES.COMPONENT
  );
}

export function isColumnReference(item: unknown): item is ColumnReference {
  return (item as ColumnReference).layoutType === DRAGGABLE_TYPES.COLUMN;
}

export function isRowReference(item: unknown): item is RowReference {
  return (item as RowReference).layoutType === DRAGGABLE_TYPES.ROW;
}

export function isSidebarReference(
  item: unknown,
): item is SidebarContentItemReference {
  return (
    (item as SidebarContentItemReference).layoutType === DRAGGABLE_TYPES.SIDEBAR
  );
}

export type AllDraggableReferencesWithExtraProps = AllDraggableReferences & {
  label?: string;
  icon?: IconTypes;
  componentType?: AnyContentItem['type'];
};

export type LayoutBundle = {
  rows: Array<RowReference>;
  contentMap: Record<string, AnyContentItem>;
};

export type DropData = {
  path: string;
  childrenCount: number;
};

export type HandleDropFunction = (
  dropZone: DropData,
  itemBeingDropped: AllDraggableReferences & { path?: Path },
) => void;

export type RowOrColumnOrItemProps = {
  referenceData: AllDraggableReferences;
  contentMap: Record<string, AnyContentItem>;
  path: string;

  selectedItemId: string | null;

  setSelectedItem: (item: AnyContentItem | null) => void;
  handleDeleteItem: (params: {
    path: string;
    id: string;
    type: string;
  }) => void;

  handleDuplicateItem: ({
    path,
    item,
    type,
  }: {
    item: RowReference | ActualContentItemReference;
    path: string;
    type: AnyContentItem['type'];
  }) => void;
};

export type BuilderRowProps = {
  handleDrop: HandleDropFunction;
} & RowOrColumnOrItemProps;

export type BuilderColumnProps = {
  handleDrop: HandleDropFunction;
  parentLineDataArray: Array<DropData>;
  isFormRow: boolean;
} & RowOrColumnOrItemProps;
