import React, { useContext } from 'react';
import { ResolvedVariable } from '@whispir/variables';
import { ApiLinkedContentBuilderProps } from '../../mainEntryPoints/Builder';

export type ModeContextType = {
  variableMode: 'builder' | 'viewer';
  inputValues: Array<ResolvedVariable>;
  contentMode: ApiLinkedContentBuilderProps['contentMode'];
};

type ModeProviderProps = {
  variableMode: 'builder' | 'viewer';
  inputValues?: Array<ResolvedVariable>;
  contentMode?: ApiLinkedContentBuilderProps['contentMode'];
};

export const ModeContext = React.createContext<ModeContextType>({
  variableMode: 'builder',
  inputValues: [],
  contentMode: 'form',
});

export const ModeProvider = (
  props: React.PropsWithChildren<ModeProviderProps>,
) => {
  const {
    children,
    variableMode,
    inputValues = [],
    contentMode = 'test',
  } = props;
  return (
    <ModeContext.Provider value={{ variableMode, inputValues, contentMode }}>
      {children}
    </ModeContext.Provider>
  );
};

export const useModeContext = () => useContext(ModeContext);
