import { useEffect, useState } from 'react';
import {
  GoogleMap as GoogleMapComp,
  Marker,
  useLoadScript,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { TextField } from '@material-ui/core';
import useAsyncEffect from 'use-async-effect';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { useOnMount } from '../../../utils/useOnmount';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { InteractiveMapStyleWrapper } from './FormLocation.styles';

type LocationType =
  | {
      lat: number;
      lng: number;
    }
  | undefined;

// This is 360 Collins Street, Melbourne VIC
const MAP_DEFAULT_LOCATION: LocationType = {
  lat: -37.816272,
  lng: 144.962327,
};

const defaultCenter = MAP_DEFAULT_LOCATION;
const MAX_ZOOM = 16;

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const FormInteractiveMapContentItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-location'
> = {
  type: 'form-location',
  label: 'Location',
  icon: 'Map',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableHtml,
      }) => {
        if (!GOOGLE_MAP_API_KEY) {
          throw new Error('Google map API key does not exist');
        }

        const { isLoaded } = useLoadScript({
          googleMapsApiKey: GOOGLE_MAP_API_KEY,
          libraries: ['places'],
        });
        const { id, data } = contentItem;
        const containerStyle = {
          width: '100%',
          height: '200px',
          marginTop: '8px',
        };
        const {
          fieldLabel,
          requiredField,
          helperText,
          backgroundColor,
          interactive,
          placeholder,
          zoom,
          mapStyle,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          locationText,
        } = data;

        const [showPlaceholderField, setPlaceholderField] = useState(true);

        // All form components must fire an update on mount, to let the viewer know what the default values are.
        useOnMount(() => {
          formUpdateFunction(id, contentItem.data.locationText);
        });

        const actualCenter =
          (locationText && JSON.parse(locationText)) || defaultCenter;
        const [location, setLocation] = useState<LocationType>(actualCenter);
        const [searchBox, setSearchBox] = useState({
          getPlaces: () => [
            {
              geometry: {
                location: {
                  toJSON: () => actualCenter,
                },
              },
            },
          ],
        });

        const setUserCurrentPosition = async () => {
          await navigator.geolocation.getCurrentPosition(
            (position) =>
              setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }),
            (err) => {
              throw err;
            },
          );
        };

        useAsyncEffect(async () => {
          const newLocation = locationText && JSON.parse(locationText);
          if (!newLocation || Object.keys(newLocation).length === 0) {
            await setUserCurrentPosition();
          } else {
            setLocation(newLocation);
          }
        }, [locationText]);

        useEffect(() => {
          setPlaceholderField(!!interactive);
        }, [interactive]);

        const reverseGeocode = () => {
          return JSON.stringify(
            searchBox.getPlaces()[0].geometry.location.toJSON(),
          );
        };

        const onSearchBoxLoad = (ref) => {
          setSearchBox(ref);
        };

        const onPlacesChanged = () => {
          const newLocationText = JSON.parse(reverseGeocode());
          setLocation(newLocationText);
          formUpdateFunction(id, newLocationText);
        };

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
            }}
            isFieldRequired={requiredField}
            label={fieldLabel}
            htmlFor={id}
          >
            <InteractiveMapStyleWrapper
              {...data}
              className="interactive-map-wrapper"
            >
              {showPlaceholderField && isLoaded && (
                <StandaloneSearchBox
                  onLoad={onSearchBoxLoad}
                  onPlacesChanged={onPlacesChanged}
                >
                  <TextField
                    variant="outlined"
                    placeholder={placeholder}
                    required={requiredField}
                    fullWidth
                    helperText={<RenderVariableHtml htmlString={helperText} />}
                  />
                </StandaloneSearchBox>
              )}
              {isLoaded && location && (
                <GoogleMapComp
                  mapContainerStyle={containerStyle}
                  center={location}
                  zoom={(zoom && (zoom * MAX_ZOOM) / 100) || 14}
                  options={{
                    draggable: false,
                    fullscreenControl: false,
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    mapTypeId: mapStyle,
                  }}
                >
                  <Marker position={location} />
                </GoogleMapComp>
              )}
            </InteractiveMapStyleWrapper>
          </ContentContainer>
        );
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        fieldLabel: 'Enter Location',
        requiredField: false,
        helperText: '',
        interactive: true,
        placeholder: 'Search an Address',
        zoom: 80,
        locationText: '',
        mapStyle: 'roadmap',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              fieldLabel: {
                controlType: 'text',
                contentDataKey: 'fieldLabel',
                data: {
                  label: 'Field Label',
                },
              },
              helperText: {
                controlType: 'variable-text-input',
                contentDataKey: 'helperText',
                data: {
                  label: 'Helper Text',
                  variables,
                },
              },
              placeholder: {
                controlType: 'variable-text-input',
                contentDataKey: 'placeholder',
                data: {
                  label: 'Placeholder Text',
                  variables,
                },
              },
              locationText: {
                controlType: 'google-auto-suggest',
                contentDataKey: 'locationText',
                data: {
                  label: 'Default Location',
                  info: 'Add a default address that your recipient can edit.',
                },
              },
              interactive: {
                controlType: 'boolean',
                contentDataKey: 'interactive',
                data: {
                  label: 'Allow GPS Coordinates',
                  info: 'Allow recipients to pin GPS coordinates on map.',
                },
              },
              zoom: {
                controlType: 'slider',
                contentDataKey: 'zoom',
                data: {
                  label: 'Zoom',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: '%',
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'STYLE',
            controlsConfig: {
              mapStyle: {
                controlType: 'radio',
                contentDataKey: 'mapStyle',
                data: {
                  options: {
                    roadmap: 'Roadmap',
                    satellite: 'Satellite',
                  },
                  label: 'Map Style',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },

      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'string',
            inputType: contentItem.type,
            label: contentItem.data.fieldLabel,
          },
        ];
      },
    },
  },
};
