export const genericExtractUsingRegex = (
  str: string,
  reg: RegExp,
  captureGroup: number,
): string | null => {
  const result = str.match(reg);

  if (!result) {
    return result;
  }

  return result[captureGroup];
};

/**
 * For a given string, split it according the regex, and then convert the matches using the conversionFn.
 * @param originalString
 * @param regex
 * @param conversionFn
 * @returns An array of the unconverted remaining parts of the string, and result of the conversions, in order that they appeared in the original string.
 */
export const splitAndConvertString = <T>(
  originalString: string,
  regex: RegExp,
  conversionFn: (itemString: string) => T,
): Array<T | string> => {
  // https://github.com/facebook/jest/issues/9184
  // Make sure you are using node v12
  const matches = originalString.matchAll(regex);

  const array = [] as Array<string | T>;

  let currentIndex = 0;

  for (const match of matches) {
    const matchIndex = match.index;
    if (matchIndex === undefined) {
      throw new Error('Got a match without an index');
    }
    const str = originalString.substr(currentIndex, matchIndex - currentIndex);

    if (str.length > 0) {
      array.push(str);
    }
    array.push(conversionFn(match[0]));
    currentIndex = matchIndex + match[0].length;
  }

  array.push(
    originalString.substr(currentIndex, originalString.length - currentIndex),
  );

  return array;
};
