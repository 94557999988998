import { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import { Icon } from '@whispir/ui-lib-v2';
import {
  AllDraggableReferences,
  ContentItemDroppableType,
  DropData,
  isValidDropItem,
} from '../utils/types';
import { handleCanDrop } from '../utils/helpers';
import { ALL_DRAGGABLE_TYPES } from '../utils/constants';
import * as S from './styles';

const ACCEPTS = [...ALL_DRAGGABLE_TYPES];

type DropLineProps = {
  data: DropData;
  type: ContentItemDroppableType;
  isVertical?: boolean;
  children?: (params: { isOver: boolean }) => ReactNode;
  disableDrop?: boolean;
  isOver?: boolean;
  handleDrop: (
    dropZone: DropData,
    itemBeingDropped: AllDraggableReferences,
  ) => void;
};

// This provides the blue drop lines that allow you to drop other draggable items onto.
const DropLine = ({
  data,
  type,
  handleDrop,
  isVertical = false,
  children,
  disableDrop = false,
  isOver = false,
}: DropLineProps) => {
  const [{ canDrop, isOverDropLine }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item) => {
      if (isValidDropItem(item)) {
        handleDrop(data, item);
      } else {
        throw new Error(
          "Something went wrong, our drop item wasn't in the right format",
        );
      }
    },
    canDrop: (item) => {
      if (disableDrop) return false;

      return handleCanDrop({ item, data });
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOverDropLine: monitor.isOver({ shallow: true }),
      };
    },
  });
  const shouldDisplay = (isOverDropLine || isOver) && canDrop;

  return children ? (
    <div ref={drop}>{children({ isOver: isOverDropLine && canDrop })}</div>
  ) : (
    <S.Wrapper
      ref={drop}
      shouldDisplay={shouldDisplay}
      type={type}
      isVertical={isVertical}
    >
      <S.IconWrapper type={type} isVertical={isVertical}>
        <Icon icon="Add" />
      </S.IconWrapper>
    </S.Wrapper>
  );
};
export default DropLine;
