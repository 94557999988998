import { StandardProperties, StandardLonghandProperties } from 'csstype';

export const justifyContentPosition = (
  position,
): StandardLonghandProperties['justifyContent'] => {
  switch (position) {
    case 'top-left':
    case 'middle-left':
    case 'bottom-left':
      return 'flex-start';
    case 'top-middle':
    case 'middle-middle':
    case 'bottom-middle':
      return 'center';
    case 'top-right':
    case 'middle-right':
    case 'bottom-right':
      return 'flex-end';
  }
};

export const alignItemsPosition = (
  position,
): StandardLonghandProperties['alignItems'] => {
  switch (position) {
    case 'top-left':
    case 'top-middle':
    case 'top-right':
      return 'flex-start';
    case 'middle-left':
    case 'middle-middle':
    case 'middle-right':
      return 'center';
    case 'bottom-left':
    case 'bottom-middle':
    case 'bottom-right':
      return 'flex-end';
  }
};

export const textAlignPosition = (
  position,
): StandardProperties['textAlign'] => {
  switch (position) {
    case 'top-left':
    case 'bottom-left':
    case 'middle-left':
      return 'left';
    case 'top-middle':
    case 'middle-middle':
    case 'bottom-middle':
      return 'center';
    case 'top-right':
    case 'middle-right':
    case 'bottom-right':
      return 'right';
  }
};

export const verticalAlign = (
  position,
): StandardProperties['verticalAlign'] => {
  switch (position) {
    case 'top-left':
    case 'top-middle':
    case 'top-right':
      return 'top';
    case 'middle-left':
    case 'middle-middle':
    case 'middle-right':
      return 'middle';
    case 'bottom-left':
    case 'bottom-middle':
    case 'bottom-right':
      return 'bottom';
  }
};
