import { cloneObject } from './objectUtils';

import {
  insertElementAtIndex,
  removeElementAtIndex,
  replaceElementAtIndex,
  shiftElementToIndex,
} from './arrayUtils';

type TreeNode<T extends string> = Record<T, Array<TreeNode<T> | unknown>>;

export const removeItemFromTree = <TKey extends string>(
  tree: TreeNode<TKey>,
  pathToInsertPoint: Array<number>,
  treeKey: TKey,
): TreeNode<TKey> => {
  if (pathToInsertPoint.length > 0 && !(treeKey in tree)) {
    throw new Error("We're still recursing, but no children found");
  }

  if (pathToInsertPoint.length === 1) {
    const itemIndex = pathToInsertPoint[0];
    const children = tree[treeKey];
    return {
      ...tree,
      [treeKey]: removeElementAtIndex(children, itemIndex),
    };
  }

  const currentArray = tree[treeKey];
  const nextIndex = pathToInsertPoint[0];

  const newPath = pathToInsertPoint.slice(1);
  const nextNode = tree[treeKey][nextIndex];

  const newNode = removeItemFromTree(
    nextNode as TreeNode<TKey>,
    newPath,
    treeKey,
  );

  const newArray = replaceElementAtIndex(currentArray, nextIndex, newNode);

  return {
    ...tree,
    [treeKey]: newArray,
  };
};

export const addItemToTree = <TKey extends string>(
  tree: TreeNode<TKey>,
  pathToInsertPoint: Array<number>,
  itemToInsert: unknown,
  treeKey: TKey,
): TreeNode<TKey> => {
  if (pathToInsertPoint.length > 0 && !(treeKey in tree)) {
    throw new Error("We're still recursing, but no children found");
  }

  if (pathToInsertPoint.length === 1) {
    const itemIndex = pathToInsertPoint[0];
    return {
      ...tree,
      [treeKey]: insertElementAtIndex(tree[treeKey], itemToInsert, itemIndex),
    };
  }

  const currentArray = tree[treeKey];
  const nextIndex = pathToInsertPoint[0];

  const newPath = pathToInsertPoint.slice(1);
  const nextNode = tree[treeKey][nextIndex];

  const newNode = addItemToTree(
    nextNode as TreeNode<TKey>,
    newPath,
    itemToInsert,
    treeKey,
  );

  const newArray = replaceElementAtIndex(currentArray, nextIndex, newNode);

  return {
    ...tree,
    [treeKey]: newArray,
  };
};

/**
 *
 * Note: This function does not know anything about the datatype it returns
 *
 * @param tree
 * @param pathTo
 * @param treeKey
 * @returns
 */
export const getElementInTree = <TKey extends string>(
  tree: TreeNode<TKey>,
  pathTo: Array<number>,
  treeKey: TKey,
): unknown => {
  if (pathTo.length > 0 && !(treeKey in tree)) {
    throw new Error("We're still recursing, but no children found");
  }

  if (pathTo.length === 1) {
    const itemIndex = pathTo[0];
    return tree[treeKey][itemIndex];
  }

  const nextIndex = pathTo[0];

  const newPath = pathTo.slice(1);
  const nextNode = tree[treeKey][nextIndex];

  return getElementInTree(nextNode as TreeNode<TKey>, newPath, treeKey);
};

/**
 * This function is only suitable for reordering elements that exist in the same parent
 * It will fail early if not
 * @param tree
 * @param pathFrom
 * @param pathTo
 * @param treeKey
 */
export const reorderElementsInTree = <TKey extends string>(
  tree: TreeNode<TKey>,
  pathFrom: Array<number>,
  pathTo: Array<number>,
  treeKey: TKey,
) => {
  if (pathFrom.length !== pathTo.length) {
    throw new Error('I expected pathTo and pathFrom to be the same length');
  }

  let arraysDoMatchToEnd = true;

  pathFrom.forEach((v, i) => {
    const w = pathTo[i];

    if (v !== w && i !== pathFrom.length - 1) {
      arraysDoMatchToEnd = false;
    }
  });

  if (!arraysDoMatchToEnd) {
    throw new Error('Arrays do not match to the end');

    // Technically, we could allow reordering of same level cousins.
    // It probably makes sense not to allow reordering split level relatives, as their datatypes are likely not the same.
    //
    // reordering same level cousins would just be a matter of remove and then add. (I think) we don't need to worry about the removing action messing up the indexes.
  }

  const allPathExceptLast = pathFrom.slice(0, -1);
  const newTree = cloneObject(tree);
  const lastParent = (allPathExceptLast.length > 0
    ? getElementInTree(newTree, allPathExceptLast, treeKey)
    : newTree) as TreeNode<TKey>;

  const indexFrom = pathFrom[pathFrom.length - 1];
  const indexTo = pathTo[pathTo.length - 1];

  const newArray = shiftElementToIndex(lastParent[treeKey], indexFrom, indexTo);
  lastParent[treeKey] = newArray;

  return newTree;
};
