import axios from 'axios';
import { useGetActivation } from './useGetActivation';
import { useGetContent } from './useGetContent';
import { useSaveActivation } from './useSaveActivation';
import { useSaveSubmission } from './useCreateSubmission';
import { useSaveContent } from './useSaveContent';
import { useSaveContentPublication } from './useSaveContentPublication';
import { useRenderingEmailApi } from './useRenderingEmailApi';

export const METHODS = Object.freeze({
  POST: 'post',
  PUT: 'put',
  GET: 'get',
});

export type ContentApiOptions = {
  url?: string;
  workspaceId?: string;
};

export const useContentApi = ({
  url = process.env.REACT_APP_CONTENT_API_URL,
  workspaceId = '',
}: ContentApiOptions) => {
  const api = axios.create({
    baseURL: url,
    withCredentials: true,
  });

  const errorHandler = (error) => {
    return Promise.reject(error.response);
  };

  api.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error),
  );

  const getContent = useGetContent({
    api,
  });
  const getActivation = useGetActivation({ api });
  const saveActivation = useSaveActivation({
    api,
    workspaceId,
  });
  const saveSubmission = useSaveSubmission({ api });
  const saveContent = useSaveContent({
    api,
    workspaceId,
  });
  const saveContentPublication = useSaveContentPublication({
    api,
    workspaceId,
  });

  const { renderEmail } = useRenderingEmailApi({
    api,
  });

  return {
    getContent: getContent.callback,
    getActivation: getActivation.callback,
    saveActivation: saveActivation.callback,
    saveSubmission: saveSubmission.callback,
    saveContent: saveContent.callback,
    saveContentPublication: saveContentPublication.callback,
    // Add loading state of hook here
    isLoading: getContent.isLoading || getActivation.isLoading,
    isSaving:
      saveActivation.isLoading ||
      saveSubmission.isLoading ||
      saveContent.isLoading ||
      saveContentPublication.isLoading,
    renderEmail,
  };
};
