import styled, { css } from 'styled-components';
import { Colors } from '@whispir/ui-lib-v2';
import { HoverIcon } from '../Global.styles';
import { Z_INDEX_MAP } from '../utils/constants';

export const RowHoverIcon = styled(HoverIcon)``;

export const Wrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  cursor: move;
  display: flex;
  justify-content: center;
  // TODO: see comments below
  // Possible solution to issue commented below:
  margin: 0 2px;

  // TODO: style padding, for future release
  /* .layout-column:hover {
    background-clip: content-box, padding-box;
  } */

  ${(props) =>
    props.isSelected &&
    css`
      outline: 2px solid ${Colors.accent_5};
      z-index: ${Z_INDEX_MAP.selectedItem};
      // If we keep this margin (and below for hover) and there is a fullwidth background image,
      // the hovered/selected layout will visibly change width.
      // If we remove this margin, the outline is not visible on the left and right side.
      /* margin: 0 2px; */
    `}

  ${(props) =>
    !props.isSelected &&
    css`
      &:hover {
        cursor: pointer;
        outline: 2px solid ${Colors.accent_3};
        background-color: ${Colors.accent_2};
        /* margin: 0 2px; */

        ${RowHoverIcon} {
          display: flex;
        }
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;
