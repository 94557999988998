import { useEffect } from 'react';
import { useFrame } from 'react-frame-component';
import parse from 'html-react-parser';
import { MapDataArray } from './types';

export const EmailWithJsMapsInitialised = ({
  emailString,
  mapDataArray,
}: {
  emailString: string;
  mapDataArray: MapDataArray;
}) => {
  /* eslint-disable-next-line destructuring/no-rename */
  const { document: frameDocument } = useFrame();

  useEffect(() => {
    if (mapDataArray.length > 0) {
      mapDataArray.forEach((mapId) => {
        const { id, center, zoom, mapTypeId } = mapId;
        const element = frameDocument.getElementById(id);
        if (element) {
          const map = new google.maps.Map(element as HTMLElement, {
            center,
            zoom,
            mapTypeId,
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            draggable: false,
            keyboardShortcuts: false,
            scrollwheel: false,
          });
          new google.maps.Marker({
            position: center,
            map,
          });
        }
      });
    }
  }, [frameDocument, mapDataArray]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {parse(emailString)}
    </div>
  );
};
