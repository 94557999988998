import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyle, UserProvider } from '@whispir/ui-lib-v2';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Viewer } from './mainEntryPoints/Viewer';
import { Builder } from './mainEntryPoints/Builder';
import { DevelopersView } from './mainEntryPoints/DevelopersView';
import './App.css';
import { contentCreationMap } from './content/content';
import '@whispir/ui-lib-v2/lib/Assets/Fonts/fonts.css';
import { useContentApi } from './hooks';
import {
  MOCK_WORKSPACE_ID,
  MOCK_INPUT_VARIABLES,
  MOCK_INPUT_VALUES,
} from './mockInputs';

// This is how we are building essentially three different frontends using the one project.
//https://blog.logrocket.com/multiple-entry-points-in-create-react-app-without-ejecting/
// We can further optimise this to tree shake properly.
// Reader further down the article.

const BUILD_MODE = process.env.REACT_APP_BUILD_MODE || 'DEV';
console.info(BUILD_MODE);

const apis = {
  apiUrl: process.env.REACT_APP_CONTENT_API_URL,
  viewerUrl: process.env.REACT_APP_VIEWER_API_URL,
  cssCompilerApi: process.env.REACT_APP_SASS_COMPILER,
  mediaApi: process.env.REACT_APP_MEDIA_URL,
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
};

const BuilderWrapper = () => {
  const [viewerActivationUrl, setViewerActivationUrl] = useState('');

  return (
    <Builder
      contentMode="test"
      apis={apis}
      contentId={window.location.pathname.split('/')[1]}
      contentType="html"
      workspaceId={MOCK_WORKSPACE_ID}
      inputVariables={MOCK_INPUT_VARIABLES}
      inputValues={MOCK_INPUT_VALUES}
      onSaveContent={({ contentId }) => {
        window.history.pushState('', 'Title', `/${contentId}`);
      }}
      onActivation={({ url }) => {
        setViewerActivationUrl(url);
      }}
      defaultSubject={'Content Builder Playground'}
      modalHeaderChildren={
        viewerActivationUrl && (
          <a
            href={viewerActivationUrl}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          >
            Go To Viewer
          </a>
        )
      }
    />
  );
};

const App = () => {
  const { getActivation, saveSubmission } = useContentApi({});
  return (
    <React.StrictMode>
      {/* We should be careful with this thing. 
It is likely to be overriding some styles that we don't want it to
*/}
      <GlobalStyle />

      <UserProvider
        user={{
          userId: 'developer-user',
        }}
      >
        {BUILD_MODE === 'VIEWER' && (
          <Viewer
            contentCreationMap={contentCreationMap}
            getActivationFn={getActivation}
            saveSubmissionFn={saveSubmission}
          />
        )}
        {BUILD_MODE === 'BUILDER' && <BuilderWrapper />}
        {BUILD_MODE === 'DEV' && <DevelopersView />}
      </UserProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
