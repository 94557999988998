import React from 'react';
import { Typography } from '@material-ui/core';
import { green, red, amber } from '@material-ui/core/colors';
import {
  CheckCircleRounded,
  ReportProblemRounded,
  ErrorRounded,
} from '@material-ui/icons';

export type FormSubmissionResultMessageProps = {
  resultType: 'success' | 'error' | 'expired' | 'error-loading-form';
};

const commonIconStyles = {
  display: 'block',
  margin: '0 auto',
  height: 40,
  width: 40,
};
const resultContent = {
  success: {
    heading: 'Thank You',
    message: 'Your submission has been captured.',
    icon: (
      <CheckCircleRounded style={{ ...commonIconStyles, color: green[500] }} />
    ),
  },
  expired: {
    heading: 'Sorry',
    message: 'This form has expired.',
    icon: (
      <ReportProblemRounded
        style={{ ...commonIconStyles, color: amber[300] }}
      />
    ),
  },
  error: {
    heading: 'Form Submission Failed',
    message: 'Sorry, please try again later.',
    icon: <ErrorRounded style={{ ...commonIconStyles, color: red[500] }} />,
  },
  'error-loading-form': {
    heading: 'Error Loading Form',
    message: 'Sorry, please try again later.',
    icon: <ErrorRounded style={{ ...commonIconStyles, color: red[500] }} />,
  },
};

export const FormSubmissionResultMessage = (
  props: FormSubmissionResultMessageProps,
) => {
  const { resultType } = props;
  const content = resultContent[resultType];
  return (
    <div style={{ paddingTop: '20vh' }}>
      {content.icon}
      <Typography variant="h5" align="center">
        {content.heading}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {content.message}
      </Typography>
    </div>
  );
};
