import { subMinutes } from 'date-fns';

const MINUTES_PER_HOUR = 60;
const UTC_TIMEZONE_DESIGNATOR = 'Z';
const OFFSET_PADDER = '0';
const OFFSET_PADDER_LENGTH = 2;
enum OFFSET_DIRECTION {
  PLUS = '+',
  MINUS = '-',
}

/**
 * Use this helper to get a valid ISO8601 string with a timezone desginator.
 * This function is intended to be used on the client side, to capture timezone information from Date form components.
 *
 * @param date the date to format
 * @example
 * ```ts
 * > const date = new Date(); // 2021-08-09T14:00:00.000Z
 * > // (in Australia/Melbourne)
 * > formatISOTZ(date); // '2021-08-10T00:00:00.000+1000'
 * ```
 * @returns a formatted ISO8601 string with timezone designation
 */
export const toISOTzString = (date: Date) => {
  const offsetInMinutes = date.getTimezoneOffset();
  const absOffsetInMinutes = Math.abs(offsetInMinutes);
  const offsetHours = `${Math.floor(
    absOffsetInMinutes / MINUTES_PER_HOUR,
  )}`.padStart(OFFSET_PADDER_LENGTH, OFFSET_PADDER);
  const offsetMinutes = `${absOffsetInMinutes % MINUTES_PER_HOUR}`.padStart(
    OFFSET_PADDER_LENGTH,
    OFFSET_PADDER,
  );

  const offsetDirection =
    offsetInMinutes <= 0 ? OFFSET_DIRECTION.PLUS : OFFSET_DIRECTION.MINUS;

  const localizedDate = subMinutes(date, offsetInMinutes);
  const iso8601String = localizedDate.toISOString();
  const localTimezoneDesignator = `${offsetDirection}${offsetHours}${offsetMinutes}`;

  const iso8601TzString = iso8601String.replace(
    UTC_TIMEZONE_DESIGNATOR,
    localTimezoneDesignator,
  );
  return iso8601TzString;
};
