import { Typography } from '@material-ui/core';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import {
  alignItemsPosition,
  justifyContentPosition,
  textAlignPosition,
  verticalAlign,
} from '../../../utils/positionSelectorCssMapping';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

export const DisplayBannerContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-banner'
> = {
  type: 'display-banner',
  label: 'Banner',
  icon: 'Banner',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        RenderVariableText,
        RenderVariableHtml,
      }) => {
        const { data } = contentItem;
        const {
          text,
          position,
          color,
          textPadding,
          src,
          backgroundColor,
          height,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          fullWidth,
        } = data;

        return (
          <ContentContainer
            style={{
              backgroundColor,
              height,
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
            }}
            fullWidth={fullWidth}
          >
            <div
              style={{
                backgroundImage: `url(${RenderVariableText(src)})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                height: '100%',
              }}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  color,
                  justifyContent: justifyContentPosition(position),
                  alignItems: alignItemsPosition(position),
                  textAlign: textAlignPosition(position),
                  overflow: 'hidden',
                  maxWidth: 600,
                  margin: '0 auto',
                }}
              >
                <Typography
                  component="div"
                  style={{
                    padding: textPadding,
                  }}
                >
                  <RenderVariableHtml htmlString={text} />
                </Typography>
              </div>
            </div>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const { contentItem, RenderVariableHtml, RenderVariableText } = props;
        const { data } = contentItem;
        const {
          text,
          position,
          color,
          textPadding,
          src,
          backgroundColor,
          height,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,

          // Full width doesn't do anything for email!
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          fullWidth,
        } = data;

        const vAlign = verticalAlign(position);

        // We remove paddingTop and paddingBottom, as the height is set on the hero,
        // not the container.
        const heroHeight = height - paddingTop - paddingBottom;

        const mjml = `
          <mj-section
            background-color="${backgroundColor}"
            padding-bottom="${paddingBottom}px"
            padding-top="${paddingTop}px"
            padding-left="${paddingLeft}px"
            padding-right="${paddingRight}px"
          >
            <mj-hero
              mode="fixed-height"
              height="${heroHeight}px"
              background-url="${RenderVariableText(src)}"
              background-color="${backgroundColor}"
              vertical-align="${vAlign}"
            >
              <mj-text
                color="${color}"
                align="${textAlignPosition(position)}"
                padding-left="${textPadding}px"
                padding-right="${textPadding}px"
              >
                ${RenderVariableHtml(text)}
              </mj-text>
            </mj-hero>
          </mj-section>
        `;
        return mjml;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        text: '',
        position: 'middle-middle',
        color: '#000000',
        textPadding: 0,
        src:
          'https://cdn-au.whispir.com/public/resources/313a588f0b0ae6c3caa57f6536a540ab50.jpeg',
        height: 250,
        fullWidth: false,
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;
        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              text: {
                controlType: 'rich-text-paragraph',
                contentDataKey: 'text',
                data: {
                  label: 'Rich Text',
                  variables,
                },
              },
              src: {
                controlType: 'variable-text-input',
                contentDataKey: 'src',
                data: {
                  label: 'Image source',
                  variables,
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              color: {
                controlType: 'color-picker',
                contentDataKey: 'color',
                data: {
                  label: 'Text Color',
                },
              },
              height: {
                controlType: 'slider',
                contentDataKey: 'height',
                data: {
                  label: 'Banner Height',
                  minValue: 1,
                  maxValue: 800,
                  step: 1,
                  units: 'px',
                },
              },
              fullWidth: {
                controlType: 'boolean',
                contentDataKey: 'fullWidth',
                data: {
                  label: 'Full Width',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              position: {
                controlType: 'position-selector',
                contentDataKey: 'position',
                data: {
                  label: 'Text Position',
                },
              },
              textPadding: {
                controlType: 'slider',
                contentDataKey: 'textPadding',
                data: {
                  label: 'Text Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
