import { ResolvedVariable, Variable } from '@whispir/variables';
import { toISOTzString } from './utils/dateUtils';

// Mock input labels, will come from the Workflow variables available at the send-message step
export const MOCK_INPUT_VARIABLES: Array<Variable> = [
  {
    id: '2be5f39a-bc6f-4674-86a9-a38ac08590e0',
    label: 'Foo',
    valueType: 'string',
    inputType: 'form-text-input',
  },
  {
    id: '2be5f39a-bc6f-4674-86a9-a38ac08590e1',
    label: 'Bar',
    valueType: 'string',
    inputType: 'form-text-input',
  },
  {
    id: '2be5f39a-bc6f-4674-86a9-a38ac08590e2',
    label: 'Baz',
    valueType: 'string',
    inputType: 'form-text-input',
  },
  {
    id: 'bb13e3dc-3e35-404d-bfda-46e8b2978034',
    valueType: 'date',
    inputType: 'form-date-input',
    label: 'Test Date',
  },
  {
    id: '6aa0a415-cc98-44f4-8a13-9484c7099727',
    valueType: 'number',
    inputType: 'form-slider',
    label: 'Test Number',
  },
  {
    id: 'ab317113-73d8-4a60-a719-ef6e257eba54',
    valueType: 'array-of-string',
    inputType: 'form-checkbox-list',
    label: 'Test Options',
    options: [
      {
        label: 'Option 1',
        id: 'adadb109-e6fc-4227-939a-be80692381ff',
        value: false,
      },
      {
        label: 'Option 2',
        id: '7bb6a81d-9fae-46bd-a516-93b07546cd23',
        value: false,
      },
      {
        label: 'Option 3',
        id: '48fc4e1c-45fb-4003-b70e-8c28f33e1c3b',
        value: false,
      },
    ],
  },
];
// Mock input values, will come from the actual value of the variables when the step is executed in WFE
export const MOCK_INPUT_VALUES: Array<ResolvedVariable> = [
  {
    id: '2be5f39a-bc6f-4674-86a9-a38ac08590e0',
    value: 'Fooby',
    label: 'Foo',
    valueType: 'string',
    inputType: 'form-text-input',
  },
  {
    id: '2be5f39a-bc6f-4674-86a9-a38ac08590e1',
    value: 'Barry',
    label: 'Foo',
    valueType: 'string',
    inputType: 'form-text-input',
  },
  {
    id: '2be5f39a-bc6f-4674-86a9-a38ac08590e2',
    value: 'Bazzy',
    label: 'Foo',
    valueType: 'string',
    inputType: 'form-text-input',
  },
  {
    id: 'bb13e3dc-3e35-404d-bfda-46e8b2978034',
    valueType: 'date',
    inputType: 'form-date-input',
    label: 'Test Date',
    value: toISOTzString(new Date()),
  },
  {
    id: '6aa0a415-cc98-44f4-8a13-9484c7099727',
    valueType: 'number',
    inputType: 'form-slider',
    label: 'Test Number',
    value: 5,
  },
  {
    id: 'ab317113-73d8-4a60-a719-ef6e257eba54',
    valueType: 'array-of-string',
    inputType: 'form-checkbox-list',
    label: 'Test Options',
    options: [
      {
        label: 'Option 1',
        id: 'adadb109-e6fc-4227-939a-be80692381ff',
        value: false,
      },
      {
        label: 'Option 2',
        id: '7bb6a81d-9fae-46bd-a516-93b07546cd23',
        value: false,
      },
      {
        label: 'Option 3',
        id: '48fc4e1c-45fb-4003-b70e-8c28f33e1c3b',
        value: false,
      },
    ],
    value: ['Option 1', 'Option 2'],
  },
];
export const MOCK_WORKSPACE_ID = '15BD8590ADB0099F';
