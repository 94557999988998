import {
  splitAndConvertString,
  genericExtractUsingRegex,
} from '../../utils/regexUtils';

const VARIABLE_SPAN_REGEX = /<span data-variableid="(.*?)">(.*?)<\/span>/;

export function extractVariableIdFromHtmlString(str: string): string | null {
  return genericExtractUsingRegex(str, VARIABLE_SPAN_REGEX, 1);
}

export function extractSpanTextFromHtmlString(str: string): string | null {
  return genericExtractUsingRegex(str, VARIABLE_SPAN_REGEX, 2);
}

/**
 * For the given rich text string, pull out any that are varible strings, and apply the conversion function to them.
 * Returns an array of remainining strings, and these converted items.
 * @param htmlString
 * @param conversionFn
 */
export const extractVariables = <T>(
  htmlString: string,
  conversionFn: (varString: string) => T,
) => {
  // attaching `g` (global) flag to matchAll variables
  return splitAndConvertString(
    htmlString,
    new RegExp(VARIABLE_SPAN_REGEX, 'g'),
    conversionFn,
  );
};
