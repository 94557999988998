/* eslint-disable react-hooks/rules-of-hooks */
// It's otherwise going to error if tyring to use hooks in the comps. Or we could rename them to upper case?
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  ColorPicker,
  ColorPickerProps,
  Slider,
  PositionSelector,
  AlignmentSelector,
  Dropdown,
  OptionsPicker,
  PositionSelectorProps,
  AlignmentSelectorProps,
  DropdownProps,
  OptionPickerProps,
  LinkGeneratorProps,
  DatePickerPropTypes,
  LinkGenerator,
  DatePicker,
  TextField,
  GenericTextareaWithVariablesProps,
  VariableTextInput,
  GenericTextareaWithVariables,
  GoogleAutoSuggest,
  DateFormatPicker,
  ToggleSwitch,
  SocialIconsOptionsPicker,
  DropdownSelectedOptionsType,
  CodeEditor,
  LabelWithTooltip,
  LabelsMaker,
  Divider,
  EditCompanyInfoButton,
  FormDataType,
} from '@whispir/ui-lib-v2';
import { ServicedUploadImage } from '@whispir/media-content';
import moment from 'moment-timezone';
import {
  AbstractContentDataOnly,
  AbstractContentTypes,
  AbstractRecords,
  AbstractVersionsForItemTypes,
} from '../framework/ContentDefinitions';
import {
  AbstractControlsGenerationMap,
  AbstractControlsDataMap,
  AbstractControlsConfigObject,
  AbstractIndividualControlConfiguration,
  ControlConfigGroup,
} from '../framework/Controls';

import { SelfDismissAlert } from '../components/Other/SelfDismissAlert/Alert';
import { ApisContext } from '../components/Contexts/ApisProvider';
import { LabelsMakerValueType, RatingType } from '../utils/utils.types';
import { useTrackingEvents } from '../components/Contexts/TrackingEventsProvider';
import { ContentPropertiesPanelProps } from '../framework/ControlPanelProps';
import { ModeContext } from '../components/Contexts/ModeProvider';
import {
  StyledControlGroupLabel,
  StyledWrapper,
  StyledControlGroupSubLabel,
  ButtonGroupSingleSelectWrapper,
  ButtonGroupSingleSelectButton,
  ButtonGroupSingleSelectLabel,
  ButtonGroupSingleSelectBar,
  ButtonGroupSingleSelectBarColumn,
  StyledTextArea,
  AlertContainer,
} from './controls.styles';

const TEXT_AREA_LINES = 4;

//Keep this here, it's helpful for debugging bad ui-lib-v2 imports

//console.info({
//  WspColorPicker,
//  Slider,
//  PositionSelector,
//  AlignmentSelector,
//  Dropdown,
//  OptionsPicker,
//  DatePickerFromTo,
//});

/**
 * Defining what controls exist, and what their options they can take.
 */
export type ControlDataTypes = {
  // Take this item as an example

  // Control type is 'text'
  text: {
    // The data used to configure the text input is here
    data: {
      label: string;
      placeholder?: string;
    };
    // The value type that this control produces
    valueType: string;
  };

  'text-area': {
    data: {
      label: string;
      placeholder?: string;
    };
    valueType: string;
  };

  radio: {
    data: {
      label: string;
      options: Record<string, string>; // This is possibly looser than I would like. Maybe not though. See for example the align: 'left' | 'right' | 'center';
    };
    valueType: string;
  };

  'variable-text-input': {
    data: {
      label: string;
      variables: GenericTextareaWithVariablesProps['variableGroups'];
      placeholder?: string;
      helperText?: ReactNode;
    };
    valueType: string;
  };
  'variable-text-area': {
    data: {
      label: string;
      variables: GenericTextareaWithVariablesProps['variableGroups'];
      placeholder?: string;
      helperText?: ReactNode;
    };
    valueType: string;
  };
  'rich-text-paragraph': {
    data: {
      label?: string;
      variables: GenericTextareaWithVariablesProps['variableGroups'];
    };
    valueType: string;
  };
  boolean: {
    data: {
      label: string;
      info?: string;
    };
    valueType: boolean;
  };
  'color-picker': {
    data: {
      label: string;
      labelSize?: ColorPickerProps['labelSize'];
    };
    valueType: string;
  };
  slider: {
    data: {
      label: string;
      minValue: number;
      maxValue: number;
      step: number;
      units?: string;
    };
    valueType: number;
  };
  'position-selector': {
    data: {
      label: string;
    };
    valueType: PositionSelectorProps['defaultValue'];
  };
  'alignment-selector': {
    data: {
      label: string;
      variant: 'text' | 'leftright' | 'items';
    };
    valueType: AlignmentSelectorProps['defaultValue'];
  };
  dropdown: {
    data: {
      options: DropdownProps['options'];
      label: string;
      orientation?: 'horizontal' | 'vertical';
    };
    valueType: any;
  };
  'options-creator': {
    data: {
      label: string;
      mode: 'single' | 'multi';
    };
    valueType: OptionPickerProps['defaultValue'];
  };
  'links-generator': {
    data: {
      label: string;
      showColorPickers: boolean;
    };
    valueType: LinkGeneratorProps['defaultValue'];
  };
  'date-picker': {
    data: {
      label: string;
      includeTime: boolean;
      minDate?: string;
      minDateMessage?: string;
      maxDate?: string;
      maxDateMessage?: string;
    };
    valueType: DatePickerPropTypes['defaultValue'];
  };
  'date-format-picker': {
    data: {
      label: string;
      format: string;
    };
    valueType: DatePickerPropTypes['defaultValue'];
  };
  'google-auto-suggest': {
    data: {
      label: string;
      info?: string;
    };
    valueType: string;
  };
  'social-icons-options-picker': {
    data: {
      label: string;
    };
    valueType: OptionPickerProps['defaultValue'];
  };
  'options-picker-buttons': {
    data: {
      label: string;
      mode: 'single' | 'multi';
    };
    valueType: OptionPickerProps['defaultValue'];
  };
  'code-editor': {
    data: {};
    valueType: {
      css: string;
      html: string;
      scss: string;
    };
  };
  'labels-maker': {
    data: {
      label: string;
    };
    valueType: LabelsMakerValueType | RatingType;
  };
  'image-upload': {
    data: {
      label: string;
    };
    valueType: string | undefined;
  };
  'button-group-single-select': {
    data: {
      buttonLabelValueMap: Record<string, string>;
    };
    valueType: string;
  };
  'edit-company-info': {
    data: {
      label: string;
    };
    valueType: FormDataType;
  };
};

/**
 * Implementation of control components here.
 *
 * !IMPORTANT  -
 * the control components should behave as _uncontrolled components_
 *
 * That is, they will get an initial value when they first mount (and component keys up the tree should ensure that controls remount if you change content item)
 * But do/should not need to respond to the value from props after that.
 */

export const propertyControlsMap: AbstractControlsGenerationMap<ControlDataTypes> = {
  // Example continues here
  text: (props) => {
    return (
      <TextField
        // configuration data comes from props.data
        label={props.data.label}
        placeholder={props.data.placeholder}
        type="text"
        variant="filled"
        // The actual value, comes from the defautValues of the content item, this is props.defaultValue
        defaultValue={props.defaultValue}
        // props.onChange is a curried function that knows how to take the value, the id of the content and the content data key and update the content.
        onChange={props.onChange}
        //https://whispir.atlassian.net/browse/WF-1731
        onChangeDelay={500}
      />
    );
  },
  'text-area': (props) => {
    return (
      <StyledTextArea
        label={props.data.label}
        placeholder={props.data.placeholder}
        type="text"
        variant="filled"
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        onChangeDelay={500}
        multiline={true}
        rows={TEXT_AREA_LINES}
        rowsMax={TEXT_AREA_LINES}
      />
    );
  },

  radio: (props) => {
    return (
      <div className="property-control property-control-radio-group">
        {props.data.label}:
        {Object.entries(props.data.options).map(([key, value]) => {
          //TODO: update to be using ui-lib components (Radio.tsx)
          // you will need to update the typings for this
          return (
            <label key={key}>
              <input
                type="radio"
                onChange={(e) => props.onChange(e.target.value)}
                name={props.data.label}
                value={key}
                checked={props.defaultValue === key}
              />{' '}
              {value}
            </label>
          );
        })}
      </div>
    );
  },

  'links-generator': (props) => {
    const { data } = props;

    return (
      <LinkGenerator
        defaultValue={props.defaultValue}
        hasBackgroundColorPicker={data.showColorPickers}
        onChange={(value) => props.onChange(JSON.parse(value))}
      />
    );
  },

  'date-picker': (props) => {
    const { data } = props;

    const handleChange = (value) => {
      // The `@whispir/ui-lib-v2` DatePicker returns a Date in the local timezone.
      // It is necessary to convert the local date to ISO8601 date string to so
      // that a ISO8601 date string is saved to content data.
      // This is necessary for serverside rendering to use accurate dates, without timezone guessing.
      // TODO: update `@whispir/ui-lib-v2` DatePicker to return ISO8601 date strings.
      const utcDate = moment(value).toISOString();

      props.onChange(utcDate);
    };

    return (
      <DatePicker
        label={data.label}
        includeTime={data.includeTime}
        onChange={handleChange}
        defaultValue={props.defaultValue}
        minDate={data.minDate}
        minDateMessage={data.minDateMessage}
        maxDate={data.maxDate}
        maxDateMessage={data.maxDateMessage}
        filled
      />
    );
  },
  'variable-text-input': (props) => {
    const { label, variables, helperText, placeholder } = props.data;

    // See notes below
    const onChangeRef = useRef(props.onChange);
    const onChangePassthroughRef = useRef((data) => {
      onChangeRef.current(data);
    });

    useEffect(() => {
      onChangeRef.current = props.onChange;
    });

    useEffect(() => {
      return () => {
        onChangeRef.current = () => undefined;
      };
    }, []);

    return (
      <VariableTextInput
        variableTextInputType="componentPropertyInput"
        activeChannel="componentPropertyInput"
        label={label}
        defaultTitle={label}
        defaultValue={props.defaultValue}
        variableMenuData={variables}
        onChange={onChangePassthroughRef.current}
        placeholder={placeholder}
        helperText={helperText}
      />
    );
  },
  'variable-text-area': (props) => {
    const { label, variables, helperText, placeholder } = props.data;

    // See notes below
    const onChangeRef = useRef(props.onChange);
    const onChangePassthroughRef = useRef((data) => {
      onChangeRef.current(data);
    });

    useEffect(() => {
      onChangeRef.current = props.onChange;
    });

    useEffect(() => {
      return () => {
        onChangeRef.current = () => undefined;
      };
    }, []);

    return (
      <VariableTextInput
        variableTextInputType="componentPropertyInput"
        activeChannel="componentPropertyInput"
        label={label}
        defaultTitle={label}
        defaultValue={props.defaultValue}
        variableMenuData={variables}
        onChange={onChangePassthroughRef.current}
        placeholder={placeholder}
        helperText={helperText}
        minLines={TEXT_AREA_LINES}
      />
    );
  },

  'rich-text-paragraph': (props) => {
    const { label, variables } = props.data;

    // There's a couple of things going on here.
    // It's all to do with the fact that draftail fires an onchange when it mounts, and also, after the component unmounts.
    // So:
    // - Firing after the component unmounts.
    //    - This causes an infinite selection bug, where the component unmounting causes it to be reselected.
    //    - The solution here, is to detect the unmount, and set the onChange handler to an empty function.
    //    - Real solution is to solve that issue inside of draftail.

    const onChangeRef = useRef(props.onChange);
    const onChangePassthroughRef = useRef((data) => {
      onChangeRef.current(data);
    });

    useEffect(() => {
      onChangeRef.current = props.onChange;
    });

    useEffect(() => {
      return () => {
        onChangeRef.current = () => undefined;
      };
    }, []);

    return (
      <GenericTextareaWithVariables
        variableGroups={variables}
        ariaLabel={label}
        onSave={onChangePassthroughRef.current}
        showVariableButton={variables.length > 0}
        initialValue={props.defaultValue}
      />
    );
  },

  boolean: (props) => {
    const { label, info } = props.data;

    return (
      <ToggleSwitch
        label={<LabelWithTooltip label={label} info={info} />}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    );
  },

  'color-picker': (props) => {
    return (
      <ColorPicker
        label={props.data.label}
        labelSize={props.data.labelSize}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        // includeAlpha
      />
    );
  },

  slider: (props) => {
    const { label, minValue, maxValue, step, units } = props.data;
    return (
      <Slider
        label={label}
        minValue={minValue}
        maxValue={maxValue}
        defaultValue={props.defaultValue}
        step={step}
        onChange={props.onChange}
        units={units}
      />
    );
  },

  'position-selector': (props) => {
    const { label } = props.data;

    return (
      <PositionSelector
        label={label}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    );
  },

  'alignment-selector': (props) => {
    const { label, variant } = props.data;

    return (
      <AlignmentSelector
        includeCenterAlignment={variant === 'leftright' ? false : true}
        iconType={variant}
        label={label}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    );
  },
  dropdown: (props) => {
    const handleOptionSelected = (
      value: string | Array<string>,
      option: DropdownSelectedOptionsType,
    ) => {
      if (Array.isArray(value)) {
        throw new Error("We weren't expecting an array here");
      }

      props.onChange(value);
    };

    const { options, label, orientation } = props.data;

    return (
      <Dropdown
        id={label}
        value={props.defaultValue}
        options={options}
        label={label}
        orientation={orientation}
        onChange={handleOptionSelected}
        variant="filled"
      />
    );
  },

  'options-creator': (props) => {
    const { mode } = props.data;
    return (
      <OptionsPicker
        optionsType={
          mode === 'multi' ? 'checkboxes' : 'radio-buttons/dropdown-menu'
        }
        componentId="options"
        onChange={(_string, value) => {
          props.onChange(value);
        }}
        defaultValue={props.defaultValue}
        filledTextAreaStyle
      />
    );
  },
  'options-picker-buttons': (props) => {
    return (
      <OptionsPicker
        optionsType={'radio-buttons/dropdown-menu'}
        componentId="options"
        onChange={(_string, value) => {
          props.onChange(value);
        }}
        defaultValue={props.defaultValue}
        filledTextAreaStyle
        isButtonPicker
      />
    );
  },
  'google-auto-suggest': (props) => {
    const { label, info } = props.data;

    return (
      <GoogleAutoSuggest
        label={info ? <LabelWithTooltip label={label} info={info} /> : label}
        onChange={props.onChange}
      />
    );
  },
  'social-icons-options-picker': (props) => {
    const urlMapper = {
      Facebook: 'facebook.com/',
      LinkedIn: 'linkedin.com/',
      Instagram: 'instagram.com/',
      Discord: 'discord.com/',
      Pinterest: 'pinterest.com/',
      Reddit: 'reddit.com/',
      Twitter: 'twitter.com/',
      TikTok: 'tiktok.com/',
      WhatsApp: 'whatsapp.com/',
      WeChat: 'wechat.com/',
      Youtube: 'youtube.com/',
      Vimeo: 'vimeo.com/',
      Website: '',
      Email: '',
      'Custom Icon': '',
    };

    const handleSocialDropdownChange = (data) => {
      const { selectedIcon } = data;

      data.urlText = urlMapper[selectedIcon] ? urlMapper[selectedIcon] : '';
      data.altText = selectedIcon !== 'Custom Icon' ? selectedIcon : '';

      const isOtherIcons = ['Website', 'Custom Icon'].some(
        (s) => s === selectedIcon,
      );
      const isEmailIcon = selectedIcon === 'Email';

      data.urlPlaceholder = 'Enter URL';
      data.urlLabel = 'URL';

      if (isEmailIcon) {
        data.urlTextAdornment = '';
        data.urlPlaceholder = 'email@business.com';
        data.urlLabel = 'Email Address';
      } else if (isOtherIcons) {
        data.urlTextAdornment = '';
      } else {
        data.urlTextAdornment = 'https://';
      }
    };

    return (
      <SocialIconsOptionsPicker
        onChange={(_string, value) => {
          props.onChange(value);
        }}
        defaultValue={props.defaultValue}
        options={Object.keys(urlMapper)}
        handleDropdownChange={handleSocialDropdownChange}
        defaultSelectedOption={{
          selectedIcon: 'Facebook',
          urlText: 'facebook.com/',
          altText: 'Facebook',
          title: 'Social Icon',
          value: true,
          label: '',
          urlTextAdornment: 'https://',
        }}
      />
    );
  },
  'date-format-picker': (props) => {
    const { label, format } = props.data;
    return (
      <DateFormatPicker
        dateLibrary="date-fns"
        filled
        label={label}
        onChange={props.onChange}
        exampleDate={new Date()}
        includeFormatInLabel
        defaultFormat={format}
      />
    );
  },
  'code-editor': (props) => {
    const { cssCompilerApi } = useContext(ApisContext);
    const { contentMode } = useContext(ModeContext);
    const [hasScriptTag, setHasScriptTag] = useState(false);

    const compileWrappedScopedScss = async (scss: string): Promise<string> => {
      const {
        data: { css },
      } = await axios.request({
        method: 'POST',
        url: `${cssCompilerApi}/compile`,
        withCredentials: true,
        data: {
          scss,
        },
      });
      return css;
    };

    const handleCodeEditorChange = async (value: Record<string, unknown>) => {
      const newCompiledCss = await compileWrappedScopedScss(
        `#id-${props.id} {${value.css}}`,
      );

      props.onChange({
        html: value.html as string,
        css: newCompiledCss,
        scss: value.css as string,
      });
    };

    const handleOnFindMatchesHtml = (matches) => {
      setHasScriptTag(matches.length !== 0);
    };

    return (
      <>
        <AlertContainer>
          {contentMode === 'email' ||
            (contentMode === 'test' && (
              <SelfDismissAlert
                title="Please note"
                content="When coding emails, remember to use HTML <table> elements for your email structure for the best user viewing experience"
              />
            ))}
          {hasScriptTag && (
            <SelfDismissAlert
              type="inline"
              alert="error"
              content="<script> tags are currently unsupported and will not show in your designs"
            />
          )}
        </AlertContainer>
        <CodeEditor
          type="all"
          theme="vs-light"
          height="600px"
          defaultValue={{
            html: props.defaultValue.html,
            css: props.defaultValue.scss,
          }}
          defaultValidtion={{ css: [], html: [] }}
          onChange={handleCodeEditorChange}
          onValidation={() => {}}
          findPatternHtml={{
            pattern: '<script\\b[^>]*>[\\s\\S\\n]*?<\\/script>',
            highlightPattern: true,
            onFindMatches: handleOnFindMatchesHtml,
          }}
        />
      </>
    );
  },
  'labels-maker': (props) => {
    return (
      <LabelsMaker
        id="labels"
        ratingType="Stars"
        defaultValue={props.defaultValue as LabelsMakerValueType}
        onChange={props.onChange}
      />
    );
  },
  'image-upload': (props) => {
    const { mediaApi } = useContext(ApisContext);

    return (
      <ServicedUploadImage
        onSelectedImage={props.onChange}
        label={props.data.label}
        currentSource={props.defaultValue}
        endpointUrl={mediaApi !== '' ? mediaApi : undefined}
      />
    );
  },
  'button-group-single-select': (props) => (
    <ButtonGroupSingleSelectWrapper>
      {Object.keys(props.data.buttonLabelValueMap).map((label, value) => (
        <ButtonGroupSingleSelectButton
          key={`ButtonGroupSingleSelectButton_${label}`}
          isSelected={
            props.defaultValue === props.data.buttonLabelValueMap[label]
          }
          onClick={() => props.onChange(props.data.buttonLabelValueMap[label])}
        >
          <ButtonGroupSingleSelectLabel>{label}</ButtonGroupSingleSelectLabel>
          <ButtonGroupSingleSelectBar columns={Number(value) + 1}>
            {Array(Number(value) + 1)
              .fill(null)
              .map((_, index) => {
                return (
                  <ButtonGroupSingleSelectBarColumn
                    key={`ButtonGroupSingleSelectBarColumn_${index}`}
                  />
                );
              })}
          </ButtonGroupSingleSelectBar>
        </ButtonGroupSingleSelectButton>
      ))}
    </ButtonGroupSingleSelectWrapper>
  ),
  'edit-company-info': (props) => (
    <EditCompanyInfoButton
      defaultData={props.defaultValue}
      handleSave={props.onChange}
    />
  ),
};

/**
 * Implemntation of the control generation map goes in this function
 *
 * This version is not specific  to our actual control generators, so is good for testing
 * @param props
 * @param configGroup
 * @param controlsMap
 */
export const abstractGenerateControls = <
  TRecords extends AbstractRecords,
  TContentType extends AbstractContentTypes<TRecords>,
  TContentVersion extends AbstractVersionsForItemTypes<TRecords, TContentType>,
  TControlsMap extends AbstractControlsDataMap
>(
  props: ContentPropertiesPanelProps<TRecords, TContentType, TContentVersion>,
  configGroup: ControlConfigGroup<
    TRecords,
    TContentType,
    TContentVersion,
    TControlsMap
  >,
  controlsMap: AbstractControlsGenerationMap<TControlsMap>,
): JSX.Element => {
  const { controlsConfig, drawLine = true } = configGroup;

  const controlConfigs = (Object.values(controlsConfig) as Array<
    AbstractIndividualControlConfiguration<
      TRecords,
      TContentType,
      TContentVersion,
      TControlsMap,
      keyof AbstractContentDataOnly<TRecords, TContentType, TContentVersion>
    >
  >).filter((v) => v.isVisible === undefined || v.isVisible); // Is visible by default

  const { trackControlComponentInteraction } = useTrackingEvents();

  return (
    <>
      <StyledWrapper variant={configGroup.variant}>
        {controlConfigs.map((v) => {
          const propertyControl = controlsMap[v.controlType];

          const onChange = (value) => {
            props.onContentUpdate(
              {
                ...props.contentItem,
                data: {
                  // @ts-ignore Data is always an object type.
                  ...props.contentItem.data,
                  [v.contentDataKey]: value,
                },
              },
              [],
            );

            trackControlComponentInteraction({
              contentType: props.contentItem.type,
              //@ts-ignore - ugh.
              controlType: v.controlType,
              controlKey: v.contentDataKey,
              controlValue: value,
            });
          };

          return (
            <div
              className="property-control"
              key={`${props.contentItem.id}-${v.contentDataKey}`}
            >
              {propertyControl({
                id: props.contentItem.id,
                onChange,
                defaultValue: props.contentItem.data[v.contentDataKey],
                variables: props.variables,
                data: v.data,
              })}
            </div>
          );
        })}
      </StyledWrapper>
      {drawLine && <Divider dividerType="solid" isHorizontal />}
    </>
  );
};

/**
 * Production version of the above function
 * @param props
 * @param options
 */
export const generateControls = <
  TRecords extends AbstractRecords,
  TContentType extends AbstractContentTypes<TRecords>,
  TContentVersion extends AbstractVersionsForItemTypes<TRecords, TContentType>
>(
  props: ContentPropertiesPanelProps<TRecords, TContentType, TContentVersion>,
  options:
    | Array<
        ControlConfigGroup<
          TRecords,
          TContentType,
          TContentVersion,
          ControlDataTypes
        >
      >
    | AbstractControlsConfigObject<
        TRecords,
        TContentType,
        TContentVersion,
        ControlDataTypes
      >,
) => {
  if (Array.isArray(options)) {
    return (
      <>
        {options.map((v) => (
          <div key={v.label || v.subLabel}>
            {v.label && (
              <StyledControlGroupLabel>{v.label}</StyledControlGroupLabel>
            )}
            {v.subLabel && (
              <StyledControlGroupSubLabel>
                {v.subLabel}
              </StyledControlGroupSubLabel>
            )}
            <div>{abstractGenerateControls(props, v, propertyControlsMap)}</div>
          </div>
        ))}
      </>
    );
  } else {
    return abstractGenerateControls(
      props,
      { label: '', variant: 'normal', controlsConfig: options, drawLine: true },
      propertyControlsMap,
    );
  }
};
