import { Typography } from '@material-ui/core';
import React from 'react';

type RequiredStyles = Required<
  Pick<
    React.CSSProperties,
    | 'backgroundColor'
    | 'paddingTop'
    | 'paddingBottom'
    | 'paddingRight'
    | 'paddingLeft'
  >
>;

type Props = {
  style?: React.CSSProperties & RequiredStyles;
  fullWidth?: boolean;
  label?: string;
  htmlFor?: string;
  isFieldRequired?: boolean;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const createStyle = (
  fullWidth: boolean | undefined,
  style?: React.CSSProperties,
): React.CSSProperties => ({
  boxSizing: 'border-box',
  maxWidth: fullWidth ? '100%' : '600px',
  margin: '0 auto',
  overflowWrap: 'break-word',
  ...style,
});

export const ContentContainer = ({
  children,
  style,
  fullWidth,
  label,
  htmlFor,
  isFieldRequired,
}: Props) => {
  if (label && !htmlFor) {
    console.warn(
      'Received a label, but no htmlFor. You probably want the htmlFor!',
    );
  }

  return (
    <div style={createStyle(fullWidth, style)}>
      {label && (
        <Typography
          variant="body2"
          component="label"
          style={{
            display: 'block',
            textAlign: 'left',
            paddingBottom: '8px',
          }}
          htmlFor={htmlFor}
        >
          {label}
          {isFieldRequired && ' *'}
        </Typography>
      )}
      {children}
    </div>
  );
};
