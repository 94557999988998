import { memo, useEffect } from 'react';

import { getEmptyImage } from 'react-dnd-html5-backend';
import { Icon } from '@whispir/ui-lib-v2';
import { useDrag } from 'react-dnd';
import { contentCreationMap } from '../../../content/content';
import { useTrackingEvents } from '../../Contexts/TrackingEventsProvider';
import BuilderColumn from '../Column';
import { useRenderVariables } from '../utils/hooks';
import {
  getJsxFunction,
  rowReferenceContainsFormItem,
  rowReferenceContainsUnsubscribe,
} from '../utils/helpers';
import { BuilderRowProps, isRowReference } from '../utils/types';
import { DRAGGABLE_TYPES } from '../utils/constants';
import DraggableItemControls from '../DraggableItemControls';
import * as S from './styles';

const BuilderRow = memo(
  ({
    referenceData,
    contentMap,
    handleDrop,
    path,
    setSelectedItem,
    selectedItemId,
    handleDeleteItem, //onDeleteItemClick
    handleDuplicateItem, //onDuplicateItemClick
  }: BuilderRowProps) => {
    if (!isRowReference(referenceData)) {
      throw new Error('Row container received bad drop item reference');
    }

    const { trackContentMovedEvent } = useTrackingEvents();

    // eslint-disable-next-line
    const [_, dragRef, preview] = useDrag({
      type: DRAGGABLE_TYPES.ROW,
      item: {
        layoutType: DRAGGABLE_TYPES.ROW,
        contentId: referenceData.contentId,
        children: referenceData.children,
        path,
      },
      end: () => {
        trackContentMovedEvent({
          contentType: 'layout-column', // TODO: update this when more layout types are released
          // @ts-ignore
          containedComponents: referenceData.children.reduce((prev, column) => {
            return [
              ...prev,
              ...column.children.map((item) => contentMap[item.contentId].type),
            ];
          }, []),
        });
      },
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { RenderVariableSpan, RenderVariableText } = useRenderVariables();

    const component = contentMap[referenceData.contentId];

    if (!component) {
      throw new Error('Component not found');
    }

    const JsxFunction = getJsxFunction(component);
    const isSelected = selectedItemId === referenceData.contentId;
    const isFormRow = rowReferenceContainsFormItem(referenceData, contentMap);
    const containsUnsubscribe = rowReferenceContainsUnsubscribe(
      referenceData,
      contentMap,
    );

    if (isFormRow && referenceData.children.length > 1) {
      throw new Error('Row can only have 1 form column');
    }

    const columnWidth = `${100 / referenceData.children.length}%`;

    const columnContainerWidth = `${
      (window.innerWidth > 600 ? 600 : window.innerWidth) -
      (component.data.paddingLeft + component.data.paddingRight)
    }px`;

    const ariaSubject = `${contentCreationMap[component.type].label} ${
      component.id
    }`;

    return (
      <S.Wrapper
        isSelected={isSelected}
        ref={dragRef}
        onClick={(e) => {
          e.preventDefault();
          setSelectedItem(contentMap[referenceData.contentId]);
        }}
        role="presentation"
        aria-label={`Preview ${ariaSubject}`}
      >
        {/* ACCESSIBILITY: These are 'meta controls' - ie. delete, duplicate, for the content. Need to behave as buttonOverlayStyles. */}
        {isSelected && (
          <DraggableItemControls
            isSingularOverride={containsUnsubscribe}
            ariaSubject={component.type as string}
            onDuplicateItem={() =>
              handleDuplicateItem({
                item: referenceData,
                path,
                type: component.type,
              })
            }
            onDeleteItem={() =>
              handleDeleteItem({
                path,
                id: referenceData.contentId,
                type: referenceData.layoutType,
              })
            }
          />
        )}
        <S.RowHoverIcon>
          <Icon icon="TextAndImage" />
        </S.RowHoverIcon>
        <JsxFunction
          key={component.id}
          contentItem={component}
          formUpdateFunction={() => {}}
          RenderVariableHtml={RenderVariableSpan}
          RenderVariableText={RenderVariableText}
        >
          <S.Container style={{ width: columnContainerWidth }}>
            {referenceData.children.map((column, index) => {
              const leftPath = `${path}-${index}`;
              const rightPath = `${path}-${index + 1}`;
              const dropLineDataArray = [
                {
                  path: leftPath,
                  childrenCount: referenceData.children.length,
                },
                {
                  path: rightPath,
                  childrenCount: referenceData.children.length,
                },
              ];

              return (
                <div
                  key={`${component.id}-${index}`}
                  // This position relative is for the column <DropLines/>
                  style={{ position: 'relative', width: columnWidth }}
                >
                  <BuilderColumn
                    contentMap={contentMap}
                    referenceData={column}
                    path={leftPath}
                    handleDrop={handleDrop}
                    setSelectedItem={setSelectedItem}
                    selectedItemId={selectedItemId}
                    handleDeleteItem={handleDeleteItem}
                    handleDuplicateItem={handleDuplicateItem}
                    parentLineDataArray={dropLineDataArray}
                    isFormRow={isFormRow}
                  />
                </div>
              );
            })}
          </S.Container>
        </JsxFunction>
      </S.Wrapper>
    );
  },
);

export default BuilderRow;
