import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { useOnMount } from '../../../utils/useOnmount';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

export const FormDropdownContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-dropdown'
> = {
  type: 'form-dropdown',
  label: 'Dropdown',
  icon: 'DropDown',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableHtml,
      }) => {
        const { id, data } = contentItem;
        const {
          backgroundColor,
          label,
          requiredField,
          helperText,
          options,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        } = data;

        const initialValue = options.find(({ value }) => value === true);

        useOnMount(() => {
          formUpdateFunction(id, initialValue ? initialValue.label : '');
        });

        return (
          <ContentContainer
            style={{
              backgroundColor,
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
            }}
            isFieldRequired={requiredField}
            label={label}
            htmlFor={id}
          >
            <FormControl required={requiredField} fullWidth>
              {/* 
                keeping InputLabel here for accessibility reasons 
                see: https://material-ui.com/components/selects/#accessibility
                but we don't want to show two labels 
              */}
              <InputLabel
                id={id}
                style={{
                  visibility: 'hidden',
                }}
              >
                {label}
              </InputLabel>
              <Select
                style={{
                  textAlign: 'left',
                }}
                variant="outlined"
                labelId={id}
                key={`${id}-${initialValue?.id}`}
                defaultValue={initialValue?.label || ''}
                onChange={(e) => formUpdateFunction(id, e.target.value)}
              >
                {options.map(({ id, label }) => (
                  <MenuItem key={`${id}-${label}`} value={label}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                <RenderVariableHtml htmlString={helperText} />
              </FormHelperText>
            </FormControl>
          </ContentContainer>
        );
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        label: 'Dropdown',
        requiredField: false,
        helperText: '',
        options: [
          {
            id: uuid(),
            label: 'Option 1',
            value: false,
          },
          {
            id: uuid(),
            label: 'Option 2',
            value: false,
          },
          {
            id: uuid(),
            label: 'Option 3',
            value: false,
          },
        ],
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              label: {
                controlType: 'text',
                contentDataKey: 'label',
                data: {
                  label: 'Field Label',
                },
              },
              helperText: {
                controlType: 'variable-text-input',
                contentDataKey: 'helperText',
                data: {
                  label: 'Helper Text',
                  variables,
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {},
          },
          {
            label: 'Options',
            controlsConfig: {
              options: {
                controlType: 'options-creator',
                contentDataKey: 'options',
                data: {
                  label: 'Options',
                  mode: 'single',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },

      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'string',
            inputType: contentItem.type,
            label: contentItem.data.label,
            options: contentItem.data.options,
          },
        ];
      },
    },
  },
};
