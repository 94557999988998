/* generateControls placeholders */
export const companyNamePlaceholder = 'Enter company name';
export const addressPlaceholder = `Enter street name
City, State, postcode
Country`;
// ${fakeAddress}`;
export const subscriptionExplanationPlaceholder =
  'You are receiving this email because you opted in via our website. Thank you!';

/* display text defaults */
export const unsubscribePrompt =
  'To stop receiving these emails, please click on';
export const currentYear = new Date().getFullYear();
export const getCopyrightText = (companyName: string) =>
  `Copyright © ${currentYear} ${companyName}. All rights reserved.`;
export const defaultCompanyName = 'Company Name';
export const getAddressText = (address: string) =>
  `Our mailing address is: 
  ${address.length ? address : addressPlaceholder}`;

export const unresolvedUnsubscribeLinkVariable = '@@unsubscribe_link@@';

/* The functions below are currently unused and are here for future use. */

type FormDataType = any; //KnownContentTypeAndVersionMap['display-unsubscribe-footer']['1']['formData'];

const mailingAddressTitle = 'Our mailing address is:';

const ADDRESS_KEYS_FORMAT = [
  'address-line-1',
  'address-line-2',
  ['city', 'state', 'post-code'],
  'country',
];

// use generic type to specify return type (can't infer returnType's type from its value, so have to cast as generic type param)
// since functions are declared below for each return type, function overloading is not needed
function getAddressDivs<T extends string | JSX.Element>(
  formData: FormDataType,
  returnType: 'mjml' | 'jsx',
): Array<T> | undefined {
  const getDiv = (value: string) =>
    returnType === 'jsx'
      ? ((<div key={`unsubscribe-${value}`}>{value}</div>) as T)
      : (`<div>${value}</div>` as T);

  const titleDiv = getDiv(mailingAddressTitle);

  const addressDivs = ADDRESS_KEYS_FORMAT.reduce<Array<T>>(
    (acc, key) => {
      if (!key) return acc;
      if (typeof key === 'string' && formData[key] && formData[key].length) {
        acc.push(getDiv(formData[key]));
        return acc;
      }
      if (Array.isArray(key) && key.length) {
        // address fields on the same line are comma seperated
        const lines: Array<string> = key.reduce<Array<string>>((acc, k) => {
          if (formData[k] && formData[k].length) {
            acc.push(formData[k]);
          }
          return acc;
        }, []);

        if (lines.length) acc.push(getDiv(lines.join(', ')));
      }
      return acc;
    },
    [titleDiv],
  );

  // if no address fields are present, return undefined
  return addressDivs.length > 1 ? addressDivs : undefined;
}

export const getMjmlAddressDivs = (formData: FormDataType) => {
  const divs = getAddressDivs<string>(formData, 'mjml');
  if (divs) return divs.join('');
};
export const getJsxAddressDivs = (formData: FormDataType) =>
  getAddressDivs<JSX.Element>(formData, 'jsx');
