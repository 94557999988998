import styled from 'styled-components';
import { Colors, Elevations, Fonts } from '@whispir/ui-lib-v2';

export const StyledSubject = styled.div`
  box-shadow: ${Elevations.elevation_1};
  padding: 8px 16px;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;

  .subject-container {
    max-width: 600px;
    margin: 0 auto;
  }

  div[class^='VariableTextInput'],
  div[class*=' VariableTextInput'] {
    margin-top: 0;
  }

  &.has-error {
    .variable-picker-field--label {
      color: ${Colors.error};
    }

    .MuiFormHelperText-root {
      ${Fonts.caption};
      color: ${Colors.error};
      margin-top: 8px;
    }

    .public-DraftEditor-content {
      border-bottom: 2px solid ${Colors.error};
    }
  }
`;
