export const replaceElementAtIndex = <T>(
  array: Array<T>,
  index: number,
  newElement: T,
): Array<T> => {
  const newArray = [...array];
  newArray.splice(index, 1, newElement);
  return newArray;
};

export const removeElementAtIndex = <T>(
  array: Array<T>,
  index: number,
): Array<T> => {
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
};

export const shiftElement = <T>(
  array: Array<T>,
  index: number,
  moveUp: boolean,
): Array<T> => {
  const copyArray = [...array];
  if (index < 0) {
    return array;
  }

  if ((moveUp && index < array.length - 1) || (!moveUp && index > 0)) {
    const element = copyArray.splice(index, 1);
    copyArray.splice(index + (moveUp ? 1 : -1), 0, ...element);
    return copyArray;
  } else {
    return array;
  }
};

export const insertElementAtIndex = <T>(
  array: T[],
  element: T,
  index: number,
): T[] => {
  return [
    // part of the array before the specified index
    ...array.slice(0, index),
    // inserted item
    element,
    // part of the array after the specified index
    ...array.slice(index),
  ];
};

/**
 * Probably use these when we start using drag and drop
 * @param array
 * @param fromIndex
 * @param toIndex
 */
export const shiftElementToIndex = <T>(
  array: Array<T>,
  fromIndex: number,
  toIndex: number,
): Array<T> => {
  const copyArray = [...array];
  if (fromIndex < 0) {
    return array;
  }

  const element = copyArray.splice(fromIndex, 1);
  copyArray.splice(toIndex, 0, ...element);
  return copyArray;
};
