import {
  convertVariablesToUIVariableGroups,
  Variable,
} from '@whispir/variables';
import { VariableTextInput } from '@whispir/ui-lib-v2';
import { FormHelperText } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useVariableGroups } from '../../Contexts/VariableProvider';
import { useModeContext } from '../../Contexts/ModeProvider';
import { extractVariables } from '../../VariableDisplay/variableRegexes';
import { StyledSubject } from './Subject.style';

type SubjectProps = {
  onChange: (subject: string, isValidSubject: boolean) => void;
  defaultValue: string;
  inputVariables: Array<Variable>;
  shouldDisplaySubjectFieldRequired?: boolean;
};

export const characterLimitExceedErrorMessage =
  'You have exceeded the maximum character count limit';
export const variablesLimitExceededErrorMessage =
  'You have exceeded the maximum 6 variable limit';

const errorMessages = {
  subjectMissing: {
    form: 'Page Title is required',
    web: 'Web Page Title is required',
    email: 'Subject line is required',
    test: 'Subject line is required',
  },
  subjectLengthExceeded: {
    form: characterLimitExceedErrorMessage,
    web: characterLimitExceedErrorMessage,
    email: characterLimitExceedErrorMessage,
    test: characterLimitExceedErrorMessage,
  },
  variablesLimitExceeded: {
    form: variablesLimitExceededErrorMessage,
    web: variablesLimitExceededErrorMessage,
    email: variablesLimitExceededErrorMessage,
    test: variablesLimitExceededErrorMessage,
  },
};

const VARIABLE_LENGTH = 5; // variable will hold an assumed weight of 5 characters
const MAX_SUBJECT_LENGTH = 78;
const MAX_SUBJECT_VARIABLES = 6;

export const Subject = ({
  onChange,
  defaultValue,
  inputVariables,
  shouldDisplaySubjectFieldRequired,
}: SubjectProps) => {
  const variableGroups = useVariableGroups();
  const { uiVariableGroups } = convertVariablesToUIVariableGroups(
    inputVariables,
    variableGroups,
  );
  const { contentMode } = useModeContext();
  const [isSubjectMissing, setIsSubjectMissing] = useState(false);
  const [isSubjectLengthExceeded, setIsSubjectLengthExceeded] = useState(
    defaultValue.length > MAX_SUBJECT_LENGTH,
  );
  const [isVariablesLimitExceeded, setIsVariablesLimitExceeded] = useState(
    false,
  );

  const isWeb = ['form', 'web'].includes(contentMode);

  const [onChangeCount, setOnChangeCount] = useState(0);

  const onSubjectChange = (subject: string) => {
    let variableCount = 0;
    const plainText = extractVariables(subject, () => {
      variableCount += 1;

      // Convert all variables to empty strings.
      return '';
    }).join('');

    const variableLimitExceeded = variableCount > MAX_SUBJECT_VARIABLES;
    const totalVariablesLength = variableCount * VARIABLE_LENGTH;
    const subjectLengthExceeded =
      plainText.length + totalVariablesLength > MAX_SUBJECT_LENGTH;

    setIsVariablesLimitExceeded(variableLimitExceeded);
    setIsSubjectLengthExceeded(subjectLengthExceeded);
    // VariableTextInput fires 2 onChange events during mount.
    // We want to suppress the initial onChange's to prevent the error message from showing on mount.
    // TODO: move this fix to Draftail in ui-lib
    if (onChangeCount >= 2) {
      setIsSubjectMissing(subject === '');
    } else {
      setOnChangeCount(onChangeCount + 1);
    }

    onChange(subject, !(subjectLengthExceeded || variableLimitExceeded));
  };

  useEffect(() => {
    if (shouldDisplaySubjectFieldRequired) {
      setIsSubjectMissing(true);

      // need to add onChangeCount increment for DraftTail to remove error
      // message onChange for isSubjectMissing
      if (onChangeCount < 2) {
        setOnChangeCount(onChangeCount + 1);
      }
    }
  }, [onChangeCount, shouldDisplaySubjectFieldRequired]);

  return (
    <StyledSubject
      className={
        isSubjectMissing || isSubjectLengthExceeded || isVariablesLimitExceeded
          ? 'has-error'
          : ''
      }
    >
      <div className="subject-container">
        <VariableTextInput
          defaultTitle=""
          key={defaultValue}
          variableTextInputType="componentPropertyInput"
          activeChannel="componentPropertyInput"
          defaultValue={defaultValue}
          variableMenuData={uiVariableGroups}
          onChange={onSubjectChange}
          label={isWeb ? 'Title *' : 'Subject *'}
          placeholder={isWeb ? 'Enter Title' : 'Enter Subject Line'}
        />
        {isSubjectMissing && (
          <FormHelperText>
            {errorMessages['subjectMissing'][contentMode]}
          </FormHelperText>
        )}
        {isSubjectLengthExceeded && (
          <FormHelperText error={true}>
            {errorMessages['subjectLengthExceeded'][contentMode]}
          </FormHelperText>
        )}
        {isVariablesLimitExceeded && (
          <FormHelperText error={true}>
            {errorMessages['variablesLimitExceeded'][contentMode]}
          </FormHelperText>
        )}
      </div>
    </StyledSubject>
  );
};
