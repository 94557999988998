import React, { useContext } from 'react';
import { VariableGroup, Variable } from '@whispir/variables';

export type VariableTypes = {
  variableGroups: VariableGroup[];
  inputVariables: Variable[];
};

type VariableProviderProps = VariableTypes;

export const VariableContext = React.createContext<VariableTypes>({
  variableGroups: [],
  inputVariables: [],
});

export const VariableProvider = (
  props: React.PropsWithChildren<VariableProviderProps>,
) => {
  const { children, variableGroups, inputVariables } = props;
  return (
    <VariableContext.Provider value={{ variableGroups, inputVariables }}>
      {children}
    </VariableContext.Provider>
  );
};

export const useVariableGroups = (): VariableGroup[] => {
  const { variableGroups } = useContext(VariableContext);
  return variableGroups;
};
