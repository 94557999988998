import { Spinner } from '@whispir/ui-lib-v2';
import axios from 'axios';
import { useContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import {
  previewRenderEmailFromContentData,
  RenderedDynamicContent,
} from '../../../exportUtils';
import { AbstractContentCreationMap } from '../../../framework/ContentCreation';
import { AbstractRecords } from '../../../framework/ContentDefinitions';
import { ApiLinkedContentBuilderProps } from '../../../mainEntryPoints/Builder';
import { ApisContext } from '../../Contexts/ApisProvider';
import { transformContentToSave } from '../utils/transformers';
import { isDisplayMapContentItem, LayoutBundle } from '../utils/types';
import * as S from './styles';
import {
  createGoogleMapId,
  normaliseZoom,
  parseLocationText,
} from './googleMapUtils';
import { EmailWithJsMapsInitialised } from './EmailWithJsMapsInitialised';
import { MapDataArray } from './types';

export const PreviewContent = <T extends AbstractRecords>({
  contentCreationMap,
  contentMode,
  layoutBundle,
  mobileWidth,
}: {
  contentCreationMap: AbstractContentCreationMap<T>;
  contentMode: ApiLinkedContentBuilderProps['contentMode'];
  layoutBundle: LayoutBundle;
  mobileWidth?: number;
}) => {
  const previewMode = !!mobileWidth ? 'mobile' : 'desktop';
  const { apiUrl } = useContext(ApisContext);

  const { rows, contentMap } = layoutBundle;
  const [emailString, setEmailString] = useState<string>();
  const [mapIds, setMapIds] = useState<MapDataArray>([]);

  const transformedContent = transformContentToSave({
    rows: rows,
    contentMap: contentMap,
  });

  // Use the clients locale for email rendering
  const { locale, timeZone } = new Intl.DateTimeFormat().resolvedOptions();

  const mjmlFunction = async (mjml: string): Promise<string> => {
    const res = await axios(`${apiUrl}/render`, {
      method: 'POST',
      data: {
        mjml,
        resolvedVariables: [],
      },
      withCredentials: true,
    });

    return res.data.emailString;
  };

  useAsyncEffect(async () => {
    if (contentMode === 'email') {
      const renderedEmailString = await previewRenderEmailFromContentData({
        data: transformedContent as any,
        parseMjmlFn: mjmlFunction,
        locale,
        timeZone,
        previewMode,
      });
      if (renderedEmailString) {
        const mapIds = Object.entries(layoutBundle.contentMap).reduce(
          (acc, [id, contentItem]) => {
            if (isDisplayMapContentItem(contentItem)) {
              const { locationText, zoom, mapType } = contentItem.data;
              const center = parseLocationText(locationText);
              acc.push({
                id: createGoogleMapId(id, previewMode),
                center,
                zoom: normaliseZoom(zoom),
                mapTypeId: mapType,
              });
            }
            return acc;
          },
          [] as MapDataArray,
        );

        setMapIds(mapIds);
        setEmailString(renderedEmailString);
      }
    }
  }, [rows, contentMap]);

  return contentMode !== 'email' ? (
    <S.ScrollWrapper>
      <RenderedDynamicContent
        data={transformedContent}
        // TODO: not sure why this is a type error, something to do with the generic type param
        // @ts-ignore
        contentCreationMap={contentCreationMap}
        formUpdateFunction={() => {}}
        maxWidth={mobileWidth}
      />
    </S.ScrollWrapper>
  ) : emailString ? (
    <S.EmailWrapper>
      <S.StyledFrame
        title={`${previewMode} email view`}
        head={
          mobileWidth && (
            <style
              dangerouslySetInnerHTML={{ __html: S.emailScrollStyleTag }}
            />
          )
        }
      >
        <EmailWithJsMapsInitialised
          emailString={emailString}
          mapDataArray={mapIds}
        />
      </S.StyledFrame>
    </S.EmailWrapper>
  ) : (
    <S.LoadingScreen>
      <Spinner size={mobileWidth ? 'medium' : 'large'} />
      <div>Hold tight while we load your designs</div>
    </S.LoadingScreen>
  );
};
