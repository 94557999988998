import { AxiosInstance } from 'axios';
import { useCallback } from 'react';

export const useRenderingEmailApi = ({ api }: { api: AxiosInstance }) => {
  const renderEmail = useCallback(
    async (body) => {
      const { data } = await api(`/render`, {
        method: 'POST',
        data: body,
      });

      return data;
    },
    [api],
  );

  return { renderEmail };
};
