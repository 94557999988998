export const Icons = {
  Star: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.24044 8.51918C6.46583 8.51918 7.69123 8.51436 8.91662 8.52481C9.09822 8.52481 9.16893 8.46693 9.22357 8.30293C9.98158 6.01177 10.7433 3.72222 11.5088 1.43428C11.5771 1.22687 11.6695 1.0468 11.9106 1.00821C12.187 0.964798 12.3775 1.09342 12.4795 1.3973C12.7768 2.27893 13.0714 3.16136 13.3634 4.04459C13.8386 5.47074 14.3121 6.89715 14.784 8.32383C14.8347 8.47658 14.9038 8.52401 15.0661 8.5232C17.5099 8.51784 19.9537 8.51624 22.3976 8.51838C22.5832 8.51838 22.7592 8.52963 22.8869 8.69122C23.079 8.9324 23.0251 9.21939 22.7351 9.43404C22.0569 9.93461 21.3773 10.4333 20.6965 10.9301C19.3787 11.8948 18.0595 12.8574 16.7391 13.8178C16.6097 13.9118 16.5783 13.9874 16.629 14.1394C17.4004 16.5088 18.1677 18.8792 18.9311 21.2508C18.9978 21.4566 19.0372 21.6527 18.8733 21.836C18.6892 22.0386 18.4361 22.0555 18.1886 21.8754C17.496 21.3716 16.8044 20.8657 16.1139 20.3576C14.7961 19.3929 13.4788 18.4282 12.1621 17.4635C12.0368 17.3719 11.958 17.3751 11.8351 17.4635C9.8664 18.9106 7.89532 20.3539 5.92184 21.7934C5.82538 21.8754 5.71484 21.9391 5.5956 21.9815C5.22919 22.078 4.92625 21.7404 5.03795 21.3625C5.18178 20.8754 5.34329 20.393 5.49998 19.9098C6.12192 17.9837 6.74573 16.0586 7.37142 14.1345C7.42285 13.9778 7.38428 13.9038 7.25732 13.813C5.2624 12.3611 3.26909 10.9039 1.27739 9.44127C1.03633 9.262 0.951156 9.06504 1.02669 8.84316C1.10222 8.62128 1.25891 8.52159 1.52488 8.52159C2.22798 8.52159 2.93107 8.52159 3.63336 8.52159L5.24044 8.51918Z" />
    </svg>
  ),

  Heart: () => (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.4375 23L11.625 21.3455C5.1875 15.4921 0.9375 11.6316 0.9375 6.89373C0.9375 3.03324 3.9625 0 7.8125 0C9.9875 0 12.075 1.01526 13.4375 2.61962C14.8 1.01526 16.8875 0 19.0625 0C22.9125 0 25.9375 3.03324 25.9375 6.89373C25.9375 11.6316 21.6875 15.4921 15.25 21.358L13.4375 23Z" />
    </svg>
  ),

  SentimentVeryDissatisfied: () => (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        stroke="inherit"
        fill="currentColor"
        strokeWidth="3"
      />
      <path d="M44.3753 38.0329C45.9825 39.3799 47.2791 41.0587 48.1761 42.9542C49.0731 44.8496 49.5493 46.9168 49.5718 49.0137V49.0137C49.5682 49.4105 49.4095 49.7903 49.1298 50.0718C48.85 50.3533 48.4712 50.5143 48.0744 50.5204V50.5204C47.8739 50.521 47.6753 50.482 47.4899 50.4055C47.3046 50.3291 47.1362 50.2167 46.9944 50.075C46.8527 49.9332 46.7403 49.7648 46.6639 49.5795C46.5875 49.3941 46.5484 49.1955 46.549 48.995C46.549 45.6411 44.991 42.5904 42.3788 40.314C39.4841 37.882 35.8128 36.5695 32.0324 36.6148C28.2534 36.5694 24.5836 37.882 21.6907 40.314V40.314C19.0738 42.5904 17.5205 45.6411 17.4925 48.995C17.4937 49.3967 17.336 49.7826 17.0537 50.0684C16.7714 50.3542 16.3875 50.5167 15.9858 50.5204V50.5204C15.7853 50.521 15.5867 50.482 15.4013 50.4055C15.216 50.3291 15.0476 50.2168 14.9058 50.075C14.764 49.9332 14.6517 49.7648 14.5753 49.5795C14.4988 49.3941 14.4598 49.1955 14.4604 48.995V48.995C14.4854 46.8958 14.9655 44.827 15.8675 42.9312C16.7694 41.0355 18.0718 39.3579 19.6849 38.0143V38.0143C23.1351 35.1029 27.5184 33.5314 32.0324 33.5874V33.5874C36.5469 33.5398 40.9279 35.1177 44.3753 38.0329V38.0329Z" />
      <path d="M23.8643 21.635C23.8643 22.3445 23.6539 23.038 23.2597 23.628C22.8656 24.2179 22.3053 24.6776 21.6499 24.9491C20.9944 25.2207 20.2731 25.2917 19.5773 25.1533C18.8815 25.0149 18.2423 24.6732 17.7406 24.1715C17.2389 23.6699 16.8973 23.0307 16.7589 22.3349C16.6205 21.639 16.6915 20.9177 16.963 20.2623C17.2345 19.6068 17.6943 19.0466 18.2842 18.6524C18.8741 18.2582 19.5676 18.0479 20.2771 18.0479C20.7484 18.0472 21.2151 18.1396 21.6506 18.3197C22.0861 18.4997 22.4818 18.7639 22.815 19.0971C23.1482 19.4304 23.4124 19.8261 23.5925 20.2616C23.7725 20.697 23.8649 21.1638 23.8643 21.635Z" />
      <path d="M47.3047 21.635C47.3047 22.3447 47.0942 23.0384 46.6998 23.6284C46.3055 24.2184 45.745 24.6782 45.0892 24.9496C44.4335 25.221 43.712 25.2917 43.016 25.1529C42.32 25.0141 41.6809 24.672 41.1794 24.1699C40.6779 23.6677 40.3366 23.0281 40.1987 22.332C40.0608 21.6358 40.1326 20.9144 40.4048 20.259C40.677 19.6037 41.1375 19.0437 41.728 18.6501C42.3185 18.2565 43.0125 18.0469 43.7222 18.0479C44.1931 18.0479 44.6593 18.1407 45.0943 18.321C45.5292 18.5013 45.9243 18.7656 46.2571 19.0988C46.5898 19.432 46.8536 19.8274 47.0334 20.2626C47.2131 20.6978 47.3053 21.1642 47.3047 21.635V21.635Z" />
    </svg>
  ),

  SentimentDissatisfied: () => (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        fill="currentColor"
        stroke="inherit"
        strokeWidth="3"
      />
      <path d="M45.6162 48.61C43.8304 46.8182 41.7082 45.3972 39.3714 44.4284C37.0346 43.4597 34.5295 42.9625 31.9999 42.9652V42.9652C29.4702 42.9619 26.9649 43.459 24.6281 44.4277C22.2912 45.3965 20.169 46.8178 18.3836 48.61V48.61C18.1545 48.8384 17.8441 48.9667 17.5206 48.9667C17.197 48.9667 16.8867 48.8384 16.6576 48.61V48.61C16.4294 48.3816 16.3013 48.0721 16.3013 47.7493C16.3013 47.4265 16.4294 47.1169 16.6576 46.8885V46.8885C18.6695 44.8693 21.0607 43.2676 23.6937 42.1756C26.3267 41.0836 29.1495 40.5228 31.9999 40.5254C34.8496 40.5229 37.6717 41.0837 40.3039 42.1758C42.936 43.2678 45.3264 44.8694 47.3375 46.8885V46.8885C47.5657 47.1169 47.6938 47.4265 47.6938 47.7493C47.6938 48.0721 47.5657 48.3816 47.3375 48.61V48.61C47.2253 48.7236 47.0916 48.8138 46.9443 48.8755C46.797 48.9372 46.6389 48.969 46.4792 48.9692V48.9692C46.3188 48.9691 46.1599 48.9373 46.0119 48.8757C45.8638 48.814 45.7293 48.7237 45.6162 48.61V48.61Z" />
      <path d="M23.9766 23.6099C23.9776 24.3639 23.7548 25.1013 23.3366 25.7287C22.9184 26.356 22.3235 26.8453 21.6272 27.1345C20.9309 27.4237 20.1645 27.4998 19.4249 27.3533C18.6853 27.2067 18.0058 26.8441 17.4724 26.3113C16.9389 25.7784 16.5755 25.0993 16.4281 24.3599C16.2806 23.6204 16.3558 22.8538 16.6441 22.1571C16.9325 21.4604 17.4209 20.8649 18.0478 20.4459C18.6746 20.0269 19.4116 19.8032 20.1656 19.8032C21.1755 19.8032 22.1442 20.2041 22.8588 20.9179C23.5733 21.6317 23.9754 22.5999 23.9766 23.6099V23.6099Z" />
      <path d="M47.4166 23.6099C47.4175 24.3641 47.1947 25.1017 46.7763 25.7291C46.3578 26.3566 45.7627 26.8459 45.0661 27.1349C44.3695 27.424 43.6029 27.4998 42.8632 27.3529C42.1234 27.206 41.4439 26.8429 40.9107 26.3096C40.3774 25.7763 40.0143 25.0968 39.8674 24.357C39.7205 23.6172 39.7964 22.8505 40.0854 22.1539C40.3745 21.4573 40.8637 20.8621 41.4911 20.4436C42.1185 20.0252 42.856 19.8023 43.6102 19.8032C44.6197 19.8032 45.5879 20.2043 46.3017 20.9182C47.0156 21.6321 47.4166 22.6003 47.4166 23.6099V23.6099Z" />
    </svg>
  ),

  SentimentSatisfied: () => (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        fill="currentColor"
        stroke="inherit"
        strokeWidth="3"
      />
      <path d="M24.0885 21.6319C24.0894 22.3859 23.8667 23.1232 23.4485 23.7506C23.0302 24.378 22.4354 24.8673 21.7391 25.1564C21.0428 25.4456 20.2763 25.5218 19.5367 25.3752C18.7972 25.2287 18.1177 24.8661 17.5842 24.3332C17.0507 23.8004 16.6873 23.1213 16.5399 22.3818C16.3925 21.6424 16.4677 20.8758 16.756 20.1791C17.0443 19.4824 17.5328 18.8869 18.1596 18.4678C18.7864 18.0488 19.5234 17.8252 20.2774 17.8252C21.2873 17.8252 22.256 18.2261 22.9706 18.9399C23.6852 19.6536 24.0872 20.6219 24.0885 21.6319V21.6319Z" />
      <path d="M47.5289 21.6319C47.5298 22.3861 47.307 23.1236 46.8886 23.7511C46.4701 24.3786 45.875 24.8678 45.1784 25.1569C44.4818 25.4459 43.7152 25.5218 42.9755 25.3749C42.2357 25.228 41.5563 24.8649 41.023 24.3316C40.4897 23.7983 40.1266 23.1187 39.9797 22.379C39.8328 21.6392 39.9087 20.8725 40.1977 20.1759C40.4868 19.4792 40.976 18.884 41.6034 18.4656C42.2308 18.0471 42.9683 17.8243 43.7225 17.8252C44.732 17.8252 45.7002 18.2263 46.414 18.9402C47.1279 19.654 47.5289 20.6223 47.5289 21.6319V21.6319Z" />
      <path d="M18.57 45.4936C18.3699 45.4936 18.1718 45.4542 17.987 45.3774C17.8022 45.3007 17.6343 45.1883 17.4931 45.0466C17.3518 44.9049 17.2399 44.7366 17.1637 44.5516C17.0876 44.3665 17.0487 44.1683 17.0493 43.9682C17.0506 43.5656 17.2113 43.18 17.4964 42.8958C17.7814 42.6116 18.1675 42.452 18.57 42.452H45.4248C45.8286 42.452 46.2159 42.6121 46.5018 42.8972C46.7877 43.1823 46.949 43.5691 46.9502 43.9728V43.9728C46.9502 44.3774 46.7895 44.7654 46.5034 45.0515C46.2174 45.3376 45.8294 45.4983 45.4248 45.4983H18.57V45.4936Z" />
    </svg>
  ),

  SentimentSatisfiedAlt: () => (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        fill="currentColor"
        stroke="inherit"
        strokeWidth="3"
      />
      <path d="M18.3794 40.8847C20.1652 42.6764 22.2874 44.0975 24.6242 45.0662C26.961 46.0349 29.4661 46.5322 31.9957 46.5294V46.5294C34.5254 46.5327 37.0307 46.0357 39.3675 45.0669C41.7044 44.0982 43.8266 42.6768 45.612 40.8847V40.8847C45.8411 40.6562 46.1515 40.5279 46.475 40.5279C46.7986 40.5279 47.1089 40.6562 47.338 40.8847V40.8847C47.5662 41.113 47.6943 41.4226 47.6943 41.7454C47.6943 42.0682 47.5662 42.3778 47.338 42.6061V42.6061C45.3261 44.6254 42.9349 46.227 40.3019 47.3191C37.6689 48.4111 34.8461 48.9719 31.9957 48.9692C29.146 48.9718 26.3239 48.4109 23.6918 47.3189C21.0596 46.2269 18.6692 44.6252 16.6581 42.6061V42.6061C16.4299 42.3778 16.3018 42.0682 16.3018 41.7454C16.3018 41.4226 16.4299 41.113 16.6581 40.8847V40.8847C16.7703 40.771 16.904 40.6808 17.0513 40.6191C17.1986 40.5575 17.3567 40.5256 17.5164 40.5255V40.5255C17.6768 40.5255 17.8357 40.5573 17.9838 40.6189C18.1319 40.6806 18.2663 40.7709 18.3794 40.8847V40.8847Z" />
      <path d="M23.9766 23.6099C23.9776 24.3639 23.7548 25.1013 23.3366 25.7287C22.9184 26.356 22.3235 26.8453 21.6272 27.1345C20.9309 27.4237 20.1645 27.4998 19.4249 27.3533C18.6853 27.2067 18.0058 26.8441 17.4724 26.3113C16.9389 25.7784 16.5755 25.0993 16.4281 24.3599C16.2806 23.6204 16.3558 22.8538 16.6441 22.1571C16.9325 21.4604 17.4209 20.8649 18.0478 20.4459C18.6746 20.0269 19.4116 19.8032 20.1656 19.8032C21.1755 19.8032 22.1442 20.2041 22.8588 20.9179C23.5733 21.6317 23.9754 22.5999 23.9766 23.6099V23.6099Z" />
      <path d="M47.4171 23.6099C47.418 24.3641 47.1952 25.1017 46.7767 25.7291C46.3583 26.3566 45.7632 26.8459 45.0666 27.1349C44.37 27.424 43.6034 27.4998 42.8636 27.3529C42.1239 27.206 41.4444 26.8429 40.9112 26.3096C40.3779 25.7763 40.0148 25.0968 39.8679 24.357C39.721 23.6172 39.7969 22.8505 40.0859 22.1539C40.3749 21.4573 40.8641 20.8621 41.4916 20.4436C42.119 20.0252 42.8565 19.8023 43.6107 19.8032C44.6202 19.8032 45.5884 20.2043 46.3022 20.9182C47.016 21.6321 47.4171 22.6003 47.4171 23.6099V23.6099Z" />
    </svg>
  ),

  SentimentVerySatisfied: () => (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        fill="currentColor"
        stroke="inherit"
        strokeWidth="3"
      />
      <path d="M19.657 46.0735C18.0497 44.7266 16.7531 43.0477 15.8561 41.1523C14.9591 39.2568 14.483 37.1897 14.4604 35.0928V35.0928C14.4641 34.6959 14.6227 34.3162 14.9025 34.0346C15.1822 33.7531 15.561 33.5921 15.9578 33.5861V33.5861C16.1583 33.5855 16.3569 33.6245 16.5423 33.7009C16.7276 33.7774 16.896 33.8897 17.0378 34.0315C17.1796 34.1732 17.2919 34.3416 17.3683 34.527C17.4448 34.7123 17.4838 34.9109 17.4832 35.1114C17.4832 38.4654 19.0412 41.5161 21.6535 43.7925C24.5482 46.2244 28.2194 47.537 31.9998 47.4916C35.7788 47.5371 39.4486 46.2244 42.3415 43.7925V43.7925C44.9584 41.5161 46.5118 38.4654 46.5397 35.1114C46.5385 34.7097 46.6962 34.3238 46.9785 34.038C47.2608 33.7522 47.6448 33.5898 48.0465 33.5861V33.5861C48.2469 33.5855 48.4456 33.6245 48.6309 33.7009C48.8163 33.7774 48.9847 33.8897 49.1264 34.0315C49.2682 34.1732 49.3805 34.3416 49.457 34.527C49.5334 34.7123 49.5724 34.9109 49.5718 35.1114V35.1114C49.5468 37.2107 49.0667 39.2795 48.1648 41.1752C47.2628 43.071 45.9604 44.7485 44.3473 46.0922V46.0922C40.8971 49.0035 36.5139 50.575 31.9998 50.519V50.519C27.4853 50.5666 23.1043 48.9887 19.657 46.0735V46.0735Z" />
      <path d="M23.8643 21.635C23.8643 22.3445 23.6539 23.038 23.2597 23.628C22.8656 24.2179 22.3053 24.6776 21.6499 24.9491C20.9944 25.2207 20.2731 25.2917 19.5773 25.1533C18.8815 25.0149 18.2423 24.6732 17.7406 24.1715C17.2389 23.6699 16.8973 23.0307 16.7589 22.3349C16.6205 21.639 16.6915 20.9177 16.963 20.2623C17.2345 19.6068 17.6943 19.0466 18.2842 18.6524C18.8741 18.2582 19.5676 18.0479 20.2771 18.0479C20.7484 18.0472 21.2151 18.1396 21.6506 18.3197C22.0861 18.4997 22.4818 18.7639 22.815 19.0971C23.1482 19.4304 23.4124 19.8261 23.5925 20.2616C23.7725 20.697 23.8649 21.1638 23.8643 21.635Z" />
      <path d="M47.3047 21.635C47.3047 22.3447 47.0942 23.0384 46.6998 23.6284C46.3055 24.2184 45.745 24.6782 45.0892 24.9496C44.4335 25.221 43.712 25.2917 43.016 25.1529C42.32 25.0141 41.6809 24.672 41.1794 24.1699C40.6779 23.6677 40.3366 23.0281 40.1987 22.332C40.0608 21.6358 40.1326 20.9144 40.4048 20.259C40.677 19.6037 41.1375 19.0437 41.728 18.6501C42.3185 18.2565 43.0125 18.0469 43.7222 18.0479C44.1931 18.0479 44.6593 18.1407 45.0943 18.321C45.5292 18.5013 45.9243 18.7656 46.2571 19.0988C46.5898 19.432 46.8536 19.8274 47.0334 20.2626C47.2131 20.6978 47.3053 21.1642 47.3047 21.635V21.635Z" />
    </svg>
  ),
};
