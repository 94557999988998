import { IconButton } from '@whispir/ui-lib-v2';
import { SINGULAR_COMPONENTS } from '../utils/constants';
import * as S from './styles';

type Props = {
  ariaSubject: string;
  onDuplicateItem: () => void;
  onDeleteItem: () => void;
  componentType?: string;
  isSingularOverride?: boolean;
};

const DraggableItemControls = (props: Props) => {
  const {
    onDuplicateItem,
    onDeleteItem,
    ariaSubject,
    componentType,
    isSingularOverride,
  } = props;
  // TODO: is it okay to just use the component.type rather than the label with contentCreationMap?
  // const ariaSubject = `${contentCreationMap[content.type].label} ${content.id}`;

  const isSingular =
    isSingularOverride ||
    (componentType && SINGULAR_COMPONENTS.includes(componentType));

  return (
    <S.DraggableItemControls canDuplicate={!isSingular}>
      <IconButton
        className="draggable-item-icon-move"
        size="small"
        icon="Move"
        color="primary"
        ariaLabel={`Move ${ariaSubject}`}
      />
      <div
        onClick={(e) => {
          if (!isSingular) {
            e.stopPropagation();
            onDuplicateItem();
          }
        }}
      >
        <IconButton
          className="draggable-item-icon-duplicate"
          size="small"
          icon="Copy"
          color="primary"
          ariaLabel={`Duplicate ${ariaSubject}`}
        />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          onDeleteItem();
        }}
      >
        <IconButton
          className="draggable-item-icon-delete"
          size="small"
          icon="Delete"
          color="primary"
          ariaLabel={`Delete ${ariaSubject}`}
        />
      </div>
    </S.DraggableItemControls>
  );
};

export default DraggableItemControls;
