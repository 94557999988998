import { useCallback } from 'react';
import { AxiosInstance } from 'axios';
import { useLoading } from './useLoading';

export const useSaveSubmission = ({ api }: { api: AxiosInstance }) => {
  const saveSubmission = useCallback(
    async (body) => {
      const { data } = await api.post('/submissions', body);

      return data;
    },
    [api],
  );

  return useLoading(saveSubmission);
};
