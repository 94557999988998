import { addHours } from 'date-fns';
import { Button } from '@material-ui/core';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { getContentItemWidthToUse } from '../../../utils/contentItemUtils';
import { getIcsURI } from './DisplayCalendar.utils';

export const DisplayCalendarContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-calendar'
> = {
  type: 'display-calendar',
  label: 'Calendar',
  icon: 'AddToCalendar',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        RenderVariableHtml,
        RenderVariableText,
      }) => {
        const { data } = contentItem;
        const {
          text,
          inviteStart,
          inviteEnd,
          inviteAllDay,
          inviteTitle,
          inviteLocation,
          inviteDescription,
          inviteFileName,
          backgroundColor,
          color,
          fontSize,
          buttonColor,
          borderColor,
          borderRadius,
          iconAlign,
          iconSrc,
          hideIcon,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          width,
        } = data;

        const href = getIcsURI({
          inviteStart,
          inviteEnd,
          inviteAllDay,
          inviteTitle: RenderVariableText(inviteTitle),
          inviteDescription: RenderVariableText(inviteDescription),
          inviteLocation: RenderVariableText(inviteLocation),
          inviteFileName: RenderVariableText(inviteFileName),
        });

        const Label = () => (
          <div style={{ padding: '0 5px' }} key={text}>
            <RenderVariableHtml htmlString={text} />
          </div>
        );

        const Icon = () =>
          hideIcon ? null : (
            <div
              style={{
                height: '20px',
                padding: '0 5px',
                display: 'flex',
              }}
            >
              <img
                style={{ height: '20px' }}
                src={iconSrc}
                alt="Calendar Icon"
              />
            </div>
          );

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
              display: 'flex',
              flexDirection: 'column',
              alignItems:
                textAlign === 'center'
                  ? 'center'
                  : textAlign === 'left'
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <Button
              component="a"
              style={{
                boxSizing: 'border-box',
                backgroundColor: buttonColor,
                color,
                borderRadius: borderRadius,
                textTransform: 'none',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 18px',
                fontSize,
                border: `1px solid ${borderColor}`,
                width,
                maxWidth: '100%',
                wordBreak: 'break-word',
              }}
              href={href}
            >
              {iconAlign === 'right' ? (
                <>
                  <Label />
                  <Icon />
                </>
              ) : (
                <>
                  <Icon />
                  <Label />
                </>
              )}
            </Button>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem,
          RenderVariableHtml,
          RenderVariableText,
          columnMaxWidthPx,
        } = props;
        const { data } = contentItem;
        const {
          text,
          inviteStart,
          inviteEnd,
          inviteAllDay,
          inviteTitle,
          inviteLocation,
          inviteDescription,
          inviteFileName,
          fontSize,
          backgroundColor,
          borderColor,
          color,
          buttonColor,
          borderRadius,
          iconAlign,
          iconSrc,
          hideIcon,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          width,
        } = data;

        const href = getIcsURI({
          inviteStart,
          inviteEnd,
          inviteAllDay,
          inviteTitle: RenderVariableText(inviteTitle),
          inviteDescription: RenderVariableText(inviteDescription),
          inviteLocation: RenderVariableText(inviteLocation),
          inviteFileName: RenderVariableText(inviteFileName),
        });

        const widthToUse = getContentItemWidthToUse({
          width,
          columnMaxWidthPx,
          paddingLeft,
          paddingRight,
        });

        const icon = hideIcon
          ? ''
          : `<td style="padding:0 5px;" >
              <img
                src="${iconSrc}"
                width="20px"
                height="20px"
                alt="Icon"
                align="top"
              />
            </td>`;

        const label = `
          <td style="padding:0 5px; text-align: center;" >
            ${RenderVariableHtml(text)}
          </td>`;

        return `<mj-button
            container-background-color="${backgroundColor}"
            padding-top="${paddingTop}px"
            padding-bottom="${paddingBottom}px"
            padding-left="${paddingLeft}px"
            padding-right="${paddingRight}px"
            align="${textAlign}"
            background-color="${buttonColor}"
            border-radius="${borderRadius}px"
            border="1px solid ${borderColor}"
            color="${color}"
            font-size="${fontSize}px"
            href="${href}"
            width="${widthToUse}px"
          >
            <table align="center" background="" border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;">
              <tr>
                ${iconAlign === 'right' ? `${label}${icon}` : `${icon}${label}`}
              </tr>
            </table>
          </mj-button>
        `;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        borderColor: '#5700D4',
        borderRadius: 5,
        textAlign: 'center',
        buttonColor: '#6200EE',
        text: 'Add to Calendar',
        color: '#FFFFFF',
        fontSize: 14,
        hideIcon: false,
        iconAlign: 'left',
        iconSrc:
          'https://d1ccvuha0qk3lj.cloudfront.net/uploads/hostedFiles/calendar_custom_icon_white-01.png',
        inviteAllDay: false,
        inviteDescription: 'Type your description here...',
        inviteEnd: addHours(new Date(), 1).toISOString(),
        inviteFileName: 'Calendar-Event',
        inviteLocation: 'Current Location',
        inviteStart: new Date().toISOString(),
        inviteTitle: 'Event Title',
        width: 200,
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;
        const { inviteAllDay, hideIcon, inviteStart } = props.contentItem.data;

        return generateControls(props, [
          {
            label: 'Content',
            drawLine: false,
            controlsConfig: {
              text: {
                controlType: 'variable-text-input',
                contentDataKey: 'text',
                data: {
                  label: 'Button Text',
                  variables,
                },
              },
            },
          },
          {
            subLabel: 'Event Date & Time',
            controlsConfig: {
              inviteStart: {
                controlType: 'date-picker',
                contentDataKey: 'inviteStart',
                data: {
                  label: 'Start',
                  includeTime: !inviteAllDay,
                },
              },
              inviteEnd: {
                controlType: 'date-picker',
                contentDataKey: 'inviteEnd',
                data: {
                  label: 'End',
                  includeTime: !inviteAllDay,
                  minDate: inviteStart,
                  minDateMessage: "'End' date must be after 'Start' date",
                },
              },
              inviteAllDay: {
                controlType: 'boolean',
                contentDataKey: 'inviteAllDay',
                data: {
                  label: 'All Day Event',
                },
              },
              inviteTitle: {
                controlType: 'variable-text-input',
                contentDataKey: 'inviteTitle',
                data: {
                  label: 'Event Title',
                  variables,
                },
              },
              inviteLocation: {
                controlType: 'variable-text-input',
                contentDataKey: 'inviteLocation',
                data: {
                  label: 'Event Location',
                  variables,
                },
              },
              inviteDescription: {
                controlType: 'variable-text-input',
                contentDataKey: 'inviteDescription',
                data: {
                  label: 'Event Description',
                  variables,
                },
              },
              inviteFileName: {
                controlType: 'variable-text-input',
                contentDataKey: 'inviteFileName',
                data: {
                  label: 'V-Card File Name (.ics)',
                  variables,
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              color: {
                controlType: 'color-picker',
                contentDataKey: 'color',
                data: {
                  label: 'Text Color',
                },
              },
              buttonColor: {
                controlType: 'color-picker',
                contentDataKey: 'buttonColor',
                data: {
                  label: 'Button Color',
                },
              },
              borderColor: {
                controlType: 'color-picker',
                contentDataKey: 'borderColor',
                data: {
                  label: 'Border Color',
                  labelSize: 'large',
                },
              },
              borderRadius: {
                controlType: 'slider',
                contentDataKey: 'borderRadius',
                data: {
                  label: 'Border Radius',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              width: {
                controlType: 'slider',
                contentDataKey: 'width',
                data: {
                  label: 'Button Width',
                  minValue: 1,
                  maxValue: 600,
                  step: 1,
                  units: 'px',
                },
              },
              fontSize: {
                controlType: 'slider',
                contentDataKey: 'fontSize',
                data: {
                  label: 'Font Size',
                  minValue: 9,
                  maxValue: 72,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
          {
            label: 'Options',
            controlsConfig: {
              hideIcon: {
                controlType: 'boolean',
                contentDataKey: 'hideIcon',
                data: {
                  label: 'Hide Icon',
                },
              },
              ...(!hideIcon && {
                iconSrc: {
                  controlType: 'text',
                  contentDataKey: 'iconSrc',
                  data: {
                    label: 'Icon Image Source',
                  },
                },
              }),
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              textAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'textAlign',
                data: {
                  label: 'Button Alignment',
                  variant: 'items',
                },
              },
              iconAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'iconAlign',
                data: {
                  label: 'Icon Alignment',
                  variant: 'leftright',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding ',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
