import { Variable } from '@whispir/variables';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import { ApiLinkedContentBuilderProps } from '../mainEntryPoints/Builder';
import {
  AbstractContentTypes,
  AbstractContentData,
  AbstractVersionsForItemTypes,
  AbstractRecords,
  AbstractAllContentDataAsArray,
} from './ContentDefinitions';
import {
  AbstractContentCreationMap,
  AbstractContentCreationItem,
} from './ContentCreation';

/**
 *  These are a series of typed convenience functions, for retrieving, migrating content data etc.
 *
 */

/**
 * I'm Ok with the ts-ignores and anys in this file - so long as there are tests that are showing type errors when expected.
 * @param contentCreationMap
 * @param contentType
 */

/**
 * For a given content key, tell us what its most recent version is.
 * @param contentCreationMap
 * @param contentType
 */
export const getMostRecentVersionForContentType = <
  C extends AbstractContentCreationMap<any>,
  T extends GetTFromC<C>,
  K extends keyof C & AbstractContentTypes<T>
>(
  contentCreationMap: C,
  contentType: K,
): AbstractVersionsForItemTypes<T, K> => {
  const versionMap = contentCreationMap[contentType].versions;

  const entries = Object.entries(versionMap);

  if (entries.length === 0) {
    throw new Error(
      `There were 0 entries in the content creation map for key: ${contentType}`,
    );
  } else {
    return entries[entries.length - 1][0];
  }
};

/**
 * For a given Content Data, tell us if it is out of date.
 * @param contentCreationMap
 * @param contentItem
 */
export const isOutOfDate = <
  C extends AbstractContentCreationMap<any>,
  T extends GetTFromC<C>
>(
  contentCreationMap: C,
  contentItem: AbstractContentData<T, any, any>,
): boolean => {
  const mostRecentVersion = getMostRecentVersionForContentType(
    contentCreationMap,
    contentItem.type,
  );
  return !(mostRecentVersion === contentItem.version);
};

/**
 * For a given content key and version, give us all of the content creation items for the versions after it.
 * @param contentCreationMap
 * @param contentKey
 * @param version
 */
export const getVersionsAfterCurrent = <
  C extends AbstractContentCreationMap<any>,
  T extends GetTFromC<C>,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
>(
  contentCreationMap: C,
  contentKey: K,
  version: V,
): Array<
  AbstractContentCreationItem<T, K, AbstractVersionsForItemTypes<T, K>>
> => {
  const entries = Object.entries(contentCreationMap[contentKey].versions);
  const validVersions = entries.filter(([cmVersion, contentCreationItem]) => {
    return parseInt(version as string) < parseInt(cmVersion);
  });

  //@ts-ignore - pretty sure this one is ok.
  return validVersions.map((v) => v[1]);
};

/**
 * For a given Content Data, migrate it to the most recent version
 * @param contentItem
 */
export const migrateContentItem = <
  C extends AbstractContentCreationMap<any>,
  T extends GetTFromC<C>,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
>(
  contentCreationMap: C,
  contentItem: AbstractContentData<T, K, V>,
): AbstractContentData<T, K, V> => {
  const versionsToMigrateTo = getVersionsAfterCurrent(
    contentCreationMap,
    contentItem.type,
    contentItem.version,
  );

  const newData = versionsToMigrateTo.reduce((acc, cur) => {
    const newData = cur.defaultData({ id: contentItem.id });
    return { ...acc, ...newData };
  }, contentItem.data as AbstractContentData<T, K, V>['data']);

  const newItem = {
    ...contentItem,
    data: newData,
    version: getMostRecentVersionForContentType(
      contentCreationMap,
      contentItem.type,
    ),
  };

  return (newItem as unknown) as AbstractContentData<T, K, V>;
};

type GetTFromC<C extends AbstractContentCreationMap<AbstractRecords>> = {
  [K in keyof C]: {
    [V in keyof C[K]['versions']]: ReturnType<
      C[K]['versions'][V]['defaultData']
    >;
  };
};

/**
 * For a given content type and version, get the content creation item.
 * @param contentCreationMap
 * @param itemKey
 * @param version
 */
export function getContentCreationItemForContentTypeAndVersion<
  T extends AbstractRecords,
  K extends AbstractContentTypes<any>,
  V extends AbstractVersionsForItemTypes<T, any>
>(
  contentCreationMap: AbstractContentCreationMap<T>,
  itemKey: K,
  version: V,
): AbstractContentCreationItem<T, K, V> {
  return contentCreationMap[itemKey].versions[version];
}

export function getOutputVariablesForContentData<T extends AbstractRecords>(
  contentData: AbstractAllContentDataAsArray<T>,
  contentCreationMap: AbstractContentCreationMap<T>,
): Array<Variable> {
  const formComponentVariables = contentData.reduce((acc, item) => {
    const { version, type } = item;

    const cci = getContentCreationItemForContentTypeAndVersion(
      contentCreationMap,
      type,
      version,
    );

    if (cci.outputVariablesFunction) {
      const outputVariable = cci.outputVariablesFunction(item);

      return [...acc, ...outputVariable];
    } else {
      return acc;
    }
  }, [] as Array<Variable>);

  return formComponentVariables;
}

export type CreateNewContentInput<
  T extends AbstractRecords,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
> = {
  contentCreationMap: AbstractContentCreationMap<T>;
  contentKey: K;
  contentVersion?: V;
  contentId?: string;
  contentData?: AbstractContentData<T, K, V>;
  contentMode?: ApiLinkedContentBuilderProps['contentMode'];
};

export const createNewContent = <
  T extends AbstractRecords,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
>(
  data: CreateNewContentInput<T, K, V>,
): AbstractContentData<T, K, V> => {
  const {
    contentCreationMap,
    contentKey,
    contentVersion,
    contentId,
    contentData,
    contentMode,
  } = data;
  const newContentCreationItem = contentCreationMap[contentKey];

  const allVersions = Object.entries(newContentCreationItem.versions);
  const latestVersion = (allVersions.length - 1).toString();

  // will default to using latest version when 'contentVersion' isn't passed
  const computedContentVersion = contentVersion
    ? contentVersion
    : latestVersion;

  const [contentVersionId, contentVersionData] = allVersions[
    computedContentVersion
  ] as [contentVersion: string, data: AbstractContentCreationItem<T, any, any>];
  const newContentId = contentId ? contentId : uuidv4();

  const newContent = {
    type: newContentCreationItem.type,
    version: contentVersionId,
    id: newContentId,
    data: contentData
      ? contentData
      : contentVersionData.defaultData({ id: newContentId, contentMode }),
  };

  return newContent as AbstractContentData<T, K, V>;
};

export const copyContent = <
  T extends AbstractRecords,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
>(
  contentItem: AbstractContentData<T, K, V>,
): AbstractContentData<T, K, V> => {
  const newContent = cloneDeep({ ...contentItem, id: uuidv4() });

  return newContent;
};

export function getAllContentCreationItemsForType<T extends AbstractRecords>(
  contentMap: AbstractContentCreationMap<T>,
  type: AbstractContentTypes<T>,
) {
  return contentMap[type];
}
