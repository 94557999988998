import styled from 'styled-components';
import { Fonts } from '@whispir/ui-lib-v2';
import { Typography } from '@material-ui/core';

export const StyledLabel = styled.div`
  ${Fonts.bodySmall};
  margin-bottom: 8px;
`;

export const StyledTypography = styled((props) => <Typography {...props} />)`
  p,
  h1,
  h2,
  h3 {
    margin: 0;
  }
` as typeof Typography; // Note there is an issue on material ui that makes this neccessary
//https://github.com/mui-org/material-ui/issues/13921#issuecomment-484133463
