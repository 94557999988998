import React, { createContext } from 'react';

export type LoadingProviderProps = {
  isLoading: boolean;
};

export const LoadingContext = createContext<LoadingProviderProps>({
  isLoading: false,
});

export const LoadingProvider = (
  props: React.PropsWithChildren<LoadingProviderProps>,
) => {
  const { isLoading, children } = props;
  return (
    <LoadingContext.Provider value={{ isLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
