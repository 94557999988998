//https://stackoverflow.com/questions/10026626/check-if-html-snippet-is-valid-with-javascript

export const isValidHtml = (html: string) => {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML === html;
};

export const getKeyByValue = (object: Record<string, number>, value: number) =>
  Object.keys(object).find((key) => object[key] === value);
