import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useState } from 'react';
import { Icon } from '@whispir/ui-lib-v2';
import { v4 as uuid } from 'uuid';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { shadeHexColor } from '../../../utils/colorUtils';
import { useOnMount } from '../../../utils/useOnmount';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

export const FormYesNoButtonsContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-yes-no-buttons'
> = {
  type: 'form-yes-no-buttons',
  label: 'Yes/No',
  icon: 'YesNo',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableHtml,
      }) => {
        const { data } = contentItem;
        const {
          label,
          options,
          requiredField,
          helperText,
          backgroundColor,
          color,
          buttonColor,
          iconColor,
          borderColor,
          borderRadius,
          iconAlign,
          hideIcon,
          yesIconSrc,
          noIconSrc,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          fontSize,
          width,
        } = data;

        const initialValue = options.find(({ value }) => value === true);

        useOnMount(() => {
          formUpdateFunction(
            contentItem.id,
            initialValue ? initialValue.label : '',
          );
        });

        const setColor = (color) => {
          return shadeHexColor(color, -20);
        };

        const YES_INDEX = 0;
        const NO_INDEX = 1;

        const iconMap = {
          [YES_INDEX]: {
            defaultIcon: 'ThumbsUp',
            customSrc: yesIconSrc,
            altText: 'Yes Button Icon',
          },
          [NO_INDEX]: {
            defaultIcon: 'ThumbsDown',
            customSrc: noIconSrc,
            altText: 'No Button Icon',
          },
        };

        const renderButtonLayout = (id, text, buttonIndex) => {
          const { defaultIcon, customSrc, altText } = iconMap[buttonIndex];

          const labelLayout = (
            <span key={`${id}-label`} style={{ padding: '0 5px' }}>
              {text}
            </span>
          );

          const isSvg = !!(customSrc as string).match(/.svg$/);

          const svgIconLayout = (
            <span
              key={`${id}-default-icon`}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 5px',
              }}
            >
              <Icon style={{ fill: iconColor }} icon={defaultIcon} />
            </span>
          );

          const nonSvgIconLayout = [
            customSrc !== '' && (
              <span
                key={`${id}-custom-icon`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 5px',
                }}
              >
                <img
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  src={customSrc}
                  alt={altText}
                />
              </span>
            ),
          ];
          if (hideIcon === false) {
            return [isSvg ? svgIconLayout : nonSvgIconLayout, labelLayout];
          }
          return [labelLayout];
        };

        const [selectedButtonId, setSelectedButtonId] = useState();

        const onClickHandler = (selectionId) => {
          setSelectedButtonId(selectionId);
        };

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
            }}
            isFieldRequired={requiredField}
            label={label}
            htmlFor={contentItem.id}
          >
            <FormControl
              required={requiredField}
              id={contentItem.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent:
                  textAlign === 'left'
                    ? 'flex-start'
                    : textAlign === 'right'
                    ? 'flex-end'
                    : 'center',
              }}
            >
              <RadioGroup
                row
                aria-label={label}
                key={`${contentItem.id}-${initialValue?.label}-${buttonColor}`}
                defaultValue={initialValue?.label}
                onChange={(e) => {
                  formUpdateFunction(contentItem.id, e.target.value);
                }}
              >
                {options.map((option, index) => {
                  return (
                    <FormControlLabel
                      // Remove default negative margin from FormControlLabel
                      style={{
                        marginLeft: 0,
                        // Remove right margin for right(No) button
                        ...(index === 1 ? { marginRight: 0 } : {}),
                      }}
                      key={option.id}
                      value={option.label}
                      control={
                        <Radio
                          aria-label={`${option.label}-radio`}
                          // https://stackoverflow.com/questions/50917016/make-hidden-field-required/50917245
                          style={{
                            opacity: 0,
                            width: 0,
                            padding: 0,
                            margin: 0,
                          }}
                          required={requiredField}
                        />
                      }
                      label={
                        <Button
                          key={`${option.id}-button`}
                          style={{
                            backgroundColor:
                              selectedButtonId === option.id
                                ? setColor(buttonColor)
                                : buttonColor,
                            color,
                            textDecoration: 'none',
                            border: `1px solid ${
                              selectedButtonId === option.id
                                ? setColor(borderColor)
                                : borderColor
                            }`,
                            boxSizing: 'border-box',
                            borderRadius,
                            textAlign: 'center',
                            wordBreak: 'break-word',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'none',
                            transition: 'all 0.2s ease-in-out',
                            fontSize,
                            width,
                          }}
                          component="span"
                          onClick={(e) => onClickHandler(option.id)}
                        >
                          {iconAlign === 'right'
                            ? renderButtonLayout(
                                option.id,
                                option.label,
                                index,
                              ).reverse()
                            : renderButtonLayout(
                                option.id,
                                option.label,
                                index,
                              )}
                        </Button>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            <FormHelperText>
              <RenderVariableHtml htmlString={helperText} />
            </FormHelperText>
          </ContentContainer>
        );
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        label: 'Yes/No',
        options: [
          {
            id: uuid(),
            label: 'Yes',
            value: false,
          },
          {
            id: uuid(),
            label: 'No',
            value: false,
          },
        ],
        requiredField: false,
        helperText: '',
        color: '#FFFFFF',
        buttonColor: '#6200EE',
        iconColor: '#FFFFFF',
        borderColor: '#5700D4',
        width: 140,
        fontSize: 14,
        borderRadius: 5,
        iconAlign: 'left',
        hideIcon: false,
        yesIconSrc:
          'https://cdn-au.whispir.com/public/resources/31c0e3dd97833bf32b5fe3c50bd05c5cde.svg',
        noIconSrc:
          'https://cdn-au.whispir.com/public/resources/31c7dd701a94d97f63d25e08021831bec2.svg',
        textAlign: 'center',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return (
          <>
            {generateControls(props, [
              {
                label: 'Content',
                controlsConfig: {
                  label: {
                    controlType: 'text',
                    contentDataKey: 'label',
                    data: {
                      label: 'Label',
                    },
                  },
                  options: {
                    controlType: 'options-picker-buttons',
                    contentDataKey: 'options',
                    data: {
                      label: 'Options',
                      mode: 'single',
                    },
                  },
                  helperText: {
                    controlType: 'variable-text-input',
                    contentDataKey: 'helperText',
                    data: {
                      label: 'Helper Text',
                      variables,
                    },
                  },
                  requiredField: {
                    controlType: 'boolean',
                    contentDataKey: 'requiredField',
                    data: {
                      label: 'Required Field',
                    },
                  },
                },
              },
              {
                label: 'Styles',
                drawLine: true,
                controlsConfig: {
                  color: {
                    controlType: 'color-picker',
                    contentDataKey: 'color',
                    data: {
                      label: 'Text Color',
                    },
                  },
                  buttonColor: {
                    controlType: 'color-picker',
                    contentDataKey: 'buttonColor',
                    data: {
                      label: 'Button Color',
                    },
                  },

                  borderColor: {
                    controlType: 'color-picker',
                    contentDataKey: 'borderColor',
                    data: {
                      label: 'Button Border Color',
                    },
                  },
                  fontSize: {
                    controlType: 'slider',
                    contentDataKey: 'fontSize',
                    data: {
                      label: 'Font Size',
                      minValue: 1,
                      maxValue: 72,
                      step: 1,
                      units: 'px',
                    },
                  },
                  borderRadius: {
                    controlType: 'slider',
                    contentDataKey: 'borderRadius',
                    data: {
                      label: 'Border Radius',
                      minValue: 1,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  width: {
                    controlType: 'slider',
                    contentDataKey: 'width',
                    data: {
                      label: 'Button Width',
                      minValue: 1,
                      maxValue: 600,
                      step: 1,
                      units: 'px',
                    },
                  },
                },
              },
              {
                label: 'Options',
                drawLine: true,
                controlsConfig: {
                  hideIcon: {
                    controlType: 'boolean',
                    contentDataKey: 'hideIcon',
                    data: {
                      label: 'Hide Icon',
                    },
                  },
                  yesIconSrc: {
                    controlType: 'text',
                    contentDataKey: 'yesIconSrc',
                    data: {
                      label: 'Icon 1 Image Source',
                    },
                    isVisible: !props.contentItem.data.hideIcon,
                  },
                  noIconSrc: {
                    controlType: 'text',
                    contentDataKey: 'noIconSrc',
                    data: {
                      label: 'Icon 2 Image Source',
                    },
                    isVisible: !props.contentItem.data.hideIcon,
                  },
                },
              },
            ])}

            {generateControls(props, [
              {
                label: 'Position',
                drawLine: false,
                controlsConfig: {
                  iconAlign: {
                    controlType: 'alignment-selector',
                    contentDataKey: 'iconAlign',
                    data: {
                      label: 'Icon Alignment',
                      variant: 'leftright',
                    },
                  },
                  textAlign: {
                    controlType: 'alignment-selector',
                    contentDataKey: 'textAlign',
                    data: {
                      label: 'Button',
                      variant: 'items',
                    },
                  },
                },
              },
              {
                subLabel: 'Padding',
                variant: 'grid',
                controlsConfig: {
                  paddingTop: {
                    controlType: 'slider',
                    contentDataKey: 'paddingTop',
                    data: {
                      label: 'Top Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingBottom: {
                    controlType: 'slider',
                    contentDataKey: 'paddingBottom',
                    data: {
                      label: 'Bottom Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingLeft: {
                    controlType: 'slider',
                    contentDataKey: 'paddingLeft',
                    data: {
                      label: 'Left Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingRight: {
                    controlType: 'slider',
                    contentDataKey: 'paddingRight',
                    data: {
                      label: 'Right Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                },
              },
            ])}
          </>
        );
      },
      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'string',
            inputType: contentItem.type,
            label: contentItem.data.label,
            options: contentItem.data.options,
          },
        ];
      },
    },
  },
};
