import styled from 'styled-components';
import { Fonts } from '@whispir/ui-lib-v2';

export const ExitConfirmationTitleModal = styled.div`
  ${Fonts.headline}
`;

export const ExitConfirmationBodyModal = styled.div`
  padding: 16px 0;
  ${Fonts.body}
`;
