import { Typography } from '@material-ui/core';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';

export const DisplayTextContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-text'
> = {
  type: 'display-text',
  label: 'Rich Text',
  icon: 'Text',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, RenderVariableHtml }) => {
        const { data } = contentItem;
        const {
          color,
          backgroundColor,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          text,
        } = data;

        return (
          <ContentContainer
            style={{
              backgroundColor,
              color,
              textAlign,
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
            }}
          >
            <Typography
              component="div"
              style={{
                textAlign,
              }}
            >
              <RenderVariableHtml htmlString={text} />
            </Typography>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem: { data },
          RenderVariableHtml,
        } = props;
        const {
          color,
          backgroundColor,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          text,
        } = data;

        return `
        <mj-text
          container-background-color="${backgroundColor}"
          padding-top="${paddingTop}px"
          padding-bottom="${paddingBottom}px"
          padding-left="${paddingLeft}px"
          padding-right="${paddingRight}px"
          color="${color}"
          align="${textAlign}"
        >
          ${RenderVariableHtml(text)}
        </mj-text>`;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        color: '#000000',
        textAlign: 'left',
        text: 'Enter text here...',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              text: {
                controlType: 'rich-text-paragraph',
                contentDataKey: 'text',
                data: {
                  label: 'Rich Text',
                  variables,
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              color: {
                controlType: 'color-picker',
                contentDataKey: 'color',
                data: {
                  label: 'Text Color',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              textAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'textAlign',
                data: {
                  label: 'Text Alignment',
                  variant: 'text',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
