import styled from 'styled-components';

export const StyledHeaderActions = styled.div`
  display: flex;
  gap: 24px;

  button:nth-child(2) {
    // to align NovaButton with old button style
    height: 40px;
  }
`;
