import styled from 'styled-components';
import { SocialIconsDataTypes } from './DisplaySocialIcons';

export const ICON_PADDING = 8;
export const ICON_ROUND_PADDING = 2;

const iconColours = {
  Facebook: '#425B93',
  LinkedIn: '#3077B0',
  Twitter: '#4AA1EB',
  Instagram: '#C84274',
  WhatsApp: '#65CF72',
  Youtube: '#EB3223',
  WeChat: '#58BD6A',
  TikTok: '#000000',
  Email: '#6DB8D1',
  Discord: '#5865F2',
  Pinterest: '#E60023',
  Vimeo: '#1AB7EA',
  Reddit: '#FF4500',
  Website: '#007A76',
};

const colorSwitch = (iconColour) => ({
  'colour-filled': `
    background-color: ${iconColour};
    fill: #FFFFFF;
    border-radius: 50%;
  `,
  'light-filled': `
    background-color: #FFFFFF;
    fill: #000000;
    border-radius: 50%;
  `,
  'dark-filled': `
    background-color: #000000;
    fill: #FFFFFF;
    border-radius: 50%;
  `,
  'colour-squares': `
    background-color: ${iconColour};
    fill: #FFFFFF;
  `,
  'light-squares': `
    background-color: #FFFFFF;
    fill: #000000;
  `,
  'dark-squares': `
    background-color: #000000;
    fill: #FFFFFF;
  `,
  'light-icons': `
    background-color: transparent;
    fill: #FFFFFF;
  `,
  'dark-icons': `
    background-color: transparent;
    fill: #000000;
  `,
});

const getIconStyle = (selectedIcon, iconStyle) => {
  return colorSwitch(iconColours[selectedIcon])[iconStyle];
};

export const gridGapRow = (spacing) => (spacing > 0 ? spacing / 2 : spacing);
export const gridGapColumn = (spacing) => spacing;
export const emailIconDimension = (scale, padding) => scale + 2 * padding;
export const StyledIconWrapper = styled.a<{
  spacing: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${ICON_PADDING}px;
  margin: ${(props) =>
    gridGapRow(props.spacing) + 'px ' + gridGapColumn(props.spacing) + 'px'};
`;

export const StyledIconRoundSection = styled.div<{
  scale: number;
  selectedIcon: unknown;
  iconStyle: unknown;
}>`
  padding: ${ICON_ROUND_PADDING}px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => getIconStyle(props.selectedIcon, props.iconStyle)}
  svg {
    width: ${({ scale }) => scale}px;
    height: ${({ scale }) => scale}px;
  }
`;

export const StyledIconsSection = styled.div<SocialIconsDataTypes>`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.backgroundColor};
  justify-content: ${(props) =>
    props.fullWidth
      ? 'space-between'
      : props.iconAlign === 'left'
      ? 'flex-start'
      : props.iconAlign === 'right'
      ? 'flex-end'
      : 'center'};
`;

export const StyledCustomImage = styled.img`
  object-fit: cover;
  object-position: center;
  border: none;
  outline: none;
  display: block;
`;

export const StyledIconStyleMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;

  .styled-icon-round-section {
    &.styled-icon-round-section--transparent {
      background: repeating-conic-gradient(#d4d4d4 0% 25%, #f0f0f0 0% 50%) 50% /
        5px 5px;
    }

    &.styled-icon-round-section--outline {
      box-shadow: inset 0px 0px 1px #70707b;
    }
  }

  .dropdown__value-container & .styled-icon-round-section {
    display: none;
  }
`;
