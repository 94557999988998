import { ResolvedVariable, Variable } from '@whispir/variables';
import { Colors } from '@whispir/ui-lib-v2';
import { ReactElement } from 'react';
import { ModeContextType } from '../Contexts/ModeProvider';
import { unEscapeHtml } from '../../utils/stringUtils';
import {
  extractSpanTextFromHtmlString,
  extractVariableIdFromHtmlString,
  extractVariables,
} from './variableRegexes';
import { FormatOptions, formatVariable } from './variableFormatters';

type VariableStringDisplayProps = {
  /**
   * The html string to resolve the variables in.
   */
  htmlString: string;
  /**
   * The viewing mode for the variables.
   */
  variableMode: ModeContextType['variableMode'];
  /**
   * The resolved variables to replace the variable placeholders with.
   */
  inputValues: Array<ResolvedVariable>;
  /**
   * The input variables are here to validate the variables used within each component are valid
   */
  inputVariables?: Array<Variable>;
} & Partial<FormatOptions>;

/**
 * The base Variable rendering function.
 * Matches the variables from the input values and performs formatting
 * @param props
 * @param destination
 */
export const RenderVariableString = (
  props: VariableStringDisplayProps,
  destination: 'text' | 'html',
): string => {
  const { htmlString, variableMode, inputValues, inputVariables } = props;

  // Adding a line break to empty <p> tags, the same way Draftail handles them.
  // This allows the native <br/> tag to display empty lines.
  // Ideally Draftail would do this replacement for us, but the `GenericTextareaWithVariables`
  // and `VariableTextInput` require signficant cleanup to provide consistent styling.
  const htmlStringWithLineBreaks = htmlString.replace(
    new RegExp('<p></p>', 'g'),
    '<p><br/></p>',
  );

  const items = extractVariables(htmlStringWithLineBreaks, (str) => {
    const id = extractVariableIdFromHtmlString(str);
    const label = extractSpanTextFromHtmlString(str);

    if (variableMode === 'builder') {
      const variableFound = inputVariables?.find(
        (inputVar) => inputVar.id === id,
      );
      if (destination === 'html') {
        // variable can't be found from the list of inputVariables, render red variable chip
        if (variableFound === undefined) {
          return `<span 
          style="
            background-color: ${Colors.error_light};
            color: ${Colors.error};
            border-radius: 16px;
            padding: 2px 12px;
          ">${label}</span>`;
        }

        // renders normal purple variable chip
        return `<span 
        style="
          background-color: ${Colors.variable_light};
          color: ${Colors.variable};
          border-radius: 16px;
          padding: 2px 12px;
        ">${label}</span>`;
      } else {
        return `<${label}>`;
      }
      // If mode === 'viewer'
    } else {
      const matchedInputValue = inputValues.find(
        (inputValue) => inputValue.id === id,
      );

      if (!matchedInputValue) {
        return '';
      }

      const formattedValue = formatVariable(matchedInputValue, props);

      return formattedValue;
    }
  });

  const newHtmlString = items.join('');

  if (destination === 'text') {
    // Unescape characters when destination is text
    return unEscapeHtml(newHtmlString);
  } else {
    return newHtmlString;
  }
};

/**
  Convert the Variable HTML string into a span string containing either the variable previews, or rendered variable value. 
  Use when html is required for rendering.
* @param props
 */
export const RenderedVariableStringAsSpanString = (
  props: VariableStringDisplayProps,
): string => RenderVariableString(props, 'html');

/**
 * Convert a variable HTML string into a string only.
 * Useful in representations where HTML elements are not appropriate. (eg. aria-labels)
 * @param props
 */
export const RenderedVariableStringAsString = (
  props: VariableStringDisplayProps,
): string => RenderVariableString(props, 'text');

/**
 * Convert the variable HTML string into a React element, which is a span. w
 * @param props
 */
export const RenderedVariableStringAsReactSpan = (
  props: VariableStringDisplayProps,
): ReactElement => (
  <span
    dangerouslySetInnerHTML={{
      __html: RenderVariableString(props, 'html'),
    }}
  />
);
