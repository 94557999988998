import styled from 'styled-components';

import { SquareIconButton } from '@whispir/ui-lib-v2';

export const Wrapper = styled.div<{
  isHidden: boolean;
}>`
  display: ${(p) => (p.isHidden ? 'none' : 'inline-block')};
  pointer-events: none;
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  border-radius: 0.5rem;
  opacity: 70%;
`;

export const PreviewSquareIconButton = styled(SquareIconButton)`
  display: block;
`;
