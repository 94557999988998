import { Fragment, memo } from 'react';
import { useDrag } from 'react-dnd';
import DropLine from '../DropLine';
import Component from '../Component';
import DropZoneWrapper from '../DropZoneWrapper';
import {
  ActualContentItemReference,
  BuilderColumnProps,
  isColumnReference,
} from '../utils/types';
import { DRAGGABLE_TYPES } from '../utils/constants';
import { NoContentItem } from '../NoContentItem';
import * as S from './styles';

const BuilderColumn = memo(
  ({
    referenceData,
    contentMap,
    handleDrop,
    path,
    selectedItemId,
    setSelectedItem,
    handleDeleteItem,
    handleDuplicateItem,
    parentLineDataArray,
    isFormRow,
  }: BuilderColumnProps) => {
    if (!isColumnReference(referenceData)) {
      throw new Error('Current data is not a column reference');
    }

    // TODO: can we remove this as we can't drag columns
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, dragRef] = useDrag({
      type: DRAGGABLE_TYPES.COLUMN,
      item: {
        layoutType: DRAGGABLE_TYPES.COLUMN,
        children: referenceData.children,
        path,
      },
    });

    const renderComponent = (
      contentReference: ActualContentItemReference,
      currentPath: string,
    ) => (
      <Component
        key={contentReference.contentId}
        referenceData={contentReference}
        contentMap={contentMap}
        path={currentPath}
        setSelectedItem={setSelectedItem}
        selectedItemId={selectedItemId}
        handleDeleteItem={handleDeleteItem}
        handleDuplicateItem={handleDuplicateItem}
      />
    );

    const isEmpty = !referenceData.children.length;

    return !isEmpty ? (
      <S.Wrapper ref={dragRef} isEmpty={isEmpty} aria-label="Column">
        {referenceData.children.map((contentReference, index) => {
          const currentPath = `${path}-${index}`;
          const nextLayoutIndex =
            index === referenceData.children.length - 1
              ? referenceData.children.length
              : index + 1;

          return (
            <DropZoneWrapper
              key={contentReference.contentId}
              type={DRAGGABLE_TYPES.COMPONENT}
              disableDrop={isFormRow}
              dropLineDataArray={[
                {
                  path: currentPath,
                  childrenCount: referenceData.children.length,
                },
                {
                  path: `${path}-${nextLayoutIndex}`,
                  childrenCount: referenceData.children.length,
                },
                ...parentLineDataArray,
              ]}
              handleDrop={handleDrop}
            >
              {renderComponent(contentReference, currentPath)}
            </DropZoneWrapper>
          );
        })}
      </S.Wrapper>
    ) : (
      <Fragment key={path}>
        <DropLine
          type={DRAGGABLE_TYPES.ROW}
          data={{
            path: `${path}-0`,
            childrenCount: referenceData.children.length,
          }}
          handleDrop={handleDrop}
        >
          {({ isOver }) => <NoContentItem isOver={isOver} />}
        </DropLine>
      </Fragment>
    );
  },
);

export default BuilderColumn;
