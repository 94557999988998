import { memo, useEffect } from 'react';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { IconTypes, SquareIconButton } from '@whispir/ui-lib-v2';
import { SidebarContentItemReference } from '../../utils/types';
import { DRAGGABLE_TYPES } from '../../utils/constants';
import * as S from './styles';

type SideBarItemProps = {
  layoutReference: SidebarContentItemReference;
  onClick?: () => void;
  label: string;
  icon: IconTypes;
  ariaLabel: string;
  isDisabled?: boolean;
};

const SideBarItem = memo(
  ({
    layoutReference,
    onClick,
    label,
    icon,
    ariaLabel,
    isDisabled,
  }: SideBarItemProps) => {
    const [{ isDragging }, dragRef, preview] = useDrag({
      type: DRAGGABLE_TYPES.SIDEBAR,
      item: { ...layoutReference, label, icon },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <S.Wrapper
        ref={isDisabled ? null : dragRef}
        onClick={() => {
          if (!isDisabled && onClick) onClick();
        }}
        lowerOpacity={isDragging}
        aria-label={ariaLabel}
        role="button"
        isDisabled={isDisabled}
      >
        <SquareIconButton label={label} icon={icon} />
      </S.Wrapper>
    );
  },
);

export default SideBarItem;
