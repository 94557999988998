import styled from 'styled-components';

export const FooterText = styled.div<{ color: string; textAlign?: string }>`
  color: ${({ color }) => color};
  //TODO: add this font to ui-lib?
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;

  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: ${({ textAlign }) => textAlign};
`;
