import { useState } from 'react';
import { Alert, AlertPropsTypes } from '@whispir/ui-lib-v2';

type Props = Partial<AlertPropsTypes> & // Partial adds all props as optional
  Pick<AlertPropsTypes, 'content'>; // Pick makes 'content' required

export const SelfDismissAlert = ({
  content,
  title,
  type = 'banner',
  alert = 'info',
}: Props) => {
  const [isOpen, setOpen] = useState(true);

  return !isOpen ? null : (
    <Alert
      title={title}
      type={type}
      alert={alert}
      content={content}
      onClose={() => setOpen(false)}
    />
  );
};
