import { Fonts } from '@whispir/ui-lib-v2';
import styled from 'styled-components';
import Frame from 'react-frame-component';

export const StyledFrame = styled(Frame)`
  border: none;
  width: 100%;
  height: calc(100% - 6px);
`;

export const ScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const LoadingScreen = styled.div`
  ${Fonts.subheadSerif}
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;

  > *:first-child {
    margin-bottom: 32px;
  }
`;

export const EmailWrapper = styled.div`
  // TODO: fix the styling of the TabComponent in ui-lib-v2 so this isn't necessary
  * {
    box-sizing: unset;
  }

  width: 100%;
  height: 100%;
`;

export const emailScrollStyleTag = `
  body {
    overflow-x: hidden;
    overflow-y: overlay;
    scrollbar-width: thin; /* Firefox */
  }

  body::-webkit-scrollbar {
    width: 4px;
  }

  body::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  body::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
`;
