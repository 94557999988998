import { useRef, useState } from 'react';
import {
  GenericTextareaWithVariables,
  ToggleSwitch,
  VariableTextInput,
} from '@whispir/ui-lib-v2';
import { useEffect } from 'react';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { useTrackingEvents } from '../../../components/Contexts/TrackingEventsProvider';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { StyledLabel, StyledTypography } from './DisplayArticle.styles';

type ImageBlockProps = {
  src: string;
  alt: string;
};

const ImageBlock = (props: ImageBlockProps) => {
  const { src, alt } = props;
  return (
    <div>
      <img
        src={src}
        alt={alt}
        style={{ objectFit: 'contain', maxWidth: '100%' }}
      />
    </div>
  );
};

export const DisplayArticleContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-article'
> = {
  type: 'display-article',
  label: 'Article',
  icon: 'TextAndImage',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        RenderVariableHtml,
        RenderVariableText,
      }) => {
        const { data } = contentItem;
        const {
          text,
          imageBoth,
          textBoth,
          switchPosition,
          color,
          backgroundColor,
          textAlign,
          firstImageSrc,
          firstImageAlt,
          secondImageSrc,
          secondImageAlt,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        } = data;

        const resolvedFirstImageSrc = RenderVariableText(firstImageSrc);
        const resolvedSecondImageSrc = RenderVariableText(secondImageSrc);

        const textContentStyle = {
          backgroundColor,
          color,
          textAlign,
          wordBreak: 'break-word',
        } as React.CSSProperties;
        const textContent1 = (
          <StyledTypography component="div" style={textContentStyle}>
            <RenderVariableHtml htmlString={text[0]} />
          </StyledTypography>
        );

        const textContent2 = (
          <StyledTypography component="div" style={textContentStyle}>
            <RenderVariableHtml htmlString={text[1]} />
          </StyledTypography>
        );

        const imageContent1 = (
          <ImageBlock src={resolvedFirstImageSrc} alt={firstImageAlt} />
        );

        const imageContent2 = (
          <ImageBlock src={resolvedSecondImageSrc} alt={secondImageAlt} />
        );

        let content1: React.ReactElement;
        let content2: React.ReactElement;

        if (imageBoth) {
          content1 = imageContent1;
          content2 = imageContent2;
        } else if (textBoth) {
          content1 = textContent1;
          content2 = textContent2;
        } else {
          content1 = textContent1;
          content2 = imageContent1;
        }

        return (
          <ContentContainer
            style={{
              color,
              paddingTop,
              paddingRight,
              paddingLeft,
              paddingBottom,
              backgroundColor,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            {switchPosition ? (
              <>
                {content2}
                {content1}
              </>
            ) : (
              <>
                {content1}
                {content2}
              </>
            )}
          </ContentContainer>
        );
      },

      EmailFunction: (props) => {
        const { contentItem, RenderVariableHtml, RenderVariableText } = props;
        const { data } = contentItem;

        const {
          text,
          imageBoth,
          textBoth,
          switchPosition,
          color,
          backgroundColor,
          textAlign,
          firstImageSrc,
          firstImageAlt,
          secondImageSrc,
          secondImageAlt,
          paddingTop,
          paddingBottom,
          paddingRight,
          paddingLeft,
        } = data;

        const textContent1 = `
          <mj-text
            color="${color}"
            align="${textAlign}"
          >
            ${RenderVariableHtml(text[0])}
          </mj-text>`;

        const textContent2 = `
          <mj-text
            color="${color}"
            align="${textAlign}"
          >
            ${RenderVariableHtml(text[1])}
          </mj-text>`;

        const imageContent1 = `
          <mj-image 
            src="${RenderVariableText(firstImageSrc)}"
            alt="${firstImageAlt}"
          />`;

        const imageContent2 = `
          <mj-image 
            src="${RenderVariableText(secondImageSrc)}"
            alt="${secondImageAlt}"
          />`;

        let content1: string;
        let content2: string;

        if (imageBoth) {
          content1 = imageContent1;
          content2 = imageContent2;
        } else if (textBoth) {
          content1 = textContent1;
          content2 = textContent2;
        } else {
          content1 = textContent1;
          content2 = imageContent1;
        }

        return `
          <mj-section
            background-color="${backgroundColor}"
            padding-bottom="${paddingBottom}px"
            padding-top="${paddingTop}px"
            padding-left="${paddingLeft}px"
            padding-right="${paddingRight}px"
          >
            <mj-column>          
              ${switchPosition ? content2 : content1}
            </mj-column>
            <mj-column>
              ${switchPosition ? content1 : content2}
            </mj-column>
          </mj-section>
        `;
      },

      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        text: ['<p>Enter text here...</p>', '<p>Enter text here...</p>'],
        imageBoth: false,
        textBoth: false,
        switchPosition: false,
        color: '#000000',
        textAlign: 'left',
        firstImageSrc:
          'https://d1ccvuha0qk3lj.cloudfront.net/uploads/general/img_placeholder.png',
        firstImageAlt: 'First Alt Description',
        secondImageSrc:
          'https://d1ccvuha0qk3lj.cloudfront.net/uploads/general/img_placeholder.png',
        secondImageAlt: 'Second Alt Description',
      }),
      PropertiesPanel: (props) => {
        const { contentItem, onContentUpdate, variables } = props;

        const { trackControlComponentInteraction } = useTrackingEvents();

        const [imageBoth, setImageBoth] = useState(contentItem.data.imageBoth);
        const [textBoth, setTextBoth] = useState(contentItem.data.textBoth);
        const [text1, setText1] = useState(contentItem.data.text[0]);
        const [text2, setText2] = useState(contentItem.data.text[1]);

        const [image1Alt, setImage1Alt] = useState(
          contentItem.data.firstImageAlt,
        );
        const [image1Src, setImage1Src] = useState(
          contentItem.data.firstImageSrc,
        );

        const [image2Alt, setImage2Alt] = useState(
          contentItem.data.secondImageAlt,
        );
        const [image2Src, setImage2Src] = useState(
          contentItem.data.secondImageSrc,
        );

        const handleImage1Change = (value: string) => {
          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'firstImageSrc',
            controlType: 'variable-text-input',
          });

          setImage1Src(value);
        };

        const handleImage2Change = (value: string) => {
          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'secondImageSrc',
            controlType: 'variable-text-input',
          });
          setImage2Src(value);
        };

        const handleImage1AltChange = (value: string) => {
          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'firstImageAlt',
            controlType: 'variable-text-input',
          });

          setImage1Alt(value);
        };

        const handleImage2AltChange = (value: string) => {
          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'secondImageAlt',
            controlType: 'variable-text-input',
          });

          setImage2Alt(value);
        };

        const handleImageBothChange = (checked: boolean) => {
          setImageBoth(checked);

          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'imageBoth',
            controlType: 'boolean',
          });

          if (checked) {
            setTextBoth(false);
          }
        };

        const handleTextBothChange = (checked: boolean) => {
          setTextBoth(checked);

          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'textBoth',
            controlType: 'boolean',
          });

          if (checked) {
            setImageBoth(false);
          }
        };

        const handleRaw1Change = (html: string) => {
          setText1(html);

          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'text[0]',
            controlType: 'rich-text-paragraph',
          });
        };

        const handleRaw2Change = (html) => {
          setText2(html);

          trackControlComponentInteraction({
            contentType: contentItem.type,
            controlKey: 'text[1]',
            controlType: 'rich-text-paragraph',
          });
        };

        const contentItemRef = useRef(contentItem);
        contentItemRef.current = contentItem;

        const contentUpdateRef = useRef(onContentUpdate);
        contentUpdateRef.current = onContentUpdate;

        useEffect(() => {
          const reffedContentItem = contentItemRef.current;
          const reffedContentUpdate = contentUpdateRef.current;
          if (!reffedContentItem) {
            throw new Error(
              'Something has gone wrong, reffedContentItem is empty',
            );
          }

          reffedContentUpdate(
            {
              ...reffedContentItem,
              data: {
                ...reffedContentItem.data,
                text: [text1, text2],
                textBoth,
                imageBoth,
                firstImageAlt: image1Alt,
                firstImageSrc: image1Src,
                secondImageAlt: image2Alt,
                secondImageSrc: image2Src,
              },
            },
            [],
          );
        }, [
          imageBoth,
          textBoth,
          text1,
          text2,
          image1Alt,
          image1Src,
          image2Alt,
          image2Src,
        ]);

        return (
          <>
            {generateControls(props, [
              {
                label: 'Layout',
                drawLine: false,
                controlsConfig: {},
              },
            ])}
            <ToggleSwitch
              label="Both Image"
              defaultValue={imageBoth ? 'true' : 'false'}
              onChange={handleImageBothChange}
            />

            <ToggleSwitch
              label="Both Text"
              defaultValue={textBoth ? 'true' : 'false'}
              onChange={handleTextBothChange}
            />

            {generateControls(props, {
              switchPosition: {
                controlType: 'boolean',
                contentDataKey: 'switchPosition',
                data: {
                  label: 'Switch Position',
                },
              },
            })}
            {!imageBoth && (
              <>
                <StyledLabel className="text-box-label">
                  Text Block One
                  <GenericTextareaWithVariables
                    ariaLabel="Text Block One"
                    variableGroups={variables}
                    onSave={handleRaw1Change}
                    showVariableButton={
                      Object.values(props.variables).length > 0
                    }
                    initialValue={text1}
                  />
                </StyledLabel>
              </>
            )}

            {textBoth && (
              <StyledLabel className="text-box-label">
                Text Block Two
                <GenericTextareaWithVariables
                  variableGroups={variables}
                  onSave={handleRaw2Change}
                  ariaLabel="Text Block Two"
                  showVariableButton={Object.values(props.variables).length > 0}
                  initialValue={text2}
                />
              </StyledLabel>
            )}

            {!textBoth && (
              <>
                <VariableTextInput
                  variableTextInputType="componentPropertyInput"
                  activeChannel="componentPropertyInput"
                  label="Image Source 1"
                  defaultTitle="Image Source 1"
                  defaultValue={image1Src}
                  variableMenuData={variables}
                  onChange={handleImage1Change}
                />
                <VariableTextInput
                  aria-label={`${props.contentItem.id}-image1-alt`}
                  activeChannel="componentPropertyInput"
                  label="Image Alt 1"
                  defaultTitle="Image Alt 1"
                  defaultValue={image1Alt}
                  variableTextInputType="componentPropertyInput"
                  variableMenuData={variables}
                  onChange={handleImage1AltChange}
                />
              </>
            )}

            {imageBoth && (
              <>
                <VariableTextInput
                  variableTextInputType="componentPropertyInput"
                  activeChannel="componentPropertyInput"
                  label="Image Source 2"
                  defaultTitle="Image Source 2"
                  defaultValue={image2Src}
                  variableMenuData={variables}
                  onChange={handleImage2Change}
                />
                <VariableTextInput
                  aria-label={`${props.contentItem.id}-image2-alt`}
                  activeChannel="componentPropertyInput"
                  label="Image Alt 2"
                  defaultTitle="Image Alt 2"
                  defaultValue={image2Alt}
                  variableTextInputType="componentPropertyInput"
                  variableMenuData={variables}
                  onChange={handleImage2AltChange}
                />
              </>
            )}

            {generateControls(props, [
              {
                label: 'Styles',
                controlsConfig: {
                  color: {
                    controlType: 'color-picker',
                    contentDataKey: 'color',
                    data: {
                      label: 'Text Color',
                    },
                  },
                  backgroundColor: {
                    controlType: 'color-picker',
                    contentDataKey: 'backgroundColor',
                    data: {
                      label: 'Background Color',
                    },
                  },
                },
              },
              {
                label: 'Position',
                drawLine: false,
                controlsConfig: {
                  textAlign: {
                    controlType: 'alignment-selector',
                    contentDataKey: 'textAlign',
                    data: {
                      label: 'Text Alignment',
                      variant: 'text',
                    },
                  },
                },
              },
              {
                subLabel: 'Padding',
                variant: 'grid',
                controlsConfig: {
                  paddingTop: {
                    controlType: 'slider',
                    contentDataKey: 'paddingTop',
                    data: {
                      label: 'Top Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingBottom: {
                    controlType: 'slider',
                    contentDataKey: 'paddingBottom',
                    data: {
                      label: 'Bottom Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingLeft: {
                    controlType: 'slider',
                    contentDataKey: 'paddingLeft',
                    data: {
                      label: 'Left Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingRight: {
                    controlType: 'slider',
                    contentDataKey: 'paddingRight',
                    data: {
                      label: 'Right Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                },
              },
            ])}
          </>
        );
      },
    },
  },
};
