import { Alert, Colors } from '@whispir/ui-lib-v2';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { FooterText } from './DisplayUnsubscribeFooter.styles';
import {
  addressPlaceholder,
  companyNamePlaceholder,
  defaultCompanyName,
  getAddressText,
  getCopyrightText,
  subscriptionExplanationPlaceholder,
  unresolvedUnsubscribeLinkVariable,
  unsubscribePrompt,
} from './unsubscribe-utils';

export const DisplayUnsubscribeFooterContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-unsubscribe-footer'
> = {
  type: 'display-unsubscribe-footer',
  label: 'Unsubscribe',
  icon: 'Footer',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, RenderVariableHtml }) => {
        const { data } = contentItem;
        const {
          backgroundColor,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          subscriptionExplanation,
          textColor,
          unsubscribeLinkColor,
          address,
          companyName,
        } = data;

        const subscriptionExplanationText = subscriptionExplanation.length
          ? RenderVariableHtml({ htmlString: subscriptionExplanation })
          : subscriptionExplanationPlaceholder;

        const companyNameText = companyName.length
          ? companyName
          : defaultCompanyName;

        const companyCopyright = getCopyrightText(companyNameText);

        const addressText = getAddressText(address);

        return (
          <ContentContainer
            style={{
              backgroundColor,
              textAlign,
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
            }}
          >
            <FooterText color={textColor} textAlign={textAlign}>
              <div>{companyCopyright}</div>
              <div style={{ whiteSpace: 'pre', wordWrap: 'break-word' }}>
                {addressText}
              </div>
              <div>{subscriptionExplanationText}</div>
              <div>{unsubscribePrompt}</div>
              <a
                href={unresolvedUnsubscribeLinkVariable}
                style={{
                  color: unsubscribeLinkColor,
                  textDecoration: 'underline',
                  fontWeight: 500,
                }}
              >
                Unsubscribe
              </a>
            </FooterText>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem: { data },
          RenderVariableHtml,
        } = props;
        const {
          backgroundColor,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          textColor,
          subscriptionExplanation,
          unsubscribeLinkColor,
          address,
          companyName,
        } = data;

        const subscriptionExplanationText = subscriptionExplanation.length
          ? RenderVariableHtml(subscriptionExplanation)
          : subscriptionExplanationPlaceholder;

        const companyNameText = companyName.length
          ? companyName
          : defaultCompanyName;

        const companyCopyright = getCopyrightText(companyNameText);

        const addressText = getAddressText(address);

        // note that 'white-space' is unsupported in Windows Outlook desktop
        return `<mj-text
          container-background-color="${backgroundColor}"
          padding-top="${paddingTop}px"
          padding-bottom="${paddingBottom}px"
          padding-left="${paddingLeft}px"
          padding-right="${paddingRight}px"
          color="${textColor}"
          align="${textAlign}"
          
          font-family="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
          font-size="12px"
          font-weight="400"
          line-height="24px"
          letter-spacing="0px"
        >
        <table style="margin: auto">
          <tbody>
            <tr>
              <td>${companyCopyright}</td>
            </tr>
            <tr>
              <td style="white-space: pre; word-wrap: break-word;">${addressText}</td>
            </tr>
            <tr>
              <td>${subscriptionExplanationText}</td>
            </tr>
            <tr>
              <td>${unsubscribePrompt}</td>
            </tr>
            <tr>
              <td>
                <a
                  href="${unresolvedUnsubscribeLinkVariable}"
                  style="color: ${unsubscribeLinkColor}; text-decoration: underline; font-weight: 500;"
                >Unsubscribe</a>
              </td>
            </tr>
          </tbody>
        </table>
        </mj-text>`;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        //TODO: get defaut data from api or message metadata or something here
        companyName: '',
        address: '',
        subscriptionExplanation: '',
        textColor: Colors.grey_blue_4,
        unsubscribeLinkColor: Colors.accent,
        textAlign: 'center',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;
        return (
          <>
            <Alert
              type="inline"
              alert="info"
              content={
                <div>
                  It's mandatory to include an unsubscribe option in all emails,
                  so we automatically embed a unique Unsubscribe link at the end
                  of your emails. We also might automatically add your contact
                  info into the Unsubscribe Footer Block, as this is also a
                  legal requirement. <a href="www.whispir.com">Learn more.</a>
                </div>
              }
            />
            <div style={{ marginBottom: 20 }} />
            {generateControls(props, [
              {
                label: 'Content',
                controlsConfig: {
                  companyName: {
                    controlType: 'text',
                    contentDataKey: 'companyName',
                    data: {
                      label: 'Company Name',
                      placeholder: companyNamePlaceholder,
                    },
                  },
                  address: {
                    controlType: 'text-area',
                    contentDataKey: 'address',
                    data: {
                      label: 'Company Address',
                      placeholder: addressPlaceholder,
                    },
                  },
                  subscriptionExplanation: {
                    controlType: 'variable-text-area',
                    contentDataKey: 'subscriptionExplanation',
                    data: {
                      label: 'Subscription Explanation',
                      placeholder: subscriptionExplanationPlaceholder,
                      variables,
                    },
                  },
                },
              },
              {
                label: 'Styles',
                controlsConfig: {
                  textColor: {
                    controlType: 'color-picker',
                    contentDataKey: 'textColor',
                    data: {
                      label: 'Text Color',
                    },
                  },
                  unsubscribeLinkColor: {
                    controlType: 'color-picker',
                    contentDataKey: 'unsubscribeLinkColor',
                    data: {
                      label: 'Unsubscribe Link Color',
                    },
                  },
                },
              },
              {
                label: 'Position',
                drawLine: false,
                controlsConfig: {
                  textAlign: {
                    controlType: 'alignment-selector',
                    contentDataKey: 'textAlign',
                    data: {
                      label: 'Text Alignment',
                      variant: 'text',
                    },
                  },
                },
              },
              {
                subLabel: 'Padding',
                variant: 'grid',
                controlsConfig: {
                  paddingTop: {
                    controlType: 'slider',
                    contentDataKey: 'paddingTop',
                    data: {
                      label: 'Top Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingBottom: {
                    controlType: 'slider',
                    contentDataKey: 'paddingBottom',
                    data: {
                      label: 'Bottom Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingLeft: {
                    controlType: 'slider',
                    contentDataKey: 'paddingLeft',
                    data: {
                      label: 'Left Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                  paddingRight: {
                    controlType: 'slider',
                    contentDataKey: 'paddingRight',
                    data: {
                      label: 'Right Padding',
                      minValue: 0,
                      maxValue: 100,
                      step: 1,
                      units: 'px',
                    },
                  },
                },
              },
            ])}
          </>
        );
      },
    },
  },
};
