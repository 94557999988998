import { ResolvedVariable } from '@whispir/variables';

export type FormatOptions = {
  /**
   * The locale to render the variables in.
   */
  locale: string;
  /**
   * The timezone to render the variables in.
   */
  timeZone: string;
};

const replaceNewLineCharacterToBreakTags = (str: string) => {
  return str.replace(new RegExp('\\n', 'g'), '<br/>');
};

// Getting the client's locale settings.
const intlResolvedOptions = new Intl.DateTimeFormat().resolvedOptions();

const defaultFormatOptions: FormatOptions = {
  locale: intlResolvedOptions.locale,
  timeZone: intlResolvedOptions.timeZone,
};

const formatters: Record<
  string,
  (value: any, options: FormatOptions) => string
> = {
  string: (value: string) => replaceNewLineCharacterToBreakTags(value),
  number: (value: number, options) => value.toLocaleString(options.locale),
  date: (value: string, options) =>
    new Date(value).toLocaleDateString(options.locale, {
      timeZone: options.timeZone,
    }),
  'array-of-string': (value: Array<string>) => value.join(', '),
};

export const formatVariable = (
  variable: ResolvedVariable,
  options?: Partial<FormatOptions>,
): string => {
  const formatter = formatters[variable.valueType];

  const mergedOptions = { ...defaultFormatOptions, ...options };
  const formattedValue = formatter
    ? formatter(variable.value, mergedOptions)
    : `${variable.value}`;

  return formattedValue;
};
