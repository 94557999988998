import styled, { css } from 'styled-components';
import { Colors } from '@whispir/ui-lib-v2';
import { Z_INDEX_MAP } from '../utils/constants';

export const DraggableItemControls = styled.div<{
  canDuplicate: boolean;
}>`
  position: absolute;
  top: -2.25rem;
  left: 0;
  z-index: ${Z_INDEX_MAP.draggableItemControls};
  display: flex;

  button {
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    background-color: ${Colors.accent};
    margin-right: 0.25rem;

    &.draggable-item-icon-move {
      cursor: grab;
    }

    &.draggable-item-icon-delete {
      &:hover {
        background-color: ${Colors.error};
      }
    }
    &.draggable-item-icon-duplicate {
      ${({ canDuplicate }) =>
        !canDuplicate &&
        css`
          cursor: not-allowed;
          &:hover {
            background-color: ${Colors.grey_blue_2};
          }
          .MuiTouchRipple-root {
            display: none;
          }
        `}
    }

    &:hover {
      background-color: ${Colors.accent};
    }
  }

  svg {
    border-radius: 24px;
    fill: ${Colors.white};
  }
`;
