import styled from 'styled-components';

import { Slider } from '@material-ui/core';

export type NumericSliderProps = {
  defaultValue: number;
  helperText?: string;
  sliderColor: string;
  showLabels?: boolean;
  leftLabel?: string;
  centerLabel?: string;
  rightLabel?: string;
  onChange: (value: number) => void;
  barColor?: string;
};

export type SliderLabelProps = {
  position: 'start' | 'center' | 'end';
};

export const StyledNumericSliderWrapper = styled.div`
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const StyledSliderHeading = styled.div`
  font-family: Inter UI;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #70707b;
  display: flex;
  padding-bottom: 20px;
`;

export const StyledSlider = styled(Slider)<{
  barColor?: string;
  sliderColor?: string;
}>`
  && {
    display: inline-flex;
    margin: 0 auto;
  }

  //https://stackoverflow.com/questions/60721323/how-reliable-are-mui-global-class-names-in-jss/60722943#60722943
  [class*='MuiSlider-root'] {
    height: 8px;
  }

  [class*='MuiSlider-rail'] {
    height: 8px;
    border-radius: 100px;
    background-color: ${(props) => props.barColor};
    opacity: 1;
    bottom: 50%;
    transform: translateY(50%);
  }

  [class*='MuiSlider-track'] {
    height: 8px;
    background-color: ${(props) => props.sliderColor};
    border-radius: 100px;
    bottom: 50%;
    top: auto;
    transform: translateY(50%);
  }

  [class*='MuiSlider-thumb'] {
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.sliderColor};
    bottom: 50%;
    transform: translate(-50%, 50%);
    margin-left: 0px;
    &:focus, &:hover, &$active: {
      box-shadow: inherit;
    }
  }

  [class*='MuiSlider-markLabel'] {
    color: #aaaaaa;
    padding-top: 8px;
    transform: translateX(-5px);
    &[data-index='0'] {
      transform: translateX(-5px);
    }
    &[data-index='10'] {
      transform: translateX(-10px);
    }
  }

  [class*='MuiSlider-mark'] {
    width: 0;
  }
`;

export const StyledSliderLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
  margin: 0 -14px;
`;
