import { RatingProps, CustomIconsType, Rating } from '../../Rating';
import { Icons } from '../../FormRatings.support';
import { RatingWrapperProps } from '../../Rating/Rating.styles';
import { RatingWrapper } from './StarRating.styles';

export const StarRating = (props: RatingProps): JSX.Element => {
  const generateCustomIcons = (count) => {
    const customIcons: CustomIconsType = {};
    for (let iconsCount = 1; iconsCount <= count; iconsCount++) {
      const { Star } = Icons;
      customIcons[iconsCount] = {
        icon: <Star />,
        label: props[`label${iconsCount}`],
      };
    }
    return customIcons;
  };

  const { count, iconColor, highlightColor } = props;

  const ratingWrapperProps: RatingWrapperProps = {
    iconColor,
    highlightColor,
  };

  const customIcons = generateCustomIcons(count);

  return (
    <RatingWrapper {...ratingWrapperProps}>
      <Rating customIcons={customIcons} {...props} />
    </RatingWrapper>
  );
};
