import { FormEventHandler, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import useAsyncEffect from 'use-async-effect';
import { ResolvedVariable, Variable } from '@whispir/variables';
import { defaultTheme } from '../themeing/defaultTheme';
import { Debug } from '../components/Debug/Debug';
import {
  AbstractAllContentDataAsArray,
  AbstractRecords,
  AbstractDefaultedData,
} from '../framework/ContentDefinitions';
import { AbstractContentCreationMap } from '../framework/ContentCreation';
import { RealViewer } from '../components/ViewPanels/RealViewer';
import { RenderedVariableStringAsString } from '../components/VariableDisplay/VariableStringDisplay';
import {
  FormSubmissionResultMessage,
  FormSubmissionResultMessageProps,
} from '../components/Other/FormSubmissionResultMessage/FormSubmissionResultMessage';

type ViewerProps<T extends AbstractRecords> = {
  contentCreationMap: AbstractContentCreationMap<T>;
  saveSubmissionFn: (args: {
    activationId: string;
    outputValues: ResolvedVariable[];
    retrievalId?: string;
  }) => Promise<void>;
  getActivationFn: (args: {
    activationId: string;
  }) => Promise<{
    inputValues: Array<ResolvedVariable>;
    content: {
      contentData: Array<AbstractDefaultedData<T>>;
      outputVariables: Array<Variable>;
      subject: string;
    };
    retrievalId: string;
  }>;
};

/** This is the actual view that the viewer of a form will see */

export const Viewer = <T extends AbstractRecords>(props: ViewerProps<T>) => {
  const { contentCreationMap, getActivationFn, saveSubmissionFn } = props;

  // The form definition
  const [content, setContent] = useState(
    [] as AbstractAllContentDataAsArray<T>,
  );
  const [inputValues, setInputValues] = useState<Array<ResolvedVariable>>([]);

  const [submissionResult, setSubmissionResult] = useState<
    FormSubmissionResultMessageProps['resultType'] | null
  >(null);

  // The data the user has entered
  const [outputValues, setOutputValues] = useState<Array<ResolvedVariable>>([]);

  const [retrievalId, setRetrievalId] = useState<string | null>(null);

  /**
   * On load, load the form definition.
   * TODO:
   *  - handle form not found scenario
   *  - handle empty id scenario
   */
  useAsyncEffect(async () => {
    const activationId = window.location.pathname.split('/')[1];

    try {
      const {
        inputValues,
        content: { outputVariables, contentData, subject },
        retrievalId,
      } = await getActivationFn({ activationId });

      document.title = RenderedVariableStringAsString({
        htmlString: subject,
        variableMode: 'viewer',
        inputValues,
      });

      const outputValues = outputVariables.map((outputVariable) => ({
        ...outputVariable,
        value: '',
      }));

      setRetrievalId(retrievalId);
      setInputValues(inputValues);
      setOutputValues(outputValues);

      // IMPORTANT: content data must be set after setting the outputVariables,
      // so that the form update function can use the outputVariables for setting
      // the outputValues in the correct order.
      setContent(contentData);
    } catch (err) {
      console.error(err);
      setSubmissionResult('error-loading-form');
    }
  }, []);

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    const activationId = window.location.pathname.split('/')[1];

    try {
      await saveSubmissionFn({
        activationId,
        outputValues,
        retrievalId: retrievalId || undefined,
      });

      //FUTURE STATE: handle expired forms.
      setSubmissionResult('success');
    } catch (err) {
      console.error(err);
      setSubmissionResult('error');
    }
  };

  const handleFormUpdate = (
    key: string,
    value: unknown,
    /*
      In most cases, we can just set the value directly. 
      But in some cases, like check box lists, the content item needs to be aware of what other things are already checked, so it doesn't clobber them. 
      We can achieve that by having the content item pass in an updateFunction, and passing that the old data. 
    */
    updateFn?: (oldData: unknown) => unknown,
  ) => {
    const oldValue = outputValues.find(({ id }) => id === key);
    const newValue = updateFn ? updateFn(oldValue) : value;

    // It's important to use this technique
    // https://stackoverflow.com/questions/66040590/closure-on-the-result-of-a-usestate-hook-causes-annoying-behaviour
    setOutputValues((oldOutputValues) => {
      const updatedOutputValues = oldOutputValues.map((outputValue) => {
        if (outputValue.id === key) {
          const resolvedOutputValue = {
            ...outputValue,
            value: newValue,
          };

          return resolvedOutputValue;
        } else {
          return outputValue;
        }
      });

      return updatedOutputValues;
    });
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <div>
        {process.env.REACT_APP_DEV_MODE && (
          <>
            <h1>Viewer</h1>
            <Debug data={outputValues} />
          </>
        )}

        {submissionResult && (
          <FormSubmissionResultMessage resultType={submissionResult} />
        )}

        {!submissionResult && (
          <RealViewer
            inputValues={inputValues}
            onSubmit={handleSubmit}
            content={content}
            contentCreationMap={contentCreationMap}
            formUpdateFunction={handleFormUpdate}
          />
        )}
      </div>
    </ThemeProvider>
  );
};
