import { ReactNode, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { v4 as uuidv4 } from 'uuid';
import { VariableGroup, Variable, ResolvedVariable } from '@whispir/variables';
import {
  ContentBuilder,
  ContentBuilderProps,
} from '../components/ContentBuilder';
import { contentCreationMap } from '../content/content';
import { AllContentDataAsArray } from '../content/ContentTypeUtilities';
import { FormSubmitButtonContentCreationItem } from '../content/items/FormSubmitButton/FormSubmitButton';
import { LayoutColumnContentCreationItem } from '../content/items/LayoutColumn/LayoutColumn';
import { getOutputVariablesForContentData } from '../framework/functions';
import { useContentApi } from '../hooks';
import '../App.css';
import { TrackingEventsContextProvider } from '../components/Contexts/TrackingEventsProvider';
import { KnownContentTypeAndVersionMap } from '../content/schemas/KnownContentTypeAndVersionMap';
import { ApisProviderProps } from '../components/Contexts/ApisProvider';
import { DisplayUnsubscribeFooterContentCreationItem } from '../content/items/DisplayUnsubscribeFooter/DisplayUnsubscribeFooter';

export type ApiLinkedContentBuilderProps = {
  workspaceId: string;
  contentType: 'html';
  contentId?: string; // uuid
  contentVersion?: string; // uuid
  inputVariables?: Variable[];
  inputVariableGroups?: VariableGroup[];
  inputValues?: ResolvedVariable[];
  publish?: boolean;
  activate?: boolean;
  enablePreviewButtons?: boolean;
  onPublish?: ({
    publicationId,
    contentVersionId,
    contentVersion,
    publicationTime,
  }) => void;
  onActivation?: ({
    activationId,
    url,
    publicationId,
    contentVersionId,
    contentVersion,
    activationTime,
  }) => void;
  onSaveContent?: ({
    contentId,
    contentVersionId,
    contentData,
    outputVariables,
    isSaveOnly,
  }) => void;
  apis: ApisProviderProps['apis'];
  modalHeaderChildren?: ReactNode;
  contentMode: 'form' | 'email' | 'web' | 'test';
  defaultSubject?: string;
  deleteContentHandler?: ContentBuilderProps<KnownContentTypeAndVersionMap>['deleteContentHandler'];
  backButtonHandler?: ContentBuilderProps<KnownContentTypeAndVersionMap>['backButtonHandler'];
};

/**
 * This EntryPoint is an example use of how the ContentBuilder might be used
 * by WFS or MS
 *
 */
export const Builder = (props: ApiLinkedContentBuilderProps) => {
  const {
    contentId,
    contentType,
    workspaceId,
    publish = true,
    activate = true,
    onPublish = () => {},
    onActivation = () => {},
    onSaveContent = () => {},
    inputVariables = [],
    inputVariableGroups,
    inputValues = [],
    apis,
    modalHeaderChildren,
    contentMode,
    defaultSubject = '',
    deleteContentHandler,
    backButtonHandler = {
      onButtonClick: () => {},
      buttonText: 'Back',
    },
    enablePreviewButtons = false,
  } = props;

  const [content, setContent] = useState<AllContentDataAsArray>([]);
  const [subject, setSubject] = useState(defaultSubject);
  const [lastSavedDate, setLastSavedDate] = useState<Date | undefined>();
  const [isContentFetched, setIsContentFetched] = useState(!contentId);

  const {
    getContent,
    saveContentPublication,
    saveActivation,
    saveContent,
    isLoading,
    isSaving,
  } = useContentApi({
    url: apis.apiUrl,
    workspaceId,
  });

  // Fetch the data from database first load.
  useAsyncEffect(async () => {
    if (contentId) {
      const { contentData, subject } = await getContent({ contentId });

      setSubject(subject);
      setContent(contentData);
      setIsContentFetched(true);
    } else {
      // Insert submit button if content mode is form and content is new
      if (contentMode === 'form') {
        const version = '1';
        const { versions, type } = FormSubmitButtonContentCreationItem;
        const { defaultData } = versions[version];
        const id = uuidv4();
        setContent([
          {
            type: type,
            version,
            id,
            data: defaultData({ id }),
          },
        ]);
      } else {
        // If switching between contentModes, we need to reset the content.
        if (contentMode === 'test') {
          const layoutColumnId = uuidv4();
          const LayoutColumnContentItem = {
            type: LayoutColumnContentCreationItem.type,
            version: '1',
            id: layoutColumnId,
            data: LayoutColumnContentCreationItem.versions['1'].defaultData({
              id: layoutColumnId,
            }),
          } as const;

          const displayUnsubscribeFooterId = uuidv4();
          const DisplayUnsubscribeFooterContentItem = {
            type: DisplayUnsubscribeFooterContentCreationItem.type,
            version: '1',
            id: displayUnsubscribeFooterId,
            data: DisplayUnsubscribeFooterContentCreationItem.versions[
              '1'
            ].defaultData({
              id: displayUnsubscribeFooterId,
            }),
          } as const;

          setContent([
            LayoutColumnContentItem,
            DisplayUnsubscribeFooterContentItem,
          ]);
        } else {
          setContent([]);
        }
      }
    }
  }, [contentMode]);

  // Save the form to database
  const handleSaveContent = async (contentData, subject, isSaveOnly) => {
    try {
      setSubject(subject);
      const outputVariablesForContentData = getOutputVariablesForContentData(
        contentData,
        contentCreationMap,
      );
      const saveContentResult = await saveContent({
        subject,
        contentId,
        contentType,
        contentData,
        inputVariables,
        outputVariables:
          outputVariablesForContentData && outputVariablesForContentData.length
            ? outputVariablesForContentData
            : [],
      });

      setLastSavedDate(new Date(saveContentResult.createdTime));

      if (onSaveContent) {
        onSaveContent({ ...saveContentResult, isSaveOnly });
      }

      // Implicitly publish the saved content
      if (publish) {
        const saveContentPublicationResult = await saveContentPublication({
          contentId: saveContentResult.contentId,
        });
        if (onPublish) {
          onPublish(saveContentPublicationResult);
        }
      }

      if (activate) {
        const saveActivationResult = await saveActivation({
          contentId: saveContentResult.contentId,
          inputValues,
        });

        const theFullViewerUrl = `${apis.viewerUrl}/${saveActivationResult.activationId}`;
        console.info(`Your form URL: ${theFullViewerUrl}`);
        if (onActivation) {
          onActivation({
            ...saveActivationResult,
            url: theFullViewerUrl,
          });
        }

        setContent(saveContentResult.contentData);
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="main-entry-builder">
      <TrackingEventsContextProvider>
        <ContentBuilder
          apis={apis}
          contentMode={contentMode}
          modalHeaderChildren={modalHeaderChildren}
          contentCreationMap={contentCreationMap}
          inputVariables={inputVariables}
          variableGroups={inputVariableGroups}
          initialContent={content}
          initialSubject={subject}
          onSaveContent={handleSaveContent}
          isLoading={isLoading || !isContentFetched}
          isSaving={isSaving}
          deleteContentHandler={deleteContentHandler}
          backButtonHandler={backButtonHandler}
          lastSavedDate={lastSavedDate}
          enablePreviewButtons={enablePreviewButtons}
        />
      </TrackingEventsContextProvider>
    </div>
  );
};
