import { useCallback, useContext } from 'react';

import { IconButton, Divider } from '@whispir/ui-lib-v2';
import { KnownContentTypeAndVersionMap } from '../../../../content/schemas/KnownContentTypeAndVersionMap';
import {
  AbstractContentCreationItemVersionMap,
  AbstractContentCreationMap,
} from '../../../../framework/ContentCreation';
import {
  AbstractContentTypes,
  AbstractVersionsForItemTypes,
  AbstractRecords,
} from '../../../../framework/ContentDefinitions';
import {
  migrateContentItem,
  isOutOfDate,
} from '../../../../framework/functions';
import { ApiLinkedContentBuilderProps } from '../../../../mainEntryPoints/Builder';
import { useEventListener } from '../../../../hooks';
import { ModeContext } from '../../../Contexts/ModeProvider';
import { ControlPanelProps } from '../../../../framework/ControlPanelProps';
import { DRAGGABLE_TYPES } from '../../utils/constants';
import { ContentPropertiesSubPanel } from '../ContentPropertiesSubPanel';
import SideBarItem from '../SideBarItem';
import * as S from './styles';

type ContentGroup = 'Form' | 'Display' | 'Test' | 'Layouts';

// This is a filter mapping for the content creation item prefix
const contentModeFilterMapping: Record<
  ApiLinkedContentBuilderProps['contentMode'],
  Array<ContentGroup>
> = {
  test: ['Layouts', 'Form', 'Display', 'Test'],
  form: ['Layouts', 'Form', 'Display'],
  web: ['Layouts', 'Display'],
  email: ['Layouts', 'Display'],
};

// This is a mapping to determine how the content is grouped in the control panel.
export const contentGroups: Record<
  ContentGroup,
  Array<keyof KnownContentTypeAndVersionMap>
> = {
  Layouts: ['layout-column'],
  Display: [
    'display-image',
    'display-button',
    'display-text',
    'display-divider',
    'display-calendar',
    'display-map',
    'display-video-player',
    'display-social-icons',
    'display-custom-code',
  ],
  Form: [
    'form-text-input',
    'form-text-area',
    'form-radio-list',
    'form-checkbox-list',
    'form-date-input',
    'form-dropdown',
    'form-yes-no-buttons',
    'form-submit-button',
    'form-slider',
  ],
  Test: [
    'display-unsubscribe-footer',
    'form-location',
    'form-ratings',
    'checkbox',
    'heading',
    'link',
    'paragraph',
    'display-button-group',
    'display-article',
    'display-footer',
    'display-banner',
    'test',
  ],
};

// Given the cci map - put them into the right groups.
const groupContentCreationItems = <T extends AbstractRecords>(
  contentCreationMap: AbstractContentCreationMap<T>,
  contentMode: ApiLinkedContentBuilderProps['contentMode'],
  enableCustomCodeInEmail?: boolean,
): Record<
  ContentGroup,
  Array<AbstractContentCreationItemVersionMap<T, any>>
> => {
  const contentCreationItems = Object.values(contentCreationMap) as Array<
    AbstractContentCreationItemVersionMap<T, any>
  >;

  // Get the allowed Content Groups for the given Content Mode
  const allowedContentGroups = contentModeFilterMapping[contentMode];

  const groups = Object.entries(contentGroups).reduce(
    (acc, [contentGroup, contentCreationItemTypes]) => {
      // Filter allowed Content Groups
      if (allowedContentGroups.includes(contentGroup as ContentGroup)) {
        // Filter the Content Creation items for the given Content Group
        const groupContentCreationItems = contentCreationItems.filter((cci) =>
          contentCreationItemTypes.includes(cci.type),
        );

        // Remove custom code from email
        // TODO: another approach should be considered if more content items that are in only one of email or web are added.
        const customCodeKey = 'display-custom-code' as const;
        let filteredGroupContentCreationItems = groupContentCreationItems;
        if (!enableCustomCodeInEmail && contentMode === 'email') {
          filteredGroupContentCreationItems = groupContentCreationItems.filter(
            (item) => item.type !== customCodeKey,
          );
        }

        const sortedContentCreationItems = filteredGroupContentCreationItems.sort(
          (cciA, cciB) => cciA.label.localeCompare(cciB.label),
        );

        return {
          ...acc,
          [contentGroup]: sortedContentCreationItems,
        };
      } else {
        return acc;
      }
    },
    {} as Record<
      ContentGroup,
      Array<AbstractContentCreationItemVersionMap<T, any>>
    >,
  );

  return groups;
};

/**
 * The main Control Panel
 * @param props
 */
export const ControlPanel = <
  T extends AbstractRecords,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
>(
  props: ControlPanelProps<T, K, V>,
) => {
  const {
    contentMap,
    onContentUpdate,
    onAddNewContent,
    selectedContent,
    inputVariables,
    onContentSelect,
    enableCustomCodeInEmail,
    disabledSideBarItems,
  } = props;

  // Event handler utilizing useCallback ...
  // ... so that reference never changes.
  const keydownHandler = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        onContentSelect(null);
      }
    },
    [onContentSelect],
  );
  // Add event listener using our hook
  useEventListener('keydown', keydownHandler);

  const contentItemIsOutOfDate =
    selectedContent && isOutOfDate(contentMap, selectedContent);

  const handleUpdateContentClick = () => {
    if (selectedContent) {
      const newContentItem = migrateContentItem(contentMap, selectedContent);
      onContentUpdate(newContentItem);
    }
  };

  const handleClickAway = () => {
    onContentSelect(null);
  };

  const { contentMode } = useContext(ModeContext);
  const groupedContentCreationItems = groupContentCreationItems(
    contentMap,
    contentMode,
    enableCustomCodeInEmail,
  );

  return (
    <S.StyleControlPanel
      className="control-panel"
      role="region"
      aria-label="Control Panel"
    >
      {selectedContent && (
        <S.ControlPanelHeader>
          <S.ControlPanelHeaderTitle>
            <IconButton
              ariaLabel="control panel close"
              size="small"
              icon="ChevronLeft"
              onClick={handleClickAway}
            />
            <h3>{contentMap[selectedContent.type].label}</h3>
          </S.ControlPanelHeaderTitle>
          <Divider dividerType="solid" isHorizontal className="divider" />
        </S.ControlPanelHeader>
      )}
      <div className="control-panel-contents">
        {!selectedContent && (
          <div className="content-group-wrapper">
            {Object.entries(groupedContentCreationItems).map(
              ([contentGroup, contentCreationItems]) => {
                return (
                  <div
                    className="content-group"
                    role="list"
                    aria-label={`${contentGroup} content group`}
                    key={contentGroup}
                  >
                    <h4 className="content-group-label">{contentGroup}</h4>
                    {contentCreationItems.map((cci) => {
                      const isDisabled = disabledSideBarItems?.includes(
                        cci.type,
                      );
                      return (
                        <SideBarItem
                          ariaLabel={`${cci.label} drag handle`}
                          key={cci.type + cci.label}
                          label={cci.label}
                          icon={cci.icon}
                          layoutReference={{
                            layoutType: DRAGGABLE_TYPES.SIDEBAR,
                            contentType: cci.type,
                          }}
                          onClick={() => {
                            onAddNewContent(cci.type);
                          }}
                          isDisabled={isDisabled}
                        />
                      );
                    })}
                  </div>
                );
              },
            )}
          </div>
        )}
        {selectedContent && (
          <>
            {contentItemIsOutOfDate && (
              <div>
                <strong> this item is out of date, update it? </strong>
                <button onClick={handleUpdateContentClick}>
                  update to most recent version{' '}
                </button>
              </div>
            )}
            <ContentPropertiesSubPanel
              contentMap={contentMap}
              contentItem={selectedContent}
              onContentUpdate={onContentUpdate}
              inputVariables={inputVariables}
            />
          </>
        )}
      </div>
    </S.StyleControlPanel>
  );
};
