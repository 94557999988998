import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { useLoading } from './useLoading';

export const useGetActivation = ({ api }: { api: AxiosInstance }) => {
  const getActivation = useCallback(
    async (body) => {
      const { data } = await api.get(`/activations/${body.activationId}`);

      return data;
    },
    [api],
  );

  return useLoading(getActivation);
};
