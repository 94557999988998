import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import {
  StyledNumericSliderWrapper,
  StyledSliderLabelWrapper,
  NumericSliderProps,
  StyledSlider,
} from './NumericSlider.styles';

const NUMBER_OF_MARKS = 11;
const MIN_RANGE = 0;
const MAX_RANGE = 10;

export type SliderMark = {
  value: number;
  label: string;
};

export const getSliderMarks = () => {
  return Array.from(Array(NUMBER_OF_MARKS).keys()).map((mark: number) => ({
    label: `${mark}`,
    value: mark,
  }));
};

export const NumericSlider = (props: NumericSliderProps): JSX.Element => {
  const {
    defaultValue,
    sliderColor,
    showLabels,
    leftLabel,
    centerLabel,
    rightLabel,
    helperText,
    onChange,
    barColor,
  } = props;

  const [value, setValue] = useState(defaultValue);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => {
    setValue(value as number);
    onChange(value as number);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <StyledNumericSliderWrapper>
      <StyledSlider
        marks={getSliderMarks()}
        min={MIN_RANGE}
        max={MAX_RANGE}
        onChange={handleChange}
        barColor={barColor}
        sliderColor={sliderColor}
        defaultValue={defaultValue}
        value={value}
      />
      <StyledSliderLabelWrapper>
        {showLabels && (
          <>
            <Typography variant="caption" style={{ alignSelf: 'start' }}>
              {leftLabel}
            </Typography>
            <Typography variant="caption" style={{ alignSelf: 'center' }}>
              {centerLabel}
            </Typography>
            <Typography variant="caption" style={{ alignSelf: 'end' }}>
              {rightLabel}
            </Typography>
          </>
        )}
      </StyledSliderLabelWrapper>
      {helperText && <Typography variant="subtitle1">{helperText}</Typography>}
    </StyledNumericSliderWrapper>
  );
};
