import React, { useState, useEffect, ReactNode } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  Spinner,
  AutoSaveDialog,
  ContentPreviewButton,
} from '@whispir/ui-lib-v2';
import { VariableGroup, Variable } from '@whispir/variables';
import { AbstractContentCreationMap } from '../framework/ContentCreation';
import {
  AbstractAllContentDataAsArray,
  AbstractDefaultedData,
  AbstractRecords,
} from '../framework/ContentDefinitions';
import { ApiLinkedContentBuilderProps } from '../mainEntryPoints/Builder';
import { ModeProvider } from './Contexts/ModeProvider';
import { ApisProvider, ApisProviderProps } from './Contexts/ApisProvider';
import { VariableProvider } from './Contexts/VariableProvider';
import { Subject } from './ViewPanels/Subject/Subject';
import {
  ContentPreviewButtonsEvents,
  useTrackingEvents,
} from './Contexts/TrackingEventsProvider';
import {
  ExitConfirmationBodyModal,
  ExitConfirmationTitleModal,
} from './Other/ExitConfirmationModal/ExitConfirmationModal';

// ReactDnD imports
import ContentBuilderLayout from './ReactDnD/ContentBuilderLayout';
import {
  transformContentToLoad,
  transformContentToSave,
} from './ReactDnD/utils/transformers';
import { LayoutBundle } from './ReactDnD/utils/types';
import { PreviewContent } from './ReactDnD/PreviewContent';
import { StyledHeaderActions } from './ContentBuilder.styles';

const MOBILE_WIDTH = 344;
const DESKTOP_WIDTH = 1072;

export type ContentBuilderProps<T extends AbstractRecords> = {
  apis: ApisProviderProps['apis'];
  inputVariables: Array<Variable>;
  variableGroups?: VariableGroup[];
  initialContent: AbstractAllContentDataAsArray<T>;
  initialSubject?: string;
  onSaveContent: (
    content: AbstractAllContentDataAsArray<T>,
    subject: string,
    isSaveOnly: boolean,
  ) => Promise<void>;
  contentCreationMap: AbstractContentCreationMap<T>;
  modalHeaderChildren?: ReactNode;
  contentMode?: ApiLinkedContentBuilderProps['contentMode'];
  isLoading?: boolean;
  isSaving?: boolean;
  deleteContentHandler?: {
    //If this callback returns content the modal will be displayed, if it returns null,  the delete will go ahead automatically
    getConfirmationModalContent: (
      contentItem: AbstractDefaultedData<T>,
      affectedVariables: Array<Variable>,
    ) => React.ReactElement | null;
    onContinue: (
      contentItem: AbstractDefaultedData<T>,
      affectedVariables: Array<Variable>,
    ) => void;
    onCancel: (
      contentItem: AbstractDefaultedData<T>,
      affectedVariables: Array<Variable>,
    ) => void;

    cancelText?: string;
    confirmText?: string;
    modalHeader?: React.ReactElement;
  };
  backButtonHandler?: {
    buttonText?: string;
    onButtonClick: () => void;
  };
  lastSavedDate?: Date;
  enablePreviewButtons?: boolean;
};

export const ContentBuilder = <T extends AbstractRecords>(
  props: ContentBuilderProps<T>,
) => {
  const {
    apis,
    inputVariables,
    initialContent,
    initialSubject = 'Enter subject here',
    variableGroups = [],
    onSaveContent,
    contentCreationMap,
    modalHeaderChildren,
    contentMode = 'test',
    isLoading = false,
    isSaving = false,
    deleteContentHandler,
    backButtonHandler,
    lastSavedDate,
    enablePreviewButtons = false,
  } = props;

  const [layoutContent, setLayoutContent] = useState<LayoutBundle>({
    rows: [],
    contentMap: {},
  });

  const [subject, setSubject] = useState(initialSubject);
  const [isValidSubject, setIsValidSubject] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState<{ [s: string]: string } | null>(null);
  const [backButtonModal, setBackButtonModal] = useState(false);

  const {
    trackContentBuilderOpened,
    trackContentBuilderExitWithoutSave,
    trackContentBuilderSaveAndCloseClicked,
  } = useTrackingEvents();

  useEffect(() => {
    const { rows, contentMap } = transformContentToLoad(initialContent);

    setLayoutContent({
      contentMap,
      rows,
    });
  }, [initialContent, contentMode]);

  useEffect(() => {
    const transformedContent = transformContentToSave(
      layoutContent,
    ) as AbstractAllContentDataAsArray<T>;

    if (
      (!isLoading &&
        JSON.stringify(transformedContent) !==
          JSON.stringify(initialContent)) ||
      subject !== initialSubject
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [initialContent, initialSubject, isLoading, subject, layoutContent]);

  useEffect(() => {
    trackContentBuilderOpened();
  }, [trackContentBuilderOpened]);

  const handleSaveClick = async (isSaveOnly = false) => {
    try {
      trackContentBuilderSaveAndCloseClicked();
      // TODO: Clean this typing up and renaming variables up
      const { rows, contentMap } = layoutContent;
      const transformedContent = transformContentToSave({
        rows,
        contentMap,
      }) as AbstractAllContentDataAsArray<T>;

      await onSaveContent(transformedContent, subject, isSaveOnly);
      setIsDirty(false);
      setError(null);
    } catch (err) {
      //@ts-ignore - this is annoying,  typeguards should work here
      //https://github.com/microsoft/TypeScript/issues/25720
      if (err && err.data && err.data.errors) {
        //@ts-ignore
        setError(err.data.errors);
      }
    }
  };

  const handleBackButtonClick = () => {
    if (isDirty) {
      setBackButtonModal(true);
    } else {
      trackContentBuilderExitWithoutSave();
      backButtonHandler?.onButtonClick();
    }
  };
  const {
    trackContentPreviewButtonClicked,
    trackContentPreviewTabClicked,
    trackContentPreviewExitButtonClicked,
  } = useTrackingEvents();

  const trackingData: Omit<ContentPreviewButtonsEvents, 'previewMode'> = {
    subject,
    contentMode,
    contentMap: layoutContent.contentMap,
  };

  // NB. The context providers here is looking a little unwieldy
  // It would be nicer to move these up a level and separate the area in which context is provided
  // And the area where we are doing business logic
  return (
    <ApisProvider apis={apis}>
      <ModeProvider variableMode="builder" contentMode={contentMode}>
        <VariableProvider
          variableGroups={variableGroups}
          inputVariables={inputVariables}
        >
          <div className="content-builder">
            {backButtonModal && (
              <Modal
                headerContent={
                  <ExitConfirmationTitleModal>
                    Unsaved Changes
                  </ExitConfirmationTitleModal>
                }
                bodyContent={
                  <ExitConfirmationBodyModal>
                    Please save unsaved changes before exiting.
                  </ExitConfirmationBodyModal>
                }
                isOpen={!!backButtonModal}
                handleExit={() => setBackButtonModal(false)}
                footerContent={
                  <>
                    <ButtonGroup align="end">
                      <Button
                        variant="link"
                        text="Discard Changes"
                        size="medium"
                        onClick={() => {
                          trackContentBuilderExitWithoutSave();
                          backButtonHandler?.onButtonClick();
                        }}
                      />
                      {isSaving ? (
                        <>
                          <div className="spinner-wrapper">
                            <Spinner size="small" />
                          </div>
                          Saving
                        </>
                      ) : (
                        <Button
                          type="primary"
                          text="Save & Exit"
                          size="medium"
                          // set isSaveOnly to false for the modal to close
                          onClick={() => handleSaveClick(false)}
                        />
                      )}
                    </ButtonGroup>
                  </>
                }
              />
            )}
            <ModalHeader
              showLogo={false}
              backButtonText={backButtonHandler?.buttonText}
              onBackClick={handleBackButtonClick}
              title={modalHeaderChildren}
              // set isSaveOnly to false for the modal to close
              onCloseClick={() => handleSaveClick(false)}
              closeButtonDisabled={isLoading || !isValidSubject}
              actions={
                <StyledHeaderActions>
                  <AutoSaveDialog
                    isSaving={isSaving}
                    onSaveClick={handleSaveClick}
                    lastSavedDate={lastSavedDate}
                    hasUnsavedChanges={!isLoading && isDirty}
                  />
                  {enablePreviewButtons && (
                    <ContentPreviewButton
                      subject={subject}
                      contentMode={contentMode === 'email' ? 'email' : 'web'}
                      mobileWidth={MOBILE_WIDTH}
                      desktopWidth={DESKTOP_WIDTH}
                      mobileContent={
                        <PreviewContent
                          contentCreationMap={contentCreationMap}
                          contentMode={contentMode}
                          layoutBundle={layoutContent}
                          mobileWidth={MOBILE_WIDTH}
                        />
                      }
                      desktopContent={
                        <PreviewContent
                          contentCreationMap={contentCreationMap}
                          contentMode={contentMode}
                          layoutBundle={layoutContent}
                        />
                      }
                      onTabClicked={(previewMode) => {
                        trackContentPreviewTabClicked({
                          ...trackingData,
                          previewMode,
                        });
                      }}
                      onButtonClicked={() => {
                        trackContentPreviewButtonClicked({
                          ...trackingData,
                        });
                      }}
                      onExitButtonClicked={() => {
                        trackContentPreviewExitButtonClicked({
                          ...trackingData,
                        });
                      }}
                    />
                  )}
                </StyledHeaderActions>
              }
              closeButtonText="Continue"
            />
            {process.env.REACT_APP_DEV_MODE && error && (
              <Alert
                type="banner"
                alert="error"
                // Just display the first error
                content={Object.values(error)[0]}
                onClose={() => {
                  setError(null);
                }}
              />
            )}
            <div className="content-builder-body">
              {isLoading ? (
                <div className="spinner-wrapper">
                  <Spinner />
                </div>
              ) : (
                <ContentBuilderLayout
                  contentCreationMap={contentCreationMap}
                  contentMode={contentMode}
                  inputVariables={inputVariables}
                  onContentChange={setLayoutContent}
                  layoutContent={layoutContent}
                  subject={subject}
                  enablePreviewButtons={enablePreviewButtons}
                  deleteContentHandler={deleteContentHandler}
                >
                  <Subject
                    onChange={(subject, isValidSubject) => {
                      setSubject(subject);
                      setIsValidSubject(isValidSubject);
                    }}
                    defaultValue={initialSubject}
                    inputVariables={inputVariables}
                    shouldDisplaySubjectFieldRequired={!!error?.subject}
                  />
                </ContentBuilderLayout>
              )}
            </div>
          </div>
        </VariableProvider>
      </ModeProvider>
    </ApisProvider>
  );
};
