import {
  PreviewModes,
  SegmentContextProvider,
  useSegmentContext,
} from '@whispir/ui-lib-v2';
import React, { useRef } from 'react';
import { KnownContentTypeAndVersionMap } from '../../content/schemas/KnownContentTypeAndVersionMap';
import { AbstractRecords, ApiLinkedContentBuilderProps } from '../../export';
import { AbstractControlsDataMap } from '../../framework/Controls';
import { LayoutBundle } from '../ReactDnD/utils/types';

/** NOTE
 *
 * I'm really not enjoying manually defining the events here.
 * TypeWriter is the way to go
 * But we're pending deciding how we are going to structure it, and we're waiting for ventifact to come in
 * So I'm just doing it manually for now.
 */

type ContentCreatedEvent = {
  contentType: keyof KnownContentTypeAndVersionMap;
  createdWithinLayout?: boolean;
};

export type ContentPreviewButtonsEvents = {
  subject: string;
  contentMode: ApiLinkedContentBuilderProps['contentMode'];
  contentMap: LayoutBundle['contentMap'];
  previewMode?: PreviewModes;
};

type ContentDeletedEvent = {
  contentType: keyof KnownContentTypeAndVersionMap;
  containedComponents?: Array<KnownContentTypeAndVersionMap>;
};

type ContentDuplicatedEvent = {
  contentType: keyof KnownContentTypeAndVersionMap;
  containedComponents?: Array<KnownContentTypeAndVersionMap>;
};

type ContentMovedEvent = {
  contentType: keyof KnownContentTypeAndVersionMap;
  containedComponents?: Array<KnownContentTypeAndVersionMap>;
};

type ContentUpdatedEvent = {
  contentType: keyof KnownContentTypeAndVersionMap;
  contentData: unknown;
};

type ControlComponentInteractionEvent<
  T extends AbstractRecords = AbstractRecords,
  TControlsMap extends AbstractControlsDataMap = AbstractControlsDataMap
> = {
  contentType: keyof T;
  controlKey: string;
  controlType: keyof TControlsMap;
  controlValue?: string;
};

type TrackingEvents = {
  trackContentCreatedEvent: (data: ContentCreatedEvent) => void;
  trackContentDeletedEvent: (data: ContentDeletedEvent) => void;
  trackContentDuplicatedEvent: (data: ContentDuplicatedEvent) => void;
  trackContentMovedEvent: (data: ContentMovedEvent) => void;
  trackContentUpdatedEvent: (data: ContentUpdatedEvent) => void;

  trackContentBuilderOpened: () => void;
  trackContentBuilderSaveAndCloseClicked: () => void;
  trackContentBuilderExitWithoutSave: () => void;

  trackContentPreviewButtonClicked: (data: ContentPreviewButtonsEvents) => void;
  trackContentPreviewTabClicked: (data: ContentPreviewButtonsEvents) => void;
  trackContentPreviewExitButtonClicked: (
    data: Omit<ContentPreviewButtonsEvents, 'previewMode'>,
  ) => void;

  trackControlComponentInteraction: (
    data: ControlComponentInteractionEvent,
  ) => void;
};

const TrackingEventsContext = React.createContext<TrackingEvents>({
  trackContentCreatedEvent: () => undefined,
  trackContentDeletedEvent: () => undefined,
  trackContentDuplicatedEvent: () => undefined,
  trackContentMovedEvent: () => undefined,
  trackContentUpdatedEvent: () => undefined,
  trackContentBuilderOpened: () => undefined,
  trackContentBuilderSaveAndCloseClicked: () => undefined,
  trackContentBuilderExitWithoutSave: () => undefined,
  trackControlComponentInteraction: () => undefined,
  trackContentPreviewButtonClicked: () => undefined,
  trackContentPreviewTabClicked: () => undefined,
  trackContentPreviewExitButtonClicked: () => undefined,
});

export const TrackingEventsContextProvider = (
  props: React.PropsWithChildren<Partial<TrackingEvents>>,
) => {
  const { children, ...rest } = props;

  const { trackEvent } = useSegmentContext();

  // The reason for using a ref
  // Is so that React doesn't think we are changing the functions, and causing effects down the line to refire.
  const eventsRef = useRef<TrackingEvents>({
    trackContentCreatedEvent: (data) => {
      trackEvent({
        event: 'Component Added',
        properties: {
          componentName: data.contentType,
          createdWithinLayout: data.createdWithinLayout,
          contentBuilderVersion: 'v2',
        },
      });
    },

    trackContentDeletedEvent: (data) => {
      trackEvent({
        event: 'Component Deleted',
        properties: {
          componentName: data.contentType,
          containedComponents: data.containedComponents,
          contentBuilderVersion: 'v2',
        },
      });
    },

    trackContentDuplicatedEvent: (data) => {
      trackEvent({
        event: 'Content Duplicated',
        properties: {
          componentName: data.contentType,
          containedComponents: data.containedComponents,
          contentBuilderVersion: 'v2',
        },
      });
    },

    trackContentMovedEvent: (data) => {
      trackEvent({
        event: 'Content Moved',
        properties: {
          componentName: data.contentType,
          containedComponents: data.containedComponents,
          contentBuilderVersion: 'v2',
        },
      });
    },

    trackContentUpdatedEvent: (data) => {
      trackEvent({
        event: 'Content Updated',
        properties: {
          componentName: data.contentType,
          contentData: data.contentData,
          contentBuilderVersion: 'v2',
        },
      });
    },

    trackContentBuilderOpened: () => {
      trackEvent({
        event: 'ContentBuilderOpened',
      });
    },

    trackContentBuilderSaveAndCloseClicked: () => {
      trackEvent({
        event: 'ContentBuilderSaveAndCloseClick',
      });
    },

    trackContentBuilderExitWithoutSave: () => {
      trackEvent({
        event: 'ContentBuilderExitWithoutSave',
      });
    },

    trackContentPreviewButtonClicked: (data) => {
      trackEvent({
        event: 'Content Preview Button Clicked',
        properties: {
          previewMode: data.previewMode,
          contentMap: data.contentMap,
          contentMode: data.contentMode,
          subject: data.subject,
          contentBuilderVersion: 'v2',
        },
      });
    },
    trackContentPreviewTabClicked: (data) => {
      trackEvent({
        event: 'Content Preview Tab Clicked',
        properties: {
          previewMode: data.previewMode,
          contentMap: data.contentMap,
          contentMode: data.contentMode,
          subject: data.subject,
          contentBuilderVersion: 'v2',
        },
      });
    },
    trackContentPreviewExitButtonClicked: (data) => {
      trackEvent({
        event: 'Content Preview Exit Button Clicked',
        properties: {
          contentMap: data.contentMap,
          contentMode: data.contentMode,
          subject: data.subject,
          contentBuilderVersion: 'v2',
        },
      });
    },

    trackControlComponentInteraction: (data) => {
      trackEvent({
        event: 'ContentBuilderControlComponentInteractedWith',
        properties: data,
      });
    },
  });

  return (
    <SegmentContextProvider>
      <TrackingEventsContext.Provider
        value={{
          ...eventsRef.current,
          ...rest,
        }}
      >
        {children}
      </TrackingEventsContext.Provider>
    </SegmentContextProvider>
  );
};

export const useTrackingEvents = (): TrackingEvents => {
  const context = React.useContext(TrackingEventsContext);
  return context;
};
