import styled from 'styled-components';
import { Fonts, Colors, TextField } from '@whispir/ui-lib-v2';

export const StyledControlGroupLabel = styled.p`
  ${Fonts.capsHeading};
`;

const applyLayout = (variant?: string) => {
  if (variant === 'grid') {
    return `
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto;
      grid-gap: 16px 32px;

      .property-control {
        margin: 0px;
      }
    `;
  }
  return ``;
};
export const StyledWrapper = styled.div<{ variant?: string }>`
  ${(props) => applyLayout(props.variant)}
`;

export const StyledControlGroupSubLabel = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 4px;
`;

export const ButtonGroupSingleSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(144px, 1fr));
  grid-gap: 1rem;
`;

export const ButtonGroupSingleSelectButton = styled.button<{
  isSelected: boolean;
}>`
  padding: 1rem;
  border: 1px solid
    ${(p) => (p.isSelected ? Colors.accent_5 : Colors.grey_blue_2)};
  border-radius: 0.3rem;
  width: 100%;
  max-width: 150px;
  background-color: ${Colors.white};
  cursor: pointer;
  transition: border 300ms ease-in-out;
`;

export const ButtonGroupSingleSelectLabel = styled.div`
  width: 100%;
  text-align: initial;
  margin-bottom: 0.75rem;
`;

export const ButtonGroupSingleSelectBar = styled.div<{ columns: number }>`
  height: 24px;
  width: 100%;
  background-color: ${Colors.background};
  border: 1px solid ${Colors.grey_blue_2};
  border-radius: 0.3rem;
  display: grid;
  grid-template-columns: repeat(${(p) => p.columns}, 1fr);
`;

export const ButtonGroupSingleSelectBarColumn = styled.div`
  height: 24px;
  width: 100%;
  border-right: 1px solid ${Colors.grey_blue_2};

  &:last-child {
    border-right: none;
  }
`;

export const StyledTextArea = styled(TextField)`
  .MuiFilledInput-multiline {
    padding: 0;
  }

  overflow: 'none';
`;

export const AlertContainer = styled.div`
  & > div {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
