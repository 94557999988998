import { contentCreationMap } from '../../../content/content';
import {
  AbstractAllContentDataAsArray,
  AbstractRecords,
} from '../../../export';
import { AnyContentItem } from '../../../framework/AnyContentItem';
import { createNewContent } from '../../../framework/functions';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { DRAGGABLE_TYPES } from './constants';
import {
  createNewColumnReference,
  createNewComponentReference,
  createNewRowReference,
} from './helpers';
import { isFormContentItem, isLayoutContentItem, LayoutBundle } from './types';

export const transformContentToSave = <T extends AbstractRecords>({
  rows,
  contentMap,
}: LayoutBundle): AbstractAllContentDataAsArray<T> => {
  const content = rows.map((row) => {
    const { data, ...meta } = contentMap[row.contentId];

    return {
      ...meta,
      data: {
        ...data,
        components: row.children.map(({ children }) =>
          children.map((componentItem) => contentMap[componentItem.contentId]),
        ),
      },
    };
  });

  return content;
};

/**
 * Needs a comment
 *
 * What does this thing do?
 *
 * Separates the content array in to Layout containers, and actual content items
 *
 * // TODO possibly also wrap the legacy items in Layout containers.
 *
 * @param content
 * @returns
 */

export const transformContentToLoad = <T extends AbstractRecords>(
  content: AbstractAllContentDataAsArray<T>,
): LayoutBundle => {
  const contentMap = {} as Record<string, AnyContentItem>;

  const rows = content.map((layoutContentItem) => {
    contentMap[layoutContentItem.id] = layoutContentItem;

    if (!isLayoutContentItem(layoutContentItem)) {
      // only content item that doesn't have backgroundColor is custom code
      const backgroundColor =
        layoutContentItem.data['backgroundColor'] ||
        COMMON_DEFAULT_DATA.backgroundColor;

      // Create new row and add it to contentMap
      const newRowContentItem = createNewContent({
        contentKey: 'layout-column',
        contentCreationMap,
      });

      newRowContentItem.data = {
        ...newRowContentItem.data,
        backgroundColor,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        columnType: isFormContentItem(layoutContentItem) ? 'Form' : '1',
      };

      if (layoutContentItem.data['backgroundColor'])
        layoutContentItem.data['backgroundColor'] =
          COMMON_DEFAULT_DATA.backgroundColor;

      // Add component to contentMap
      contentMap[layoutContentItem.id] = layoutContentItem;

      contentMap[newRowContentItem.id] = newRowContentItem;

      return createNewRowReference(newRowContentItem.id, [
        createNewColumnReference([
          createNewComponentReference(layoutContentItem),
        ]),
      ]);
    }

    const {
      data: { components },
      ...rest
    } = layoutContentItem;

    return {
      layoutType: DRAGGABLE_TYPES.ROW,
      contentId: rest.id,
      children: components.map((column) => {
        return {
          layoutType: DRAGGABLE_TYPES.COLUMN,
          children: column.map((contentItem) => {
            contentMap[contentItem.id] = contentItem;
            return {
              layoutType: DRAGGABLE_TYPES.COMPONENT,
              contentId: contentItem.id,
            };
          }),
        };
      }),
    };
  });

  return { rows, contentMap };
};
