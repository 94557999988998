import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isOutOfDate: boolean }>`
  ${(props) =>
    props.isOutOfDate &&
    css`
      &::after {
        content: '';
        outline: solid 2px red;
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        height: 10px;
        border-radius: 100%;
      }
    `}
`;
