import { useState, ReactNode, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Variable } from '@whispir/variables';
import { Button, ButtonGroup, Modal } from '@whispir/ui-lib-v2';
import { createNewContent } from '../../../framework/functions';
import { ApiLinkedContentBuilderProps } from '../../../mainEntryPoints/Builder';
import {
  AbstractContentTypes,
  AbstractDefaultedData,
  AbstractRecords,
} from '../../../framework/ContentDefinitions';
import { AbstractContentCreationMap } from '../../../framework/ContentCreation';
import { AnyContentItem } from '../../../framework/AnyContentItem';
import { useTrackingEvents } from '../../Contexts/TrackingEventsProvider';
import BuilderRow from '../Row';
import {
  handleRemoveItemFromLayout,
  handleDuplicateItemInLayout,
  handleDrop as handleMove,
  addContentIntoArray,
  handleRowColumnChange,
  getContentItemsWithinLayoutColumn,
} from '../utils/helpers';
import {
  HandleDropFunction,
  LayoutBundle,
  isSidebarReference,
  isLayoutContentItem,
} from '../utils/types';
import { ControlPanel } from '../ControlPanelComponents/ControlPanel';
import DropZoneWrapper from '../DropZoneWrapper';
import EndDropZone from '../EndDropZone';
import DragPreview from '../DragPreview';
import { DRAGGABLE_TYPES, SINGULAR_COMPONENTS } from '../utils/constants';
import { getOutputVariablesForContentData } from '../../../framework/functions';
import * as S from './styles';

type ContentBuilderLayoutProps<T extends AbstractRecords> = {
  children: ReactNode;
  contentCreationMap: AbstractContentCreationMap<T>;
  inputVariables: Array<Variable>;
  onContentChange: (params: LayoutBundle) => void;
  layoutContent: LayoutBundle;
  contentMode: ApiLinkedContentBuilderProps['contentMode'];
  subject: string;
  enablePreviewButtons?: boolean;
  deleteContentHandler?: {
    //If this callback returns content the modal will be displayed, if it returns null,  the delete will go ahead automatically
    getConfirmationModalContent: (
      contentItem: AbstractDefaultedData<T>,
      affectedVariables: Array<Variable>,
    ) => React.ReactElement | null;
    onContinue: (
      contentItem: AbstractDefaultedData<T>,
      affectedVariables: Array<Variable>,
    ) => void;
    onCancel: (
      contentItem: AbstractDefaultedData<T>,
      affectedVariables: Array<Variable>,
    ) => void;

    cancelText?: string;
    confirmText?: string;
    modalHeader?: React.ReactElement;
  };
};

const ContentBuilderLayout = <T extends AbstractRecords>({
  contentCreationMap,
  children,
  inputVariables,
  onContentChange,
  layoutContent,
  contentMode,
  subject,
  enablePreviewButtons,
  deleteContentHandler,
}: ContentBuilderLayoutProps<T>) => {
  const [layoutArray, setLayoutArray] = useState<LayoutBundle['rows']>(
    layoutContent.rows,
  );
  const [componentsMap, setComponentsMap] = useState<
    LayoutBundle['contentMap']
  >(layoutContent.contentMap);

  const {
    trackContentCreatedEvent,
    trackContentDeletedEvent,
    trackContentDuplicatedEvent,
    trackContentUpdatedEvent,
  } = useTrackingEvents();

  useEffect(() => {
    onContentChange({ rows: layoutArray, contentMap: componentsMap });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutArray, componentsMap]);

  const [selectedItem, setSelectedItem] = useState<AnyContentItem | null>(null);

  // Update component properties (including layout containers) via the components map
  const handleContentUpdate = (contentItem: AnyContentItem) => {
    setComponentsMap((currentComponents) => ({
      ...currentComponents,
      [contentItem.id]: contentItem,
    }));
    setSelectedItem(contentItem);

    if (isLayoutContentItem(contentItem)) {
      const commonLayoutData = contentItem.data;
      // get path of contentItem (row) in layoutArray
      const rowIndex = layoutArray.findIndex((row) => {
        return row.contentId === contentItem.id;
      });

      if (rowIndex >= 0) {
        // add/remove column(s) at row
        const rows = handleRowColumnChange(
          layoutArray,
          commonLayoutData.columnType,
          rowIndex,
        );

        setLayoutArray(rows);
        // Only track custom code component for now
        if ((contentItem.type as any) === 'display-custom-code') {
          trackContentUpdatedEvent({
            contentType: contentItem.type,
            contentData: {
              codeEditor: {
                css: contentItem.data['codeEditor'].css,
                html: contentItem.data['codeEditor'].html,
              },
            },
          });
        }
      }
    }
  };

  const handleCreateNewContent = (contentKey: AbstractContentTypes<T>) =>
    createNewContent({ contentKey, contentCreationMap, contentMode });

  const handleAddItemFromSidebar = (
    contentKey: AbstractContentTypes<T>,
    splitDropZonePath?: Array<string>,
  ) => {
    // This is going to prevent Form sections from being able to have more then one display component
    if (
      splitDropZonePath &&
      splitDropZonePath.length > 1 &&
      layoutArray.length > 0 &&
      Object.keys(componentsMap).length > 0
    ) {
      const foundRow =
        componentsMap[layoutArray[splitDropZonePath[0]]?.contentId];

      if (foundRow?.data?.columnType === 'Form') {
        return;
      }
    }

    const newComponent = handleCreateNewContent(contentKey);

    const { rows, contentMap } = addContentIntoArray(
      layoutArray,
      componentsMap,
      newComponent,
      splitDropZonePath,
    );

    setComponentsMap(contentMap);
    setLayoutArray(rows);
    setSelectedItem(newComponent);

    trackContentCreatedEvent({
      contentType: newComponent.type as any,
      createdWithinLayout:
        newComponent.type !== 'layout-column' &&
        // if component path is > 1 it is in a layout column
        splitDropZonePath &&
        splitDropZonePath.length > 1
          ? true
          : false,
    });
  };

  /** Handle any drop, regardless of where it came from */
  const handleDrop: HandleDropFunction = (dropZone, item) => {
    const pathToSplit = dropZone.path.split('-');

    // Move from sidebar into the layout
    if (isSidebarReference(item)) {
      return handleAddItemFromSidebar(item.contentType, pathToSplit);
    }

    const { rows, contentMap } = handleMove(
      layoutArray,
      componentsMap,
      dropZone.path,
      item,
    );

    setComponentsMap(contentMap);
    setLayoutArray(rows);
  };

  const [currentlyDeleting, setCurrentlyDeleting] = useState<{
    contentItem: AbstractDefaultedData<T>;
    splitPath: Array<string>;
    id: string;
  } | null>(null);
  const [
    deleteModalContent,
    setDeleteModalContent,
  ] = useState<React.ReactElement | null>(null);

  const handleDeleteContent = ({ contentItem, splitPath, id }) => {
    const newLayoutBundle = handleRemoveItemFromLayout(
      {
        rows: layoutArray,
        contentMap: componentsMap,
      },
      splitPath,
      id,
    );

    if (!componentsMap[id]) {
      throw new Error("Something has gone wrong, this shouldn't be possible");
    }

    const contentType = contentItem.type;
    const deletedComponents =
      contentType === 'layout-column'
        ? Object.keys(componentsMap)
            .filter(
              (x) =>
                componentsMap[x].type !== 'layout-column' &&
                !Object.keys(newLayoutBundle.contentMap).includes(x),
            )
            .map((x) => componentsMap[x].type)
        : undefined;

    setComponentsMap(newLayoutBundle.contentMap);
    setLayoutArray(newLayoutBundle.rows);

    trackContentDeletedEvent({
      contentType: contentType as any,
      containedComponents: deletedComponents,
    });
  };
  const handleModalDeleteConfirmClick = () => {
    if (!deleteContentHandler) {
      throw new Error("Something has gone wrong, this shouldn't be possible");
    }

    if (!currentlyDeleting) {
      throw new Error("Something has gone wrong, this shouldn't be possible");
    }

    deleteContentHandler.onContinue(
      currentlyDeleting.contentItem,
      getOutputVariablesForContentData(
        [currentlyDeleting.contentItem],
        contentCreationMap,
      ),
    );
    handleDeleteContent(currentlyDeleting);
    setCurrentlyDeleting(null);
    setDeleteModalContent(null);
  };

  const handleModalDeleteCancelClick = () => {
    if (!deleteContentHandler) {
      throw new Error("Something has gone wrong, this shouldn't be possible");
    }

    if (!currentlyDeleting) {
      throw new Error("Something has gone wrong, this shouldn't be possible");
    }

    deleteContentHandler.onCancel(
      currentlyDeleting.contentItem,
      getOutputVariablesForContentData(
        [currentlyDeleting.contentItem],
        contentCreationMap,
      ),
    );
    setCurrentlyDeleting(null);
    setDeleteModalContent(null);
  };

  const disabledSideBarItems = Object.values(componentsMap)
    .filter(({ type }) => SINGULAR_COMPONENTS.includes(type))
    .map(({ type }) => type);

  return (
    <DndProvider
      backend={HTML5Backend}
      options={{ enableTouchEvents: false, enableMouseEvents: true }}
    >
      <DragPreview />
      <S.Wrapper>
        {deleteModalContent && (
          <Modal
            headerContent={deleteContentHandler?.modalHeader}
            bodyContent={deleteModalContent}
            isOpen={!!deleteModalContent}
            handleExit={handleModalDeleteCancelClick}
            footerContent={
              <>
                <ButtonGroup align="end">
                  <Button
                    type="secondary"
                    text={deleteContentHandler?.cancelText || 'Cancel'}
                    size="medium"
                    onClick={handleModalDeleteCancelClick}
                  />

                  <Button
                    type="primary"
                    text={deleteContentHandler?.confirmText || 'Confirm'}
                    size="medium"
                    onClick={handleModalDeleteConfirmClick}
                  />
                </ButtonGroup>
              </>
            }
          />
        )}
        {/* This is the 'canvas' area, with control panel on the right */}
        <div>
          {/* Eg subject will go here */}
          <div>{children}</div>

          <EndDropZone
            data={{
              path: '0',
              childrenCount: layoutArray.length,
            }}
            handleDrop={handleDrop}
            isTop
          />
          <div role="region" aria-label="preview">
            {layoutArray.map((row, index) => {
              const currentPath = `${index}`;
              const nextLayoutPath =
                index === layoutArray.length - 1
                  ? layoutArray.length
                  : index + 1;

              return (
                <DropZoneWrapper
                  key={`${row.contentId}_${index}`}
                  type={DRAGGABLE_TYPES.ROW}
                  dropLineDataArray={[
                    {
                      path: currentPath,
                      childrenCount: layoutArray.length,
                    },
                    {
                      path: `${nextLayoutPath}`,
                      childrenCount: layoutArray.length,
                    },
                  ]}
                  handleDrop={handleDrop}
                  shallow
                >
                  <BuilderRow
                    path={currentPath}
                    contentMap={componentsMap}
                    handleDrop={handleDrop}
                    referenceData={row}
                    setSelectedItem={(value) => {
                      setSelectedItem(value);
                    }}
                    selectedItemId={selectedItem ? selectedItem.id : null}
                    handleDeleteItem={({ path, id, type }) => {
                      const contentItem = componentsMap[id];
                      // get any contained content items
                      const containedContentItems = getContentItemsWithinLayoutColumn(
                        contentItem,
                        layoutArray,
                        componentsMap,
                      );
                      if (deleteContentHandler) {
                        const {
                          getConfirmationModalContent,
                        } = deleteContentHandler;
                        const content = getConfirmationModalContent(
                          contentItem,
                          getOutputVariablesForContentData(
                            // add any contained content items that may have variables
                            [contentItem, ...containedContentItems],
                            contentCreationMap,
                          ),
                        );
                        if (content) {
                          setDeleteModalContent(content);
                          setCurrentlyDeleting({
                            contentItem,
                            splitPath: path.split('-'),
                            id,
                          });
                        } else {
                          // if no content, just delete
                          handleDeleteContent({
                            contentItem,
                            splitPath: path.split('-'),
                            id,
                          });
                        }
                      } else {
                        // if no delete handler, just delete
                        handleDeleteContent({
                          contentItem,
                          splitPath: path.split('-'),
                          id,
                        });
                      }
                      setSelectedItem(null);
                    }}
                    handleDuplicateItem={({ item, path, type }) => {
                      const {
                        rows,
                        contentMap,
                        // eslint-disable-next-line destructuring/no-rename
                        item: duplicateItem,
                      } = handleDuplicateItemInLayout(
                        layoutArray,
                        componentsMap,
                        path.split('-'),
                        item,
                      );

                      if (!componentsMap[item.contentId]) {
                        throw new Error(
                          "Something has gone wrong, this shouldn't be possible",
                        );
                      }

                      const duplicatedComponents =
                        type === 'layout-column'
                          ? Object.keys(contentMap)
                              .filter(
                                (x) =>
                                  contentMap[x].type !== 'layout-column' &&
                                  !Object.keys(componentsMap).includes(x),
                              )
                              .map((x) => contentMap[x].type)
                          : undefined;

                      setComponentsMap(contentMap);
                      setLayoutArray(rows);
                      setSelectedItem(duplicateItem);

                      trackContentDuplicatedEvent({
                        contentType: type as any,
                        containedComponents: duplicatedComponents,
                      });
                    }}
                  />
                </DropZoneWrapper>
              );
            })}
          </div>

          <EndDropZone
            data={{
              path: `${layoutArray.length}`,
              childrenCount: layoutArray.length,
            }}
            handleDrop={handleDrop}
            isTop={false}
          />
        </div>

        {/* Control Panel To The Right */}
        <ControlPanel
          onContentSelect={setSelectedItem}
          onContentUpdate={handleContentUpdate}
          onAddNewContent={handleAddItemFromSidebar}
          contentMap={contentCreationMap}
          selectedContent={selectedItem}
          inputVariables={inputVariables}
          enableCustomCodeInEmail={enablePreviewButtons}
          disabledSideBarItems={disabledSideBarItems}
        />
      </S.Wrapper>
    </DndProvider>
  );
};

export default ContentBuilderLayout;
