import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { METHODS } from './useContentApi';
import { useLoading } from './useLoading';

export const useSaveActivation = ({
  api,
  workspaceId,
}: {
  api: AxiosInstance;
  workspaceId: string;
}) => {
  const saveActivation = useCallback(
    async (body) => {
      let url;
      let method;

      if (body.activationId) {
        url = `/activations/${body.activationId}`;
        method = METHODS.PUT;
      } else {
        url = `/activations`;
        method = METHODS.POST;
      }

      const { data } = await api(url, {
        method,
        data: { ...body, workspaceId },
      });

      return data;
    },
    [api, workspaceId],
  );

  return useLoading(saveActivation);
};
