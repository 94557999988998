import React from 'react';

type DebugProps = {
  data: unknown;
};

export const Debug = (props: DebugProps) => {
  const { data } = props;
  return <pre className="debug">{JSON.stringify(data, null, 2)}</pre>;
};
