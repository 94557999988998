import { TextField } from '@material-ui/core';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { useOnMount } from '../../../utils/useOnmount';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';

export const FormTextInputContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-text-input'
> = {
  type: 'form-text-input',
  label: 'Short Text',
  icon: 'TextInput',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableHtml,
        RenderVariableText,
      }) => {
        const { data, id } = contentItem;
        const {
          label,
          defaultValue,
          helperText,
          paddingTop,
          paddingBottom,
          requiredField,
          paddingLeft,
          placeholder,
          paddingRight,
          backgroundColor,
          textInputType,
        } = data;

        const dynamicDefaultValue = RenderVariableText(defaultValue);

        // All form components must fire an update on mount, to let the viewer know what the default values are.
        useOnMount(() => {
          formUpdateFunction(id, dynamicDefaultValue);
        });

        return (
          <ContentContainer
            style={{
              paddingLeft,
              paddingRight,
              paddingTop,
              paddingBottom,
              backgroundColor,
            }}
            isFieldRequired={requiredField}
            label={label}
            htmlFor={id}
          >
            <TextField
              variant="outlined"
              key={`${id}-${dynamicDefaultValue}`}
              required={requiredField}
              defaultValue={dynamicDefaultValue}
              placeholder={RenderVariableText(placeholder)}
              helperText={<RenderVariableHtml htmlString={helperText} />}
              id={id}
              onChange={(e) =>
                formUpdateFunction(contentItem.id, e.target.value)
              }
              fullWidth
              type={textInputType}
            />
          </ContentContainer>
        );
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        label: 'Short Text',
        requiredField: false,
        helperText: '',
        defaultValue: '',
        placeholder: 'Enter Text',
        textInputType: 'text',
      }),

      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              textInputType: {
                controlType: 'dropdown',
                contentDataKey: 'textInputType',
                data: {
                  label: 'Text Input Type',
                  options: [
                    {
                      label: 'Text',
                      value: 'text',
                    },
                    {
                      label: 'Number',
                      value: 'number',
                    },
                    {
                      label: 'Mobile',
                      value: 'tel',
                    },
                    {
                      label: 'Email',
                      value: 'email',
                    },
                    {
                      label: 'URL',
                      value: 'url',
                    },
                    {
                      label: 'Password',
                      value: 'password',
                    },
                  ],
                },
              },
              label: {
                controlType: 'text',
                contentDataKey: 'label',
                data: {
                  label: 'Field Label',
                },
              },
              helperText: {
                controlType: 'variable-text-input',
                contentDataKey: 'helperText',
                data: {
                  label: 'Helper Text',
                  variables,
                },
              },
              placeholder: {
                controlType: 'variable-text-input',
                contentDataKey: 'placeholder',
                data: {
                  label: 'Placeholder Text',
                  variables,
                },
              },
              defaultValue: {
                controlType: 'variable-text-input',
                contentDataKey: 'defaultValue',
                data: {
                  label: 'Default Value',
                  variables,
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {},
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'string',
            inputType: contentItem.type,
            label: contentItem.data.label,
          },
        ];
      },
    },
  },
};
