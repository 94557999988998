export const DRAGGABLE_TYPES = {
  ROW: 'row',
  COLUMN: 'column',
  COMPONENT: 'component',
  SIDEBAR: 'sidebar',
} as const;

export const ALL_DRAGGABLE_TYPES = Object.values(DRAGGABLE_TYPES);

export const COLUMN_MAP = {
  '1': 1,
  '1/3': 2,
  '2': 2,
  '2/3': 2,
  '3': 3,
  '4': 4,
  Form: 1,
} as const;

export const Z_INDEX_MAP = {
  default: 0,
  clickShield: 1,
  hoverItem: 2,
  selectedItem: 3,

  // these refer to drop lines
  dropLine: 101,
  row: 102,
  column: 104,
  component: 103,

  draggableHoverIcon: 201,
  draggableItemControls: 202,

  previewButtons: 301,
} as const;

// a drag and drop component is considered 'singular' if a maximum of one can exist on the canvas.
// i.e it cannot be duplicated, but can be deleted and added again
export const SINGULAR_COMPONENTS = ['display-unsubscribe-footer'];
