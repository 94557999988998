import { RatingProps, CustomIconsType, Rating } from '../../Rating';
import { Icons } from '../../FormRatings.support';
import { RatingWrapperProps } from '../../Rating/Rating.styles';
import { RatingWrapper } from './EmojiRating.styles';
const {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} = Icons;

export const EmojiRating = (props: RatingProps): JSX.Element => {
  const { labels, iconColor, highlightColor } = props;

  const ratingWrapperProps: RatingWrapperProps = {
    iconColor,
    highlightColor,
  };

  const customIcons: CustomIconsType = {
    1: {
      icon: <SentimentVeryDissatisfied />,
      label: labels[0],
    },
    2: {
      icon: <SentimentDissatisfied />,
      label: labels[1],
    },
    3: {
      icon: <SentimentSatisfied />,
      label: labels[2],
    },
    4: {
      icon: <SentimentSatisfiedAlt />,
      label: labels[3],
    },
    5: {
      icon: <SentimentVerySatisfied />,
      label: labels[4],
    },
  };

  return (
    <RatingWrapper {...ratingWrapperProps}>
      <Rating customIcons={customIcons} {...props} />
    </RatingWrapper>
  );
};
