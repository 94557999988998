import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { useOnMount } from '../../../utils/useOnmount';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

const StyledRadio = styled(Radio)`
  &&.Mui-checked {
    color: #6200ee;
  }
`;

export const FormRadioListContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-radio-list'
> = {
  type: 'form-radio-list',
  label: 'Single Choice',
  icon: 'RadioList',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableHtml,
      }) => {
        const {
          backgroundColor,
          label,
          helperText,
          requiredField,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          options,
        } = contentItem.data;

        const initialValue = options.find(({ value }) => value === true);

        useOnMount(() => {
          formUpdateFunction(
            contentItem.id,
            initialValue ? initialValue.label : '',
          );
        });

        return (
          <ContentContainer
            style={{
              paddingLeft,
              paddingRight,
              paddingTop,
              paddingBottom,
              backgroundColor,
            }}
            isFieldRequired={requiredField}
            label={label}
            htmlFor={contentItem.id}
          >
            <FormControl required={requiredField} fullWidth id={contentItem.id}>
              <RadioGroup
                aria-label={label}
                key={`${contentItem.id}-${initialValue?.label}`}
                defaultValue={initialValue?.label}
                name={contentItem.id}
                onChange={(e) => {
                  formUpdateFunction(contentItem.id, e.target.value);
                }}
              >
                {options.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.id}
                      value={option.label}
                      control={<StyledRadio required={requiredField} />}
                      label={option.label}
                    />
                  );
                })}
              </RadioGroup>
              <FormHelperText>
                <RenderVariableHtml htmlString={helperText} />
              </FormHelperText>
            </FormControl>
          </ContentContainer>
        );
      },

      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        requiredField: false,
        helperText: '',
        label: 'Single Choice',
        options: [
          {
            label: 'Option 1',
            id: uuid(),
            value: false,
          },
          {
            label: 'Option 2',
            id: uuid(),
            value: false,
          },
          {
            label: 'Option 3',
            id: uuid(),
            value: false,
          },
        ],
      }),

      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              label: {
                controlType: 'text',
                contentDataKey: 'label',
                data: {
                  label: 'Field Label',
                },
              },
              helperText: {
                controlType: 'variable-text-input',
                contentDataKey: 'helperText',
                data: {
                  label: 'Helper Text',
                  variables,
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {},
          },
          {
            label: 'Options',
            controlsConfig: {
              options: {
                controlType: 'options-creator',
                contentDataKey: 'options',
                data: {
                  label: 'Options',
                  mode: 'single',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },

      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'string',
            inputType: contentItem.type,
            label: contentItem.data.label,
            options: contentItem.data.options,
          },
        ];
      },
    },
  },
};
