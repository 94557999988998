import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { METHODS } from './useContentApi';
import { useLoading } from './useLoading';

export const useSaveContent = ({
  api,
  workspaceId,
}: {
  api: AxiosInstance;
  workspaceId: string;
}) => {
  const saveContent = useCallback(
    async (body) => {
      let url;
      let method;

      if (body.contentId) {
        url = `/content/${body.contentId}`;
        method = METHODS.PUT;
      } else {
        url = `/content`;
        method = METHODS.POST;
      }

      const { data } = await api(url, {
        method,
        data: { ...body, workspaceId },
      });

      return data;
    },
    [api, workspaceId],
  );

  return useLoading(saveContent);
};
