import { useEffect, useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Colors } from '@whispir/ui-lib-v2';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { sign } from '../../../utils/urlSigner';
import { COMMON_DEFAULT_DATA, MAP_HEIGHT } from '../../../utils/constants';
import {
  GOOGLE_MAP_LIBRARIES,
  MAP_WIDTH,
  normaliseZoom,
  parseLocationText,
} from '../../../components/ReactDnD/PreviewContent/googleMapUtils';

const {
  REACT_APP_GOOGLE_MAP_API_KEY,
  REACT_APP_GOOGLE_SIGNING_SECRET,
} = process.env;

export const DisplayMapContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-map'
> = {
  type: 'display-map',
  label: 'Map',
  icon: 'Location',
  versions: {
    '1': {
      JsxFunction: ({ contentItem }) => {
        if (!REACT_APP_GOOGLE_MAP_API_KEY) {
          throw new Error('Google map API key does not exist');
        }

        const { isLoaded } = useLoadScript({
          googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
          libraries: GOOGLE_MAP_LIBRARIES,
        });
        const { data, id } = contentItem;
        const {
          zoom,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          locationText,
          backgroundColor,
          mapType,
        } = data;

        const [position, setPosition] = useState(
          parseLocationText(locationText),
        );

        useEffect(() => {
          navigator.geolocation.getCurrentPosition((position) =>
            setPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }),
          );
        }, []);

        useEffect(() => {
          setPosition(parseLocationText(locationText));
        }, [locationText]);

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
            }}
          >
            {isLoaded && (
              <GoogleMap
                id={id}
                mapContainerStyle={{
                  height: MAP_HEIGHT,
                  width: '100%',
                }}
                center={position}
                zoom={normaliseZoom(zoom)}
                mapTypeId={mapType}
              >
                <Marker position={position} />
              </GoogleMap>
            )}
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem: { data, id },
          columnMaxWidthPx,
          previewMode,
        } = props;

        if (!REACT_APP_GOOGLE_MAP_API_KEY) {
          throw new Error(
            'REACT_APP_GOOGLE_MAP_API_KEY is not configured in environment.',
          );
        }

        if (!previewMode && !REACT_APP_GOOGLE_SIGNING_SECRET) {
          // signing secret is not available/necessary for previews
          throw new Error(
            'REACT_APP_GOOGLE_SIGNING_SECRET is not configured in environment.',
          );
        }

        const {
          zoom,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          locationText,
          backgroundColor,
          mapType,
        } = data;

        const { lat, lng } = parseLocationText(locationText);

        // See here for API documentation on GoogleMaps Location API used to generate the linked href.
        // https://developers.google.com/maps/documentation/urls/get-started
        const href = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

        const mapWidth = columnMaxWidthPx
          ? Math.round(columnMaxWidthPx - paddingLeft - paddingRight)
          : MAP_WIDTH;

        // See here for API documentation on GoogleMaps Static API used to generate the image src.
        // https://developers.google.com/maps/documentation/maps-static/start
        const src = !previewMode
          ? `https://maps.googleapis.com/maps/api/staticmap?markers=${lat},${lng}&zoom=${normaliseZoom(
              zoom,
            )}&size=${mapWidth}x${MAP_HEIGHT}&maptype=${mapType}&key=${REACT_APP_GOOGLE_MAP_API_KEY}`
          : '';

        const signedSrc = !previewMode
          ? sign(src, REACT_APP_GOOGLE_SIGNING_SECRET)
          : '';

        return previewMode
          ? // Within <mj-raw> is the compiled mjml for <mj-image>, but with a <div> wih the map id instead of <img>
            `<mj-raw>
            <tr>
              <td align="center" style="font-size:0px;padding:${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px;word-break:break-word;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                  <tbody>
                    <tr>
                      <td style="width:${mapWidth}px;">
                        <a href="${href}" target="_blank" rel="noreferrer noopen" style="background-color: ${Colors.background}; display: block;">
                          <div style="height: ${MAP_HEIGHT}px; pointer-events: none;" id="${id}-${previewMode}" ></div>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </mj-raw>`
          : `<mj-image
              href="${href}"
              src="${signedSrc}"
              container-background-color="${backgroundColor}"
              padding-bottom="${paddingBottom}px"
              padding-left="${paddingLeft}px"
              padding-right="${paddingRight}px"
              padding-top="${paddingTop}px"
            />`;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        zoom: 80,
        // Location of Whispir Melbourne Office
        locationText: JSON.stringify({
          lat: -37.816272,
          lng: 144.962327,
        }),
        mapType: 'roadmap',
      }),
      PropertiesPanel: (props) => {
        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              locationText: {
                controlType: 'google-auto-suggest',
                contentDataKey: 'locationText',
                data: {
                  label: 'Display Location',
                },
              },
              zoom: {
                controlType: 'slider',
                contentDataKey: 'zoom',
                data: {
                  label: 'Zoom',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: '%',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              mapType: {
                controlType: 'dropdown',
                contentDataKey: 'mapType',
                data: {
                  label: 'Map Style',
                  orientation: 'horizontal',
                  options: [
                    {
                      label: 'Roadmap',
                      value: 'roadmap',
                    },
                    {
                      label: 'Satellite',
                      value: 'satellite',
                    },
                    {
                      label: 'Terrain',
                      value: 'terrain',
                    },
                    {
                      label: 'Hybrid',
                      value: 'hybrid',
                    },
                  ],
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
