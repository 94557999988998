import { ResolvedVariable } from '@whispir/variables';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import styled from 'styled-components';
import { useContext } from 'react';
import { PreviewModes } from '@whispir/ui-lib-v2';
import { contentCreationMap } from './content/content';
import { KnownContentTypeAndVersionMap } from './content/schemas/KnownContentTypeAndVersionMap';
import { AbstractContentCreationMap } from './framework/ContentCreation';
import {
  AbstractAllContentDataAsArray,
  AbstractRecords,
} from './framework/ContentDefinitions';
import {
  RenderedVariableStringAsSpanString,
  RenderedVariableStringAsString,
  RenderedVariableStringAsReactSpan,
} from './components/VariableDisplay/VariableStringDisplay';
import { ModeContext } from './components/Contexts/ModeProvider';
import { VariableContext } from './components/Contexts/VariableProvider';
import { AnyContentItem } from './framework/AnyContentItem';

//TODO probably rename this file.

/**
 * This file contains the top level 'rendering' methods for a given ContentDataArray.
 *
 */

// Provides a customiseable entry point for Message level styling.
const globalStyles = {
  // All
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  lineHeight: 1.5,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: 'normal',

  // Element Styles
  button: {
    padding: '10px 18px',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.4px',
  },

  // HTML Element Styles
  p: {
    margin: '0px',
  },
};

const theme = createMuiTheme({
  typography: {
    allVariants: {
      lineHeight: globalStyles.lineHeight,
      letterSpacing: globalStyles.letterSpacing,
      fontFamily: globalStyles.fontFamily,
      fontWeight: globalStyles.fontWeight,
      fontSize: globalStyles.fontSize,
    },
  },
  overrides: {
    MuiTypography: {
      paragraph: {
        margin: globalStyles.p.margin,
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        letterSpacing: globalStyles.button.letterSpacing,
        fontWeight: globalStyles.button.fontWeight,
        fontSize: globalStyles.button.fontSize,
        padding: globalStyles.button.padding,
      },
    },
  },
});

const mjmlGlobalStyling = `
  <mj-font name="Roboto" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" />
  <mj-attributes>
    <mj-all 
      font-family="${globalStyles.fontFamily}"
      line-height="${globalStyles.lineHeight}"
      font-size="${globalStyles.fontSize}"
      font-weight="${globalStyles.fontWeight}"
      letter-spacing="${globalStyles.letterSpacing}"
    />
    <mj-button
      padding="0px"
      inner-padding="${globalStyles.button.padding}"
      font-weight="${globalStyles.button.fontWeight}"
      font-size="${globalStyles.button.fontSize}"
      letter-spacing="${globalStyles.button.letterSpacing}"
    />
    <mj-image
      padding="0px"
    />
    <mj-text
      padding="0px"
    />
  </mj-attributes>
  <mj-style inline="inline">
    /* TODO: move this styling to be inlined in the Social Icons component. */
    .img-center-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .custom-mj-column {
      width: 100% !important;
      max-width: 100% !important;
    }
    // End social icons
  </mj-style>
  <mj-style>
    .image-cover img {
      object-fit: cover; 
    }
  </mj-style>
  <mj-html-attributes>
    <mj-selector path="p">
      <mj-html-attribute name="style">
        margin: ${globalStyles.p.margin};
      </mj-html-attribute>
    </mj-selector>
  </mj-html-attributes>`;

const StyledContent = styled.div`
  // Styling <p> tags with global margin config
  [class^='MuiTypography-root'] {
    p {
      margin: ${globalStyles.p.margin};
    }
  }
`;

export const RenderedDynamicContent = <T extends AbstractRecords>(props: {
  data: AbstractAllContentDataAsArray<T>;
  contentCreationMap: AbstractContentCreationMap<T>;
  formUpdateFunction;
  maxWidth?: number;
}) => {
  const { data, contentCreationMap, formUpdateFunction, maxWidth } = props;
  const { variableMode, inputValues } = useContext(ModeContext);
  const { inputVariables } = useContext(VariableContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const RenderVariableSpan = ({ htmlString }: { htmlString: string }) =>
    RenderedVariableStringAsReactSpan({
      htmlString,
      variableMode,
      inputValues,
      inputVariables,
    });

  const RenderVariableText = (htmlString: string) =>
    RenderedVariableStringAsString({
      htmlString,
      variableMode,
      inputValues,
      inputVariables,
    });

  return (
    <ThemeProvider theme={theme}>
      <StyledContent>
        {data.map((value) => {
          const versionMap = contentCreationMap[value.type];
          const ContentConfig = versionMap.versions[value.version];

          if (!ContentConfig) {
            throw new Error(
              "Couldn't find a content creation item for that type and version",
            );
          }

          const JsxFunction = ContentConfig.JsxFunction;

          return (
            <JsxFunction
              key={value.id}
              contentItem={value}
              formUpdateFunction={formUpdateFunction}
              RenderVariableHtml={RenderVariableSpan}
              RenderVariableText={RenderVariableText}
              maxWidth={maxWidth}
            />
          );
        })}
      </StyledContent>
    </ThemeProvider>
  );
};

export const renderMjmlBodyString = <T extends AbstractRecords>({
  data,
  RenderVariableHtml,
  RenderVariableText,
  columnMaxWidthPx,
  previewMode,
}: {
  data: AbstractAllContentDataAsArray<T>;
  RenderVariableHtml: (htmlString: string) => string;
  RenderVariableText: (htmlString: string) => string;
  columnMaxWidthPx?: number;
  previewMode?: PreviewModes;
}) => {
  return data
    .map((contentItem: AnyContentItem) => {
      const versionMap = contentCreationMap[contentItem.type];
      const contentConfig = versionMap.versions[contentItem.version];

      if (!contentConfig) {
        throw new Error(
          "Couldn't find a content creation item for that type and version",
        );
      }

      if (!contentConfig.EmailFunction) {
        console.warn(
          "Recieved a content item that didn't have an email function associated",
        );
        return '';
      }

      return contentConfig.EmailFunction({
        contentItem,
        RenderVariableHtml,
        RenderVariableText,
        columnMaxWidthPx,
        previewMode,
      });
    })
    .join('');
};

//TODO you possibly want to rename this to uppercase.
export const genericRenderEmailFromContentData = async <
  T extends AbstractRecords
>(
  data: AbstractAllContentDataAsArray<T>,
  contentCreationMap: AbstractContentCreationMap<T>,
  parseMjmlFn: (mjmlString: string) => Promise<string>,
  variableMode: 'builder' | 'viewer',
  inputValues: Array<ResolvedVariable>,
  locale: string,
  timeZone: string,
  previewMode?: PreviewModes,
): Promise<string> => {
  const RenderVariableHtml = (htmlString: string) =>
    RenderedVariableStringAsSpanString({
      htmlString,
      variableMode,
      inputValues,
      locale,
      timeZone,
    });
  const RenderVariableText = (htmlString: string) =>
    RenderedVariableStringAsString({
      htmlString,
      variableMode,
      inputValues,
      locale,
      timeZone,
    });

  const mjmlBodyString = renderMjmlBodyString({
    data,
    RenderVariableHtml,
    RenderVariableText,
    previewMode,
  });

  const fullMjmlString = `
  <mjml>
    <mj-head>
      ${mjmlGlobalStyling}
    </mj-head>
    <mj-body background-color="#FFFFFF">
      ${mjmlBodyString}
    </mj-body>
  </mjml>`;

  return parseMjmlFn(fullMjmlString);
};

export const productionRenderEmailFromContentData = async (
  data: AbstractAllContentDataAsArray<KnownContentTypeAndVersionMap>,
  parseMjmlFn: (mjmlString) => Promise<string>,
  inputValues: Array<ResolvedVariable>,
  locale: string,
  timeZone: string,
) => {
  return genericRenderEmailFromContentData(
    data,
    contentCreationMap,
    parseMjmlFn,
    'viewer',
    inputValues,
    locale,
    timeZone,
  );
};

export const previewRenderEmailFromContentData = async ({
  data,
  parseMjmlFn,
  locale,
  timeZone,
  previewMode,
}: {
  data: AbstractAllContentDataAsArray<KnownContentTypeAndVersionMap>;
  parseMjmlFn: (mjmlString) => Promise<string>;
  locale: string;
  timeZone: string;
  previewMode: PreviewModes;
}) => {
  return genericRenderEmailFromContentData(
    data,
    contentCreationMap,
    parseMjmlFn,
    'viewer',
    [],
    locale,
    timeZone,
    previewMode,
  );
};
export const resolveEmailSubject = (
  subject: string,
  inputValues: Array<ResolvedVariable>,
  locale: string,
  timeZone: string,
) =>
  RenderedVariableStringAsString({
    htmlString: subject,
    variableMode: 'viewer',
    inputValues,
    locale,
    timeZone,
  });

export const renderPlaceholderSubject = (subject: string): string =>
  RenderedVariableStringAsString({
    htmlString: subject,
    variableMode: 'builder',
    inputValues: [],
  });
