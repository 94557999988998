import { useContext } from 'react';
import {
  RenderedVariableStringAsString,
  RenderedVariableStringAsReactSpan,
} from '../../VariableDisplay/VariableStringDisplay';
import { ModeContext } from '../../Contexts/ModeProvider';

export const useRenderVariables = () => {
  const { variableMode, inputValues } = useContext(ModeContext);

  const RenderVariableSpan = ({ htmlString }: { htmlString: string }) =>
    RenderedVariableStringAsReactSpan({
      htmlString,
      variableMode,
      inputValues,
    });

  const RenderVariableText = (htmlString: string) =>
    RenderedVariableStringAsString({
      htmlString,
      variableMode,
      inputValues,
    });

  return { RenderVariableSpan, RenderVariableText };
};
