import { useEffect, useState } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { isValid, parseJSON, startOfDay } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { useOnMount } from '../../../utils/useOnmount';
import { toISOTzString } from '../../../utils/dateUtils';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

const getDefaultValue = (
  defaultValue: string,
  currentDateAsDefault: boolean,
): string | null => {
  if (currentDateAsDefault) {
    return toISOTzString(new Date());
  } else {
    const parsedDate = parseJSON(defaultValue);
    if (isValid(parsedDate)) {
      const isoTzDate = toISOTzString(parsedDate);

      return isoTzDate;
    } else {
      return null;
    }
  }
};

export const FormDateInputContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-date-input'
> = {
  type: 'form-date-input',
  label: 'Date',
  //TODO this icon should be DateInput but it isn't working
  icon: 'Date',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableHtml,
      }) => {
        const { id, data } = contentItem;
        const {
          backgroundColor,
          defaultValue,
          label,
          requiredField,
          helperText,
          currentDateAsDefault,
          format,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        } = data;

        const [selectedDate, setSelectedDate] = useState(
          getDefaultValue(defaultValue, currentDateAsDefault),
        );

        useEffect(() => {
          setSelectedDate(getDefaultValue(defaultValue, currentDateAsDefault));
        }, [currentDateAsDefault, defaultValue]);

        useOnMount(() => {
          formUpdateFunction(
            id,
            getDefaultValue(defaultValue, currentDateAsDefault) || '',
          );
        });

        const handleDateChange: KeyboardDatePickerProps['onChange'] = (
          date,
        ) => {
          if (date !== null) {
            const parsedDate = parseJSON(date);
            if (isValid(parsedDate)) {
              const startOfDayDate = startOfDay(parsedDate);
              const isoTzDate = toISOTzString(startOfDayDate);
              setSelectedDate(isoTzDate);
              formUpdateFunction(contentItem.id, isoTzDate);
            }
          }
        };

        return (
          <ContentContainer
            style={{
              backgroundColor,
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
            }}
            isFieldRequired={requiredField}
            label={label}
            htmlFor={contentItem.id}
          >
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              key={`form-date-input-${defaultValue}`}
            >
              <KeyboardDatePicker
                inputVariant="outlined"
                autoOk
                id={contentItem.id}
                required={requiredField}
                helperText={<RenderVariableHtml htmlString={helperText} />}
                variant="inline"
                format={format}
                value={selectedDate}
                onChange={handleDateChange}
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </ContentContainer>
        );
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        label: 'Date',
        requiredField: false,
        helperText: '',
        defaultValue: '',
        currentDateAsDefault: false,
        format: 'dd/MM/yy',
      }),
      PropertiesPanel: (props) => {
        const {
          variables,
          contentItem: {
            data: { format },
          },
        } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              label: {
                controlType: 'text',
                contentDataKey: 'label',
                data: {
                  label: 'Field Label',
                },
              },
              helperText: {
                controlType: 'variable-text-input',
                contentDataKey: 'helperText',
                data: {
                  label: 'Helper Text',
                  variables,
                },
              },
              currentDateAsDefault: {
                controlType: 'boolean',
                contentDataKey: 'currentDateAsDefault',
                data: {
                  label: 'Current date as default',
                },
              },
              ...(!props.contentItem.data.currentDateAsDefault && {
                defaultValue: {
                  controlType: 'date-picker',
                  contentDataKey: 'defaultValue',
                  data: {
                    label: 'Default Value',
                    includeTime: false,
                  },
                },
              }),
              format: {
                controlType: 'date-format-picker',
                contentDataKey: 'format',
                data: {
                  label: 'Date Format',
                  format,
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {},
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'date',
            inputType: contentItem.type,
            label: contentItem.data.label,
          },
        ];
      },
    },
  },
};
