import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-y: hidden;
  width: 100%;

  > div:first-child {
    flex: 1 0 1px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
`;
