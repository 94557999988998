import { Icon } from '@whispir/ui-lib-v2';

import { handleCanDrop } from '../utils/helpers';
import { AllDraggableReferences, DropData } from '../utils/types';
import * as DropLineStyles from '../DropLine/styles';
import { DRAGGABLE_TYPES } from '../utils/constants';
import DropZone from '../DropZone';
import * as S from './styles';

type DropLineProps = {
  data: DropData;
  handleDrop: (
    dropZone: DropData,
    itemBeingDropped: AllDraggableReferences,
  ) => void;
  isTop: boolean;
};

const EndDropZone = ({ data, handleDrop, isTop }: DropLineProps) => {
  const ariaLabel = isTop ? 'Top DropZone' : 'Bottom DropZone';

  return (
    <DropZone
      type={DRAGGABLE_TYPES.ROW}
      onDrop={(item) => handleDrop(data, item)}
      canDrop={(item) =>
        handleCanDrop({
          item,
          data,
        })
      }
      style={{ height: `${!isTop ? '100%' : '50px'}` }}
    >
      {({ isOver }) => (
        <S.Container aria-label={ariaLabel} isTop={isTop}>
          <S.Wrapper isOver={isOver} isTop={isTop}>
            <DropLineStyles.IconWrapper type={DRAGGABLE_TYPES.ROW}>
              <Icon icon="Add" />
            </DropLineStyles.IconWrapper>
          </S.Wrapper>
        </S.Container>
      )}
    </DropZone>
  );
};

export default EndDropZone;
