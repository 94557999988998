import { CSSProperties, memo, useCallback } from 'react';

import { useDragLayer, XYCoord } from 'react-dnd';

import { contentCreationMap } from '../../../content/content';

import { DRAGGABLE_TYPES } from '../utils/constants';
import {
  AllDraggableReferencesWithExtraProps,
  ContentItemDraggableType,
} from '../utils/types';
import * as S from './styles';

const getOffset = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
): CSSProperties => {
  if (!initialOffset || !currentOffset) {
    return {
      top: 0,
      left: 0,
    };
  }

  let { x, y } = currentOffset;

  return {
    top: y - 75,
    left: x - 75,
  };
};

const RenderItemPreview = memo(
  ({
    itemType,
    item,
  }: {
    itemType: ContentItemDraggableType | null;
    item: AllDraggableReferencesWithExtraProps;
  }) => {
    if (itemType === DRAGGABLE_TYPES.SIDEBAR && item?.label && item?.icon) {
      return <S.PreviewSquareIconButton label={item.label} icon={item.icon} />;
    }

    if (itemType === DRAGGABLE_TYPES.COMPONENT) {
      const componentDetails =
        contentCreationMap[item?.componentType || 'layout-column'];

      return (
        <S.PreviewSquareIconButton
          label={componentDetails.label}
          icon={componentDetails.icon}
        />
      );
    }

    if (itemType === DRAGGABLE_TYPES.ROW) {
      const componentDetails = contentCreationMap['layout-column'];

      return (
        <S.PreviewSquareIconButton label="Row" icon={componentDetails.icon} />
      );
    }

    return null;
  },
);

export const DragPreview = memo(() => {
  const {
    itemType,
    isDragging,
    initialOffset,
    currentOffset,
    item,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem() as AllDraggableReferencesWithExtraProps,
    itemType: monitor.getItemType() as ContentItemDraggableType,
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const callBackGetItemStyles = useCallback(getOffset, [
    initialOffset?.x,
    initialOffset?.y,
    currentOffset?.x,
    currentOffset?.y,
  ]);
  const styles = callBackGetItemStyles(initialOffset, currentOffset);

  if (!isDragging) {
    return null;
  }

  return (
    <S.Wrapper style={styles} isHidden={!initialOffset || !currentOffset}>
      <RenderItemPreview itemType={itemType} item={item} />
    </S.Wrapper>
  );
});

export default DragPreview;
