import styled from 'styled-components';
import { commonStyles, RatingWrapperProps } from '../../Rating/Rating.styles';

const inactiveStyle = (iconColor) => `
  svg{
    fill: ${iconColor};
  }
`;

const activeStyle = (highlightColor) => `
  svg{
    fill: ${highlightColor};
  }
`;

export const RatingWrapper = styled.div<RatingWrapperProps>`
  [class*='MuiRating-icon'] {
    ${({ iconColor }) => inactiveStyle(iconColor)}
  }

  ${commonStyles()}

  [class*="MuiRating-iconFilled"] {
    ${({ highlightColor }) => activeStyle(highlightColor)}
  }
`;
