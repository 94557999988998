import styled, { css } from 'styled-components';

import { Colors } from '@whispir/ui-lib-v2';
import { Z_INDEX_MAP } from '../utils/constants';

export const Container = styled.div<{ isTop?: boolean }>`
  display: flex;
  flex-flow: column;
  ${(props) =>
    props.isTop
      ? css`
          justify-content: flex-end;
        `
      : css`
          padding-bottom: 80px;
        `}
  height: 100%;
  box-sizing: border-box;
`;

export const Wrapper = styled.div<{
  isOver?: boolean;
  isTop?: boolean;
}>`
  display: none;
  background-color: ${Colors.accent_5};
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX_MAP.dropLine};
  outline: 6px solid ${Colors.accent_4};
  height: 2px;
  top: ${(props) => (props.isTop ? '1px' : '-1px')};

  ${(props) =>
    props.isOver &&
    css`
      display: flex;
    `}
`;
