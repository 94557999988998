import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  lowerOpacity: boolean;
  isDisabled?: boolean;
}>`
  display: inline-block;
  margin: 5px 6px;
  opacity: 1;

  ${(props) =>
    props.lowerOpacity &&
    css`
      opacity: 0.4;
    `};
  ${(props) =>
    props.isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      .MuiButtonBase-root {
        pointer-events: none;
      }
    `};
`;
