import { DebugPanel } from '@whispir/ui-lib-v2';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { propertyControlsMap } from '../../controls/generateControls';
import { fromKebabCaseToPascalCase } from '../../utils/stringUtils';
import {
  convertModelToContentCreationItem,
  convertModelToContentType,
} from './NewContentCreator.utils';

const ALL_CONTROL_TYPES = Object.keys(propertyControlsMap);

type NewContentCreatorProps = {};

export type ControlPropertyTemplate = {
  id: string;
  contentDataKey: string;
  controlType: string;
  label: string;
  defaultValue: any;
};

const ControlPropertyRow = ({ data, onChange, onDelete }) => {
  const { contentDataKey, label, controlType, defaultValue } = data;

  const handleChange = (field, value) => {
    onChange({
      ...data,
      [field]: value,
    });
  };

  return (
    <tr>
      <td>
        <input
          value={contentDataKey}
          onChange={(e) => handleChange('contentDataKey', e.target.value)}
        />
      </td>
      <td>
        <input
          value={label}
          onChange={(e) => handleChange('label', e.target.value)}
        />
      </td>
      <td>
        <select
          value={controlType}
          onChange={(e) => handleChange('controlType', e.target.value)}
        >
          {ALL_CONTROL_TYPES.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
      </td>
      <td>
        <input
          disabled={!['text', 'slider', 'other'].includes(controlType)}
          value={defaultValue}
          onChange={(e) => {
            const value = e.target.value;
            switch (controlType) {
              case 'text': {
                handleChange('defaultValue', value);
                break;
              }
              case 'slider': {
                handleChange('defaultValue', parseInt(value));
                break;
              }
              case 'other': {
                handleChange('defaultValue', value);
                break;
              }
              default: {
                throw new Error("Shouldn't have got here");
              }
            }
          }}
        />
      </td>

      <td>
        <button onClick={() => onDelete(data)}>Delete </button>
      </td>
    </tr>
  );
};

export const NewContentCreator = (props: NewContentCreatorProps) => {
  const [controlPropertyTemplates, setControlPropertyTemplate] = useState<
    Array<ControlPropertyTemplate>
  >([]);
  const [error, setError] = useState('');

  const [name, setName] = useState('');
  const [label, setLabel] = useState('');

  const [contentCreationItem, setContentCreationItem] = useState('');
  const [contentType, setContentType] = useState('');

  const handleChange = (newData) => {
    const newRows = controlPropertyTemplates.map((row) => {
      if (row.id === newData.id) {
        return newData;
      } else {
        return row;
      }
    });
    setControlPropertyTemplate(newRows);
  };

  const handleAdd = () => {
    setControlPropertyTemplate([
      ...controlPropertyTemplates,
      {
        id: uuid(),
        label: '',
        contentDataKey: '',
        controlType: 'text',
        defaultValue: '',
      },
    ]);
  };

  const handleDelete = (data) => {
    const newRows = controlPropertyTemplates.filter(
      (row) => row.id !== data.id,
    );

    setControlPropertyTemplate(newRows);
  };

  const handleGenerateClick = () => {
    try {
      const newContentCreationItem = convertModelToContentCreationItem(
        name,
        label,
        controlPropertyTemplates,
      );

      const newContentType = convertModelToContentType(
        name,
        controlPropertyTemplates,
      );

      setContentCreationItem(newContentCreationItem);
      setContentType(newContentType);
      setError('Success!');
    } catch (err) {
      console.info(err);
      setError(err.message);
    }
  };

  return (
    <div className="new-content-creator">
      <h2>New Content Creator</h2>
      <p>Use this tool to create new content items</p>
      {error.length > 0 && <div className="error">{error}</div>}

      <DebugPanel item={controlPropertyTemplates} />

      <div>
        <label>
          Name (kebab case):
          <input type="text" onChange={(e) => setName(e.target.value)} />
        </label>
      </div>
      <div>
        <label>
          Label:
          <input type="text" onChange={(e) => setLabel(e.target.value)} />
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>Control Name (kebab-case)</th>
            <th>Label</th>
            <th>Control Type</th>
            <th>defaultValue</th>
          </tr>
        </thead>

        {controlPropertyTemplates.map((v) => (
          <ControlPropertyRow
            onDelete={handleDelete}
            onChange={handleChange}
            data={v}
            key={v.id}
          />
        ))}
      </table>

      <div>
        <button onClick={handleAdd}>Add new control proeprty</button>
      </div>
      <div>
        <button onClick={handleGenerateClick}>Generate!</button>
      </div>
      <h2>Output:</h2>

      <DebugPanel
        showCopyButton
        paragraph="KnownContentTypeAndVersionMap.ts"
        item={contentType}
      />

      <DebugPanel
        showCopyButton
        paragraph={`${fromKebabCaseToPascalCase(name)}.tsx`}
        item={contentCreationItem}
      />
    </div>
  );
};
