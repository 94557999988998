import { Button } from '@material-ui/core';
import { useContext } from 'react';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { LoadingContext } from '../../../components/Contexts/LoadingProvider';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';

export const FormSubmitButtonContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-submit-button'
> = {
  type: 'form-submit-button',
  label: 'Submit',
  icon: 'Button',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, RenderVariableHtml }) => {
        const { data } = contentItem;
        const {
          text,
          color,
          buttonColor,
          backgroundColor,
          borderColor,
          fontSize,
          borderRadius,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          width,
        } = data;
        const { isLoading } = useContext(LoadingContext);

        return (
          <ContentContainer
            style={{
              paddingLeft,
              paddingRight,
              paddingTop,
              paddingBottom,
              backgroundColor,
              textAlign,
            }}
          >
            <Button
              type="submit"
              style={{
                color: isLoading ? '#9FA0A7' : color,
                backgroundColor: isLoading ? '#E7E7E9' : buttonColor,
                borderRadius,
                textTransform: 'none',
                border: isLoading ? 'none' : `1px solid ${borderColor}`,
                padding: '10px 18px',
                width,
                fontSize,
                maxWidth: '100%',
                cursor: isLoading ? 'not-allowed' : 'initial',
              }}
              disabled={isLoading}
            >
              <RenderVariableHtml htmlString={text} />
            </Button>
          </ContentContainer>
        );
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        borderColor: '#5700D4',
        borderRadius: 4,
        buttonColor: '#6200EE',
        color: '#FFFFFF',
        fontSize: 14,
        text: 'Submit',
        textAlign: 'center',
        width: 140,
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              text: {
                controlType: 'variable-text-input',
                contentDataKey: 'text',
                data: {
                  label: 'Button Text',
                  variables,
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              color: {
                controlType: 'color-picker',
                contentDataKey: 'color',
                data: {
                  label: 'Text Color',
                },
              },
              buttonColor: {
                controlType: 'color-picker',
                contentDataKey: 'buttonColor',
                data: {
                  label: 'Button Color',
                },
              },
              borderColor: {
                controlType: 'color-picker',
                contentDataKey: 'borderColor',
                data: {
                  label: 'Border Color',
                  labelSize: 'large',
                },
              },
              fontSize: {
                controlType: 'slider',
                contentDataKey: 'fontSize',
                data: {
                  label: 'Font Size',
                  minValue: 1,
                  maxValue: 72,
                  step: 1,
                  units: 'px',
                },
              },
              borderRadius: {
                controlType: 'slider',
                contentDataKey: 'borderRadius',
                data: {
                  label: 'Border Radius',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              width: {
                controlType: 'slider',
                contentDataKey: 'width',
                data: {
                  label: 'Button Width',
                  minValue: 0,
                  maxValue: 600,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              textAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'textAlign',
                data: {
                  label: 'Alignment',
                  variant: 'items',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
