import { CSSProperties, ReactNode, useRef, useState } from 'react';

import { useDrop } from 'react-dnd';
import { getKeyByValue } from '../../utils';
import {
  AllDraggableReferences,
  ContentItemDroppableType,
  Direction,
  isValidDropItem,
} from '../utils/types';
import { ALL_DRAGGABLE_TYPES, DRAGGABLE_TYPES } from '../utils/constants';
import * as S from './styles';

const ACCEPTS = [...ALL_DRAGGABLE_TYPES];
type Props = {
  children: (params: {
    isOver: boolean;
    dragDirection?: Direction;
  }) => ReactNode;
  onDrop: (item: AllDraggableReferences, dragDirection: Direction) => void;
  canDrop: (item: AllDraggableReferences, dragDirection: Direction) => boolean;
  style?: CSSProperties;
  shallow?: boolean;
  type: ContentItemDroppableType;
};

const DropZone = ({
  children,
  onDrop,
  canDrop,
  style = {},
  type,
  shallow = false,
}: Props) => {
  const placementRef = useRef<HTMLDivElement>(null);
  const [dragDirection, setDragDirection] = useState<Direction>();
  const [{ isOver }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item) => {
      if (isValidDropItem(item)) {
        onDrop(item, dragDirection);
      } else {
        throw new Error(
          "Something went wrong, our drop item wasn't in the right format",
        );
      }
    },
    hover: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();

      if (clientOffset && placementRef?.current) {
        const {
          right,
          bottom,
          left,
          top,
        } = placementRef.current.getBoundingClientRect();
        const dropLineTop = bottom,
          dropLineLeft = right,
          dropLineBottom = top,
          dropLineRight = left;

        const directionMap = {
          top: Math.round(
            ((dropLineTop - clientOffset.y) / (dropLineTop - dropLineBottom)) *
              100,
          ),
          bottom: Math.round(
            (Math.abs(dropLineBottom - clientOffset.y) /
              (dropLineTop - dropLineBottom)) *
              100,
          ),
          left: Math.round(
            ((dropLineLeft - clientOffset.x) / (dropLineLeft - dropLineRight)) *
              100,
          ),
          right: Math.round(
            (Math.abs(dropLineRight - clientOffset.x) /
              (dropLineLeft - dropLineRight)) *
              100,
          ),
        } as const;
        const topAndBottomDirectionMap = {
          top: directionMap.top,
          bottom: directionMap.bottom,
        } as const;
        const maxValueAllDirection = Math.max(...Object.values(directionMap));
        const maxValueTopAndBottom = Math.max(
          ...Object.values(topAndBottomDirectionMap),
        );
        const foundDirection = (() => {
          if (
            type === DRAGGABLE_TYPES.ROW ||
            (isValidDropItem(item) && !canDrop(item, 'right'))
          ) {
            return getKeyByValue(
              topAndBottomDirectionMap,
              maxValueTopAndBottom,
            );
          }

          return getKeyByValue(directionMap, maxValueAllDirection);
        })();

        if (foundDirection && dragDirection !== foundDirection) {
          setDragDirection(foundDirection as Direction);
        }
      }
    },
    canDrop: (item, monitor) => {
      if (!monitor.isOver({ shallow: true })) {
        return false;
      }

      if (isValidDropItem(item)) {
        return canDrop(item, dragDirection);
      } else {
        throw new Error(
          "Something went wrong, our drop item wasn't in the right format",
        );
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({
        shallow:
          monitor.getItemType() === DRAGGABLE_TYPES.ROW ? false : shallow,
      }),
    }),
  });

  return (
    <S.Wrapper ref={placementRef} style={style}>
      <div ref={drop} style={style}>
        {children({ isOver, dragDirection })}
      </div>
    </S.Wrapper>
  );
};

export default DropZone;
