export const toInitialUpperCase = (str: string) => {
  if (str.length === 0) {
    return str;
  } else {
    const first = str.substr(0, 1);
    const rest = str.substr(1);

    return first.toUpperCase() + rest;
  }
};

export const fromKebabCaseToPascalCase = (str: string) => {
  const parts = str.split('-');
  return parts.map((v) => toInitialUpperCase(v)).join('');
};

const HTML_ESCAPE_CHAR_TO_ASCII = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&apos;': "'",

  // Draftail's encoding for the quote character
  '&#x27;': "'",
};
const HTML_ESCAPE_CHAR_REGEX = new RegExp(
  `${Object.keys(HTML_ESCAPE_CHAR_TO_ASCII).join('|')}`,
  'g',
);

export const unEscapeHtml = (str: string): string => {
  return str.replace(
    HTML_ESCAPE_CHAR_REGEX,
    (m) => HTML_ESCAPE_CHAR_TO_ASCII[m],
  );
};
