/* eslint-disable react-hooks/exhaustive-deps */ // What do we want to do with this? Remove the rule completely?
import { useEffect, createElement } from 'react';
import { v4 as uuid } from 'uuid';
import { generateControls } from '../controls/generateControls';
import { AbstractContentCreationMap } from '../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from './schemas/KnownContentTypeAndVersionMap';

import { DisplayArticleContentCreationItem } from './items/DisplayArticle/DisplayArticle';
import { DisplayBannerContentCreationItem } from './items/DisplayBanner/DisplayBanner';
import { DisplayButtonContentCreationItem } from './items/DisplayButton/DisplayButton';
import { DisplayButtonGroupContentCreationItem } from './items/DisplayButtonGroup/DisplayButtonGroup';
import { DisplayCalendarContentCreationItem } from './items/DisplayCalendar/DisplayCalendar';
import { DisplayDividerContentCreationItem } from './items/DisplayDivider/DisplayDivider';
import { DisplayFooterContentCreationItem } from './items/DisplayFooter/DisplayFooter';

import { FormSubmitButtonContentCreationItem } from './items/FormSubmitButton/FormSubmitButton';
import { DisplayMapContentCreationItem } from './items/DisplayMap/DisplayMap';
import { DisplayVideoPlayerContentCreationItem } from './items/DisplayVideoPlayer/DisplayVideoPlayer';
import { DisplayCustomCodeContentCreationItem } from './items/DisplayCustomCode/DisplayCustomCode';
import { DisplayUnsubscribeFooterContentCreationItem } from './items/DisplayUnsubscribeFooter/DisplayUnsubscribeFooter';

import { FormDateInputContentCreationItem } from './items/FormDateInput/FormDateInput';
import { FormDropdownContentCreationItem } from './items/FormDropdown/FormDropdown';
import { FormRadioListContentCreationItem } from './items/FormRadioList/FormRadioList';
import { FormTextInputContentCreationItem } from './items/FormTextInput/FormTextInput';
import { FormCheckboxListContentCreationItem } from './items/FormCheckboxList/FormCheckboxList';
import { FormTextAreaContentCreationItem } from './items/FormTextArea/FormTextArea';
import { FormYesNoButtonsContentCreationItem } from './items/FormYesNoButtons/FormYesNoButtons';
import { FormRatingsContentItem } from './items/FormRatings/FormRatings';
import { DisplaySocialIconsContentCreationItem } from './items/DisplaySocialIcons/DisplaySocialIcons';
import { FormSliderContentItem } from './items/FormSlider/FormSlider';
import { FormInteractiveMapContentItem } from './items/FormLocation/FormLocation';
import { LayoutColumnContentCreationItem } from './items/LayoutColumn/LayoutColumn';
import { DisplayImageContentCreationItem } from './items/DisplayImage/DisplayImage';
import { DisplayTextContentCreationItem } from './items/DisplayText/DisplayText';

export const contentCreationMap: AbstractContentCreationMap<KnownContentTypeAndVersionMap> = {
  // Form Components
  'form-date-input': FormDateInputContentCreationItem,
  'form-dropdown': FormDropdownContentCreationItem,
  'form-checkbox-list': FormCheckboxListContentCreationItem,
  'form-location': FormInteractiveMapContentItem,
  'form-text-area': FormTextAreaContentCreationItem,
  'form-text-input': FormTextInputContentCreationItem,
  'form-slider': FormSliderContentItem,
  'form-radio-list': FormRadioListContentCreationItem,
  'form-ratings': FormRatingsContentItem,
  'form-submit-button': FormSubmitButtonContentCreationItem,
  'form-yes-no-buttons': FormYesNoButtonsContentCreationItem,

  // Display Components
  'display-article': DisplayArticleContentCreationItem,
  'display-banner': DisplayBannerContentCreationItem,
  'display-button': DisplayButtonContentCreationItem,
  'display-button-group': DisplayButtonGroupContentCreationItem,
  'display-calendar': DisplayCalendarContentCreationItem,
  'display-custom-code': DisplayCustomCodeContentCreationItem,
  'display-divider': DisplayDividerContentCreationItem,
  'display-footer': DisplayFooterContentCreationItem,
  'display-image': DisplayImageContentCreationItem,
  'display-map': DisplayMapContentCreationItem,
  'display-social-icons': DisplaySocialIconsContentCreationItem,
  'display-text': DisplayTextContentCreationItem,
  'display-video-player': DisplayVideoPlayerContentCreationItem,
  'display-unsubscribe-footer': DisplayUnsubscribeFooterContentCreationItem,

  // Layout Components
  'layout-column': LayoutColumnContentCreationItem,

  // Test Components
  heading: {
    type: 'heading',
    label: 'Heading',
    icon: 'Tip',
    versions: {
      '1': {
        defaultData: () => ({
          text: '',
          size: 'h1',
        }),
        JsxFunction: ({ contentItem }) => {
          const tag = contentItem.data.size;

          //https://stackoverflow.com/questions/33471880/dynamic-tag-name-in-jsx-and-react
          return createElement(`${tag}`, null, contentItem.data.text);
        },
        PropertiesPanel: (props) => {
          return generateControls(props, {
            size: {
              controlType: 'radio',
              contentDataKey: 'size',
              data: {
                options: {
                  h1: 'h1',
                  h2: 'h2',
                  h3: 'h3',
                },
                label: 'Size',
              },
            },
            text: {
              controlType: 'text',
              contentDataKey: 'text',
              data: {
                label: 'Text',
              },
            },
          });
        },
      },
    },
  },
  link: {
    type: 'link',
    label: 'Link',
    icon: 'Tip',
    versions: {
      '1': {
        defaultData: () => ({
          href: '',
          text: 'I am a link',
        }),
        JsxFunction: ({ contentItem }) => (
          <a href={contentItem.data.href}>{contentItem.data.text}</a>
        ),

        PropertiesPanel: (props) => {
          return generateControls(props, {
            text: {
              controlType: 'text',
              contentDataKey: 'text',
              data: {
                label: 'Text',
              },
            },
            href: {
              controlType: 'text',
              contentDataKey: 'href',
              data: {
                label: 'href',
              },
            },
          });
        },
      },
    },
  },
  paragraph: {
    type: 'paragraph',
    label: 'Paragraph',
    icon: 'Tip',

    versions: {
      '1': {
        JsxFunction: ({ contentItem }) => {
          return (
            <p
              style={{
                textAlign: contentItem.data.align,
              }}
            >
              {contentItem.data.text}
            </p>
          );
        },
        defaultData: () => ({
          text: 'I am a paragraph',
          align: 'left',
        }),
        PropertiesPanel: (props) => {
          return generateControls(props, {
            align: {
              controlType: 'radio',
              data: {
                options: {
                  left: 'Left',
                  center: 'Center',
                  right: 'Right',
                },
                label: 'Alignment',
              },
              contentDataKey: 'align',
            },
            text: {
              controlType: 'text',
              contentDataKey: 'text',
              data: {
                label: 'Text',
              },
            },
          });
        },
      },
    },
  },

  checkbox: {
    type: 'checkbox',
    label: 'Checkbox',
    icon: 'Tip',
    versions: {
      '1': {
        JsxFunction: ({ contentItem, formUpdateFunction }) => {
          useEffect(() => {
            formUpdateFunction(contentItem.id, false);
          }, []);

          return (
            <label>
              {contentItem.data.label}
              <input
                type="checkbox"
                onChange={(e) =>
                  formUpdateFunction(contentItem.id, e.target.checked)
                }
              />
            </label>
          );
        },
        defaultData: () => ({
          label: '',
        }),
        PropertiesPanel: (props) => {
          return generateControls(props, {
            label: {
              controlType: 'text',
              contentDataKey: 'label',
              data: {
                label: 'Label',
              },
            },
          });
        },
      },
    },
  },

  test: {
    type: 'test',
    label: 'Test',
    icon: 'Tip',
    versions: {
      '1': {
        JsxFunction: ({ contentItem }) => {
          return (
            <div>
              {Object.entries(contentItem.data).map(([key, value]) => {
                return (
                  <p key={key}>
                    {key} : {JSON.stringify(value)}
                  </p>
                );
              })}
            </div>
          );
        },
        defaultData: () => ({
          testRadio: 'option2',
          testText: 'initial text value',
          testVariableDropdown: null,
          testVariableTextInput: '<p>variable text input</p>',
          testBoolean: false,
          testColorPicker: 'red',
          testSlider: 3,
          testPositionSelector: 'top-left',
          testAlignmentSelector: 'left',
          testDropdown: '123',
          testOptionsMulti: [
            {
              id: uuid(),
              label: 'Option 1',
              value: true,
            },
            {
              id: uuid(),
              label: 'Option 2',
              value: false,
            },
            {
              id: uuid(),
              label: 'Option 3',
              value: false,
            },
          ],
          testOptionsSingle: [],
          testLinkGenerator: [],
          testDatePicker: new Date(),
          testRichTextParagraph: '<p>initial rich text</p>',
          testDatePickerFromTo: {
            from: `${new Date()}`,
            to: `${new Date()}`,
          },
          testSocialIconsOptionsPicker: [
            {
              id: uuid(),
              label: 'Facebook',
              selectedIcon: 'Facebook',
              value: true,
            },
          ],
          testLabelsMaker: {
            count: 3,
            includeLabels: true,
            labels: [
              {
                label: 'label 1',
                value: 'Good',
              },
              {
                label: 'label 2',
                value: 'average',
              },
              {
                label: 'label 3',
                value: 'poor',
              },
            ],
          },
        }),
        PropertiesPanel: (props) => {
          const { variables } = props;

          return (
            <>
              <p data-testid="test-component-id">{`component id: ${props.contentItem.id}`}</p>
              {generateControls(props, {
                testRadio: {
                  controlType: 'radio',
                  contentDataKey: 'testRadio',
                  data: {
                    label: 'Radio Option',
                    options: {
                      option1: 'Option 1',
                      option2: 'Option 2',
                      option3: 'Option 3',
                    },
                  },
                },
                testText: {
                  controlType: 'text',
                  contentDataKey: 'testText',
                  data: {
                    label: 'Text Value',
                  },
                },
                testVariableTextInput: {
                  controlType: 'variable-text-input',
                  contentDataKey: 'testVariableTextInput',
                  data: {
                    label: 'Variable Text Input',
                    variables,
                  },
                },

                testRichTextParagraph: {
                  controlType: 'rich-text-paragraph',
                  contentDataKey: 'testRichTextParagraph',
                  data: {
                    label: 'Rich Text Paragraph (With Variables)',
                    variables,
                  },
                },

                testBoolean: {
                  controlType: 'boolean',
                  contentDataKey: 'testBoolean',
                  data: {
                    label: 'Checkbox (Boolean)',
                  },
                },
                testColorPicker: {
                  controlType: 'color-picker',
                  contentDataKey: 'testColorPicker',
                  data: {
                    label: 'Color Picker',
                  },
                },
                testSlider: {
                  controlType: 'slider',
                  contentDataKey: 'testSlider',
                  data: {
                    label: 'Slider Label',
                    minValue: 1,
                    maxValue: 10,
                    step: 1,
                  },
                },
                testPositionSelector: {
                  controlType: 'position-selector',
                  contentDataKey: 'testPositionSelector',
                  data: {
                    label: 'Position Selector',
                  },
                },
                testAlignmentSelector: {
                  controlType: 'alignment-selector',
                  contentDataKey: 'testAlignmentSelector',
                  data: {
                    variant: 'leftright',
                    label: 'Alignment Selector',
                  },
                },
                testDropdown: {
                  controlType: 'dropdown',
                  contentDataKey: 'testDropdown',
                  data: {
                    label: 'Dropdown Selection',
                    options: [
                      {
                        label: 'foo',
                        value: 'foo',
                      },
                      {
                        label: 'bar',
                        value: 'bar',
                      },
                    ],
                  },
                },
                testOptionsMulti: {
                  contentDataKey: 'testOptionsMulti',
                  controlType: 'options-creator',
                  data: {
                    label: 'Options Creator',
                    mode: 'multi',
                  },
                },
                testOptionsSingle: {
                  contentDataKey: 'testOptionsSingle',
                  controlType: 'options-creator',
                  data: {
                    label: 'Options Creator',
                    mode: 'single',
                  },
                },
                testLinkGenerator: {
                  contentDataKey: 'testLinkGenerator',
                  controlType: 'links-generator',
                  data: {
                    label: 'Link generator',
                    showColorPickers: false,
                  },
                },
                testDatePicker: {
                  controlType: 'date-picker',
                  contentDataKey: 'testDatePicker',
                  data: {
                    includeTime: false,
                    label: 'Date Time Picker',
                  },
                },
                testSocialIconsOptionsPicker: {
                  controlType: 'social-icons-options-picker',
                  contentDataKey: 'testSocialIconsOptionsPicker',
                  data: {
                    label: 'SOCIAL ICONS',
                  },
                },
                testLabelsMaker: {
                  controlType: 'labels-maker',
                  contentDataKey: 'testLabelsMaker',
                  data: {
                    label: 'LABELS MAKER',
                  },
                },
              })}
            </>
          );
        },
      },
    },
  },
};

// Currently not used, but incase you need to look up an cci key via the label

export const labelToKeyLookup = Object.entries(contentCreationMap).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [value.label]: key,
    };
  },
  {},
);
