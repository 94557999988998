import { PreviewModes } from '@whispir/ui-lib-v2';

export const MAX_ZOOM = 16;
export const MAP_WIDTH = 600;
export const GOOGLE_MAP_LIBRARIES = ['places' as const];

export const createGoogleMapId = (
  contentItemId: string,
  previewMode: PreviewModes,
) => `${contentItemId}-${previewMode}`;

export const normaliseZoom = (zoom: number) =>
  Math.floor((zoom * MAX_ZOOM) / 100);

export const parseLocationText = (
  locationText: string,
): { lat: number; lng: number } => JSON.parse(locationText);
