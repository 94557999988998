import styled from 'styled-components';

import { Rating as RatingMUI } from '@material-ui/lab';

export type RatingWrapperProps = {
  iconColor: string;
  highlightColor: string;
};

const gutterSpace = 3;
export const StyledRating = styled(RatingMUI)<{
  count: number;
}>`
  && {
    display: inline-flex;
    margin: 8px 0px;
    align-items: flex-start;
  }

  [class*='.MuiRating-label'] {
    margin: 0px ${gutterSpace}px;
    position: relative;
    min-width: 24px;
    color: red;
    max-width: calc(${(props) => 100 / props.count}% - ${gutterSpace * 2}px);
  }
` as typeof RatingMUI;

export const commonStyles = (): string => `
  svg {
    width: 1.5em;
    height: 1.5em;
  }

  [class*="MuiRating-root"]{
    flex-wrap: wrap;
  }
`;
