import { TextField } from '@material-ui/core';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { useOnMount } from '../../../utils/useOnmount';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';

export const FormTextAreaContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-text-area'
> = {
  type: 'form-text-area',
  label: 'Long Text',
  icon: 'LongText',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableText,
        RenderVariableHtml,
      }) => {
        const { id, data } = contentItem;
        const {
          label,
          requiredField,
          helperText,
          defaultValue,
          inputFieldHeight,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          backgroundColor,
          placeholder,
        } = data;

        const dynamicDefaultValue = RenderVariableText(defaultValue);

        useOnMount(() => {
          formUpdateFunction(id, dynamicDefaultValue);
        });

        return (
          <ContentContainer
            style={{
              paddingLeft,
              paddingRight,
              paddingTop,
              paddingBottom,
              backgroundColor,
            }}
            isFieldRequired={requiredField}
            label={label}
            htmlFor={contentItem.id}
          >
            <TextField
              variant="outlined"
              key={`${id}-${dynamicDefaultValue}`}
              id={contentItem.id}
              required={requiredField}
              defaultValue={dynamicDefaultValue}
              placeholder={RenderVariableText(placeholder)}
              helperText={<RenderVariableHtml htmlString={helperText} />}
              fullWidth
              multiline
              rows={inputFieldHeight}
              rowsMax={inputFieldHeight}
              onChange={(e) => formUpdateFunction(id, e.target.value)}
            />
          </ContentContainer>
        );
      },

      // END DELETE
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        label: 'Long Text',
        requiredField: false,
        helperText: '',
        defaultValue: '',
        inputFieldHeight: 4,
        placeholder: 'Enter Text',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              label: {
                controlType: 'text',
                contentDataKey: 'label',
                data: {
                  label: 'Field Label',
                },
              },
              helperText: {
                controlType: 'variable-text-input',
                contentDataKey: 'helperText',
                data: {
                  label: 'Helper Text',
                  variables,
                },
              },
              placeholder: {
                controlType: 'variable-text-input',
                contentDataKey: 'placeholder',
                data: {
                  label: 'Placeholder Text',
                  variables,
                },
              },
              defaultValue: {
                controlType: 'variable-text-input',
                contentDataKey: 'defaultValue',
                data: {
                  label: 'Default Value',
                  variables,
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {},
          },
          {
            label: 'Options',
            controlsConfig: {
              inputFieldHeight: {
                controlType: 'dropdown',
                contentDataKey: 'inputFieldHeight',
                data: {
                  label: 'Input Field Height',
                  options: [
                    {
                      label: '1 Line',
                      value: 1,
                    },
                    {
                      label: '2 Lines',
                      value: 2,
                    },
                    {
                      label: '3 Lines',
                      value: 3,
                    },
                    {
                      label: '4 Lines',
                      value: 4,
                    },
                    {
                      label: '5 Lines',
                      value: 5,
                    },
                    {
                      label: '6 Lines',
                      value: 6,
                    },
                    {
                      label: '7 Lines',
                      value: 7,
                    },
                    {
                      label: '8 Lines',
                      value: 8,
                    },
                    {
                      label: '9 Lines',
                      value: 9,
                    },
                    {
                      label: '10 Lines',
                      value: 10,
                    },
                  ],
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },

      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'string',
            inputType: contentItem.type,
            label: contentItem.data.label,
          },
        ];
      },
    },
  },
};
