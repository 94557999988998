const { REACT_APP_CONTENT_API_URL } = process.env;

type Attributes = {
  inviteStart: string;
  inviteEnd: string;
  inviteAllDay: boolean;
  inviteTitle: string;
  inviteDescription: string;
  inviteLocation: string;
  inviteFileName: string;
};

export const getIcsURI = (attributes: Attributes) => {
  const {
    inviteStart,
    inviteEnd,
    inviteAllDay,
    inviteTitle,
    inviteDescription,
    inviteLocation,
    inviteFileName,
  } = attributes;

  if (!REACT_APP_CONTENT_API_URL) {
    throw new Error('REACT_APP_CONTENT_API_URL not present in environment');
  }

  const uriEncodedContent = encodeURIComponent(
    JSON.stringify({
      prodId: {
        company: 'Whispir',
        product: 'Content',
        language: 'EN',
      },
      events: [
        {
          start: inviteStart,
          end: inviteEnd,
          allDay: inviteAllDay,
          summary: inviteTitle,
          description: inviteDescription,
          location: inviteLocation,
        },
      ],
    }),
  );
  const uri = `${REACT_APP_CONTENT_API_URL}/file/calendar/${inviteFileName}.ics?content=${uriEncodedContent}`;

  return uri;
};
