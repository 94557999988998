import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { useOnMount } from '../../../utils/useOnmount';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

const StyledCheckbox: typeof Checkbox = styled(Checkbox)`
  &&[class*='Mui-checked'] {
    color: #6200ee;
  }
`;

// This is the technique for using the browser native validation messages
//https://stackoverflow.com/questions/22702629/how-to-create-html5-custom-validation
const addCustomErrorMessage = (
  ref: React.MutableRefObject<HTMLInputElement | null | undefined>,
  isInvalid,
) => {
  if (isInvalid) {
    ref.current?.setCustomValidity('Please select at least one option');
  } else {
    ref.current?.setCustomValidity('');
  }
};

export const FormCheckboxListContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-checkbox-list'
> = {
  type: 'form-checkbox-list',
  label: 'Multi Choice',
  icon: 'CheckboxList',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        formUpdateFunction,
        RenderVariableHtml,
      }) => {
        const {
          backgroundColor,
          label,
          helperText,
          requiredField,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          options,
        } = contentItem.data;

        useOnMount(() => {
          // TODO: update the `options` to have the same shape as below
          const initialValue = options.reduce((acc, option) => {
            if (option.value) {
              return [...acc, option.label];
            } else {
              return acc;
            }
          }, [] as string[]);
          formUpdateFunction(contentItem.id, initialValue);
        });

        const checkboxRef = React.useRef<HTMLInputElement | null>();

        const [numSelected, setNumSelected] = useState(
          options.filter((v) => v.value).length,
        );

        useEffect(() => {
          addCustomErrorMessage(
            checkboxRef,
            numSelected === 0 && requiredField,
          );
        }, [numSelected, requiredField]);

        return (
          <ContentContainer
            style={{
              paddingLeft,
              paddingRight,
              paddingTop,
              paddingBottom,
              backgroundColor,
            }}
            isFieldRequired={requiredField}
            label={label}
            htmlFor={contentItem.id}
          >
            <FormControl
              id={contentItem.id}
              component="fieldset"
              required={requiredField}
              fullWidth
            >
              <FormGroup>
                {contentItem.data.options.map((option, i) => {
                  return (
                    <FormControlLabel
                      key={option.id}
                      label={option.label}
                      control={
                        <StyledCheckbox
                          inputRef={(ref) => {
                            if (i === 0) {
                              checkboxRef.current = ref;
                            }
                          }}
                          key={`${contentItem.id}-${option.id}-${option.value}`}
                          name={contentItem.id}
                          value={option.label}
                          defaultChecked={option.value}
                          onChange={(e) => {
                            formUpdateFunction(
                              contentItem.id,
                              null, // value is created by the function

                              // !IMPORTANT - Note here we are using the callback to determine the current value
                              (oldData) => {
                                if (
                                  !(
                                    typeof oldData === 'object' &&
                                    oldData !== null
                                  )
                                ) {
                                  throw new Error(
                                    'Something has gone wrong, we were expecting a plain object here',
                                  );
                                }

                                // @ts-ignore
                                const filteredOldData = oldData.value.filter(
                                  (label) => label !== option.label,
                                );

                                const updatedItem = e.target.checked
                                  ? [option.label]
                                  : [];

                                const updatedData = [
                                  ...filteredOldData,
                                  ...updatedItem,
                                ];

                                setNumSelected(updatedData.length);

                                return updatedData;
                              },
                            );
                          }}
                        />
                      }
                    />
                  );
                })}
              </FormGroup>
              <FormHelperText>
                <RenderVariableHtml htmlString={helperText} />
              </FormHelperText>
            </FormControl>
          </ContentContainer>
        );
      },

      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        requiredField: false,
        helperText: '',
        label: 'Multi Choice',
        options: [
          {
            label: 'Option 1',
            id: uuid(),
            value: false,
          },
          {
            label: 'Option 2',
            id: uuid(),
            value: false,
          },
          {
            label: 'Option 3',
            id: uuid(),
            value: false,
          },
        ],
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              label: {
                controlType: 'text',
                contentDataKey: 'label',
                data: {
                  label: 'Field Label',
                },
              },
              helperText: {
                controlType: 'variable-text-input',
                contentDataKey: 'helperText',
                data: {
                  label: 'Helper Text',
                  variables,
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {},
          },
          {
            label: 'Options',
            controlsConfig: {
              options: {
                controlType: 'options-creator',
                contentDataKey: 'options',
                data: {
                  label: 'Options',
                  mode: 'multi',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            // TODO: update this to Array of strings
            valueType: 'array-of-string',
            inputType: contentItem.type,
            label: contentItem.data.label,
            options: contentItem.data.options,
          },
        ];
      },
    },
  },
};
