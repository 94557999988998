import videoUrlParser from 'js-video-url-parser';

// Options type is not exported, so we use the builtin `Parameters` generic to extract the type.
type CreateParameters = Parameters<typeof videoUrlParser.create>;
// Pulling out the first argument.
type Options = CreateParameters[0];

type ProviderConfig = {
  preview: Partial<Options>;
};

// If the Video provider supports preview generation, add the configuration here.
// The preview configuration is used to render a static image.
const videoProviderConfigurationMap: Record<string, ProviderConfig> = {
  // https://github.com/Zod-/jsVideoUrlParser/wiki/Dailymotion
  dailymotion: {
    preview: {
      format: 'image',
    },
  },
  // https://github.com/Zod-/jsVideoUrlParser/wiki/YouTube
  youtube: {
    preview: {
      format: 'longImage',
      params: {
        imageQuality: 'maxresdefault',
      },
    },
  },
};

type ParsedVideo = {
  link: string;
  embed?: string;
  preview?: string;
};

export const parseVideoSrc = (src: string): ParsedVideo => {
  // The `js-video-url-parser` implements parsers and creators for video providers,
  // and provides extensibility to add new providers as necessary.
  // See here for a full list of supported providers:
  // https://www.npmjs.com/package/js-video-url-parser#supported-providers
  const parsedVideoUrl = videoUrlParser.parse(src);

  if (!parsedVideoUrl) {
    // Video cannot be parsed, fallback to the provided src.
    return { link: src };
  } else {
    // Video src can be parsed.
    const embed = videoUrlParser.create({
      videoInfo: parsedVideoUrl,
      format: 'embed',
    });

    const link = videoUrlParser.create({
      videoInfo: parsedVideoUrl,
      format: 'long',
    }) as string;

    // Some providers don't support preview generation.
    // A custom configuration is required for the preview for each provider.
    const videoProviderConfig =
      videoProviderConfigurationMap[parsedVideoUrl.provider as string];
    if (videoProviderConfig?.preview) {
      const preview = videoUrlParser.create({
        videoInfo: parsedVideoUrl,
        ...videoProviderConfig.preview,
      });

      return { link, embed, preview };
    }

    return { link, embed };
  }
};
