import { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

export const DisplayFooterContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-footer'
> = {
  type: 'display-footer',
  label: 'Footer',
  icon: 'Footer',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        RenderVariableHtml,
        RenderVariableText,
      }) => {
        const { data } = contentItem;
        const {
          color,
          backgroundColor,
          logoWidth,
          logoSrc,
          logoAltText,
          text,
          links,
          textAlign,
          imageAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        } = data;

        return (
          <ContentContainer
            style={{
              backgroundColor,
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              color,
              flexDirection: 'column',
              textAlign: 'left',
            }}
          >
            <img
              style={{
                width: logoWidth,
                marginLeft: imageAlign === 'left' ? '' : 'auto',
                marginRight: imageAlign === 'right' ? '' : 'auto',
                display: 'block',
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              src={RenderVariableText(logoSrc)}
              alt={RenderVariableText(logoAltText)}
            />
            <Typography
              component="div"
              style={{
                textAlign,
              }}
            >
              <RenderVariableHtml htmlString={text} />
            </Typography>
            <div
              style={{
                padding: '8px 0',
                display: 'flex',
                flexFlow: 'row wrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {links.map((linkOption, index) => {
                const { link, text } = linkOption;
                return (
                  <Fragment key={`${link}-${text}`}>
                    <Typography
                      component="a"
                      href={link}
                      style={{
                        margin: `0 20px 0 ${index === 0 ? '0' : '20px'}`,
                        color,
                      }}
                    >
                      {text}
                    </Typography>
                    {index + 1 < links.length && <span>|</span>}
                  </Fragment>
                );
              })}
            </div>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const { contentItem, RenderVariableHtml, RenderVariableText } = props;
        const { data } = contentItem;
        const {
          color,
          backgroundColor,
          logoWidth,
          logoSrc,
          logoAltText,
          text,
          links,
          textAlign,
          imageAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        } = data;

        return `
          <mj-wrapper
            background-color="${backgroundColor}"
            padding-top="${paddingTop}px"
            padding-bottom="${paddingBottom}px"
            padding-left="${paddingLeft}px"
            padding-right="${paddingRight}px"
          >
            <mj-section
              padding="0"
            >
              <mj-column>
                <mj-image
                  align="${imageAlign}"
                  alt="${RenderVariableText(logoAltText)}"
                  src="${RenderVariableText(logoSrc)}"
                  width="${logoWidth}px"
                />
              </mj-column>
            </mj-section>
            <mj-section
              padding="0"
            >
              <mj-column>
                <mj-text
                  color="${color}"
                  align="${textAlign}"
                >
                  ${RenderVariableHtml(text)}
                </mj-text>
              </mj-column>
            </mj-section>
            <mj-section
              padding="8px 0px"
            >
              <mj-column>
                <mj-text
                  align="left"
                  color="${color}"
                >
                    ${links
                      .map((linkOption, index) => {
                        const { link, text } = linkOption;
                        return `
                    <a
                      href="${link}"
                      style="
                        margin: 0 20px 0 ${index === 0 ? '0' : '20px'};
                        text-transform: none;
                        text-decoration: underline;
                        color=${color}
                      "
                    >${text}</a>
                    ${index + 1 < links.length ? `<span>|</span>` : ''}`;
                      })
                      .join('')}
                </mj-text>
              </mj-column>
            </mj-section>
          </mj-wrapper> `;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        color: '#70707B',
        logoWidth: 100,
        logoSrc:
          'https://d1ccvuha0qk3lj.cloudfront.net/uploads/hostedFiles/footer_image.png',
        logoAltText: '',
        text:
          'Cras montes amet fusce. Turpis quis porta platea! Conubia primis metus, magnis viverra ante quisque. Platea primis litora lorem. Mus quis volutpat sollicitudin aenean euismod sagittis ac.',
        links: [
          {
            id: uuid(),
            text: 'Link 1',
            link: '',
            background: '',
          },
          {
            id: uuid(),
            text: 'Link 2',
            link: '',
            background: '',
          },
        ],
        textAlign: 'left',
        imageAlign: 'left',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              logoSrc: {
                controlType: 'variable-text-input',
                contentDataKey: 'logoSrc',
                data: {
                  label: 'Image Source',
                  variables,
                },
              },
              logoAltText: {
                controlType: 'variable-text-input',
                contentDataKey: 'logoAltText',
                data: {
                  label: 'Alt Description',
                  placeholder: 'Enter an image description',
                  variables,
                },
              },
              logoWidth: {
                controlType: 'slider',
                contentDataKey: 'logoWidth',
                data: {
                  label: 'Maximum Width',
                  minValue: 1,
                  maxValue: 480,
                  step: 1,
                  units: 'px',
                },
              },
              text: {
                controlType: 'rich-text-paragraph',
                contentDataKey: 'text',
                data: {
                  label: 'Footer Text',
                  variables,
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              color: {
                controlType: 'color-picker',
                contentDataKey: 'color',
                data: {
                  label: 'Text Color',
                },
              },
            },
          },
          {
            label: 'LINKS',
            controlsConfig: {
              links: {
                controlType: 'links-generator',
                contentDataKey: 'links',
                data: {
                  label: 'Options Picker',
                  showColorPickers: false,
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              imageAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'imageAlign',
                data: {
                  label: 'Image Alignment',
                  variant: 'items',
                },
              },
              textAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'textAlign',
                data: {
                  label: 'Text Alignment',
                  variant: 'text',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
