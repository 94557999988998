import DropZone from '../DropZone';
import DropLine from '../DropLine';
import {
  ContentItemDroppableType,
  DropData,
  HandleDropFunction,
} from '../utils/types';
import { handleCanDrop } from '../utils/helpers';
import { DRAGGABLE_TYPES } from '../utils/constants';
import * as S from './styles';

const DRAG_DIRECTION_INDEX_MAP = {
  top: 0,
  bottom: 1,
  left: 2,
  right: 3,
} as const;

type Props = {
  dropLineDataArray: Array<DropData>;
  handleDrop: HandleDropFunction;
  children: React.ReactNode;
  type: ContentItemDroppableType;
  disableDrop?: boolean;
  shallow?: boolean;
};

const DropZoneWrapper = ({
  dropLineDataArray,
  children,
  handleDrop,
  disableDrop = false,
  type = DRAGGABLE_TYPES.ROW,
  shallow = false,
}: Props) => {
  const commonProps = {
    type,
    handleDrop,
    disableDrop,
  };

  return (
    <DropZone
      type={type}
      shallow={shallow}
      onDrop={(item, dragDirection) => {
        const dropLineIndex = DRAG_DIRECTION_INDEX_MAP[dragDirection || 'top'];
        const dropLineData =
          dropLineDataArray[dropLineIndex] || dropLineDataArray[0];

        return handleDrop(dropLineData, item);
      }}
      canDrop={(item, dragDirection) => {
        const dropLineIndex = DRAG_DIRECTION_INDEX_MAP[dragDirection || 'top'];
        const dropLineData =
          dropLineDataArray[dropLineIndex] || dropLineDataArray[0];

        return handleCanDrop({
          item,
          data: dropLineData,
        });
      }}
    >
      {({ isOver, dragDirection }) => {
        const dropLineIndex = DRAG_DIRECTION_INDEX_MAP[dragDirection || 'top'];
        const dropLineData =
          dropLineDataArray[dropLineIndex] || dropLineDataArray[0];

        return type === DRAGGABLE_TYPES.COMPONENT ? (
          <S.VerticalWrapper position={dragDirection}>
            <DropLine
              data={dropLineData}
              isOver={isOver}
              isVertical={dragDirection === 'left' || dragDirection === 'right'}
              {...commonProps}
            />
            <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
          </S.VerticalWrapper>
        ) : (
          <S.HorizontalWrapper position={dragDirection}>
            <DropLine
              data={
                dragDirection === 'top'
                  ? dropLineDataArray[0]
                  : dropLineDataArray[1]
              }
              isOver={isOver}
              {...commonProps}
            />
            <div>{children}</div>
          </S.HorizontalWrapper>
        );
      }}
    </DropZone>
  );
};

export default DropZoneWrapper;
