import React, { createContext } from 'react';

type ApisContextProps = {
  apiUrl: string;
  cssCompilerApi: string;
  viewerUrl: string;
  mediaApi: string;
  googleMapApiKey: string;
};

export type ApisProviderProps = {
  apis: ApisContextProps;
};

export const ApisContext = createContext<ApisContextProps>({
  apiUrl: '',
  cssCompilerApi: '',
  viewerUrl: '',
  mediaApi: '',
  googleMapApiKey: '',
});

export const ApisProvider = (
  props: React.PropsWithChildren<ApisProviderProps>,
) => {
  const { apis, children } = props;
  return <ApisContext.Provider value={apis}>{children}</ApisContext.Provider>;
};
