// Taken from https://developers.google.com/maps/documentation/maps-static/get-api-key#node-js
// Converted to Typescript.

import crypto from 'crypto';

/**
 * Convert from 'web safe' base64 to true base64.
 *
 * @param  {string} safeEncodedString The code you want to translate
 *                                    from a web safe form.
 * @return {string}
 */
const removeWebSafe = (safeEncodedString: string): string => {
  return safeEncodedString.replace(/-/g, '+').replace(/_/g, '/');
};

/**
 * Convert from true base64 to 'web safe' base64
 *
 * @param  {string} encodedString The code you want to translate to a
 *                                web safe form.
 * @return {string}
 */
const makeWebSafe = (encodedString: string): string => {
  return encodedString.replace(/\+/g, '-').replace(/\//g, '_');
};

/**
 * Takes a base64 code and decodes it.
 *
 * @param  {string} code The encoded data.
 * @return {string}
 */
const decodeBase64Hash = (code: string): Buffer => {
  return Buffer.from(code, 'base64');
};

/**
 * Takes a key and signs the data with it.
 *
 * @param  {string} key  Your unique secret key.
 * @param  {string} data The url to sign.
 * @return {string}
 */
const encodeBase64Hash = (key: Buffer, data: string): string => {
  return crypto.createHmac('sha1', key).update(data).digest('base64');
};

/**
 * Sign a URL using a secret key.
 *
 * @param  {string} path   The url you want to sign.
 * @param  {string} secret Your unique secret key.
 * @return {string}
 */
export const sign = (path, secret) => {
  const url = new URL(path);
  const safeSecret = decodeBase64Hash(removeWebSafe(secret));
  const hashedSignature = makeWebSafe(
    encodeBase64Hash(safeSecret, `${url.pathname}${url.search}`),
  );
  const signedUrl = `${url.href}&signature=${hashedSignature}`;
  return signedUrl;
};
