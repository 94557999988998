import {
  RatingProps as RatingMUIProps,
  IconContainerProps,
} from '@material-ui/lab';
import React, { ChangeEvent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { kebabCase } from 'lodash';
import Box from '@material-ui/core/Box';
import { FormHelperText } from '@material-ui/core';
import { StyledRating } from './Rating.styles';

const getIconContainerComponent = (
  customIcons,
  selectedValue,
  hoveredValue,
) => {
  const addSelectedValue = (selectedValue, hoveredValue, value) => {
    if (hoveredValue === value) {
      return 'selected';
    } else if (hoveredValue < 0 && value === selectedValue) {
      return 'selected';
    }
    return 'not-selected';
  };

  const IconContainer = (props: IconContainerProps) => {
    const { value, className, ...other } = props;
    const customIcon = customIcons[value];
    return (
      <Box
        component="span"
        {...other}
        role="img"
        className={`${className} ${addSelectedValue(
          selectedValue,
          hoveredValue,
          value,
        )} `}
      >
        {customIcon.icon}
      </Box>
    );
  };
  return IconContainer;
};

export type CustomIconsType = {
  [index: string]: {
    icon: React.ReactElement;
    label?: string;
  };
};

export type RatingProps = {
  name?: string;
  iconColor: string;
  highlightColor: string;
  errorText?: string;
  size: 'small' | 'medium' | 'large';
  count: number;
  includeLabels: boolean;
  labels: string[];
  callback?: (event: ChangeEvent<any>, index: number | null) => void;
  onChange?: (event: ChangeEvent<any>, value: number | null) => void;
  customIcons?: CustomIconsType;
};

export const Rating = (props: RatingProps): JSX.Element => {
  const {
    customIcons,
    errorText,
    callback,
    includeLabels,
    labels,
    name = uuid(),
    count,
    size,
  } = props;

  const [selectedValue, setSelectedValue] = useState(0);
  const [hoveredValue, setHoveredValue] = useState(-1);

  const handleChange = (event, value, callback) => {
    setSelectedValue(value);
    if (callback) {
      callback(event, value);
    }
  };

  const handleChangeActive = (event, value) => {
    setHoveredValue(value);
  };

  const allProps: RatingMUIProps = {
    precision: 1,
    onChange: (event, index) => handleChange(event, index, callback),
    onChangeActive: (event, value) => handleChangeActive(event, value),
  };

  if (customIcons) {
    allProps.IconContainerComponent = getIconContainerComponent(
      customIcons,
      selectedValue,
      hoveredValue,
    );
  }

  const ratingStylesProps = {
    ...allProps,
    count,
    name: kebabCase(name),
  };

  const labelIndex = (hoveredValue !== -1 ? hoveredValue : selectedValue) - 1;
  const canShowLabel = labelIndex >= 0;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100px',
      }}
    >
      <StyledRating max={count} size={size} {...ratingStylesProps} />
      {includeLabels && (
        <Box
          style={
            canShowLabel
              ? { alignSelf: 'flex-start', marginLeft: '3px' }
              : {
                  visibility: 'hidden',
                }
          }
        >
          {canShowLabel ? labels[labelIndex] : 'Ty'}
        </Box>
      )}

      {errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
    </Box>
  );
};
