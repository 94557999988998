import { useContext } from 'react';
import { Colors } from '@whispir/ui-lib-v2';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { generateControls } from '../../../controls/generateControls';
import {
  KnownBaseContentItem,
  KnownContentTypeAndVersionMap,
} from '../../schemas/KnownContentTypeAndVersionMap';
import {
  renderMjmlBodyString,
  RenderedDynamicContent,
} from '../../../exportUtils';
import { contentCreationMap } from '../../content';
import { AnyContentItem } from '../../../framework/AnyContentItem';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { NoContentItem } from '../../../components/ReactDnD/NoContentItem';
import { ModeContext } from '../../../components/Contexts/ModeProvider';
import { getOutputVariablesForContentData } from '../../../framework/functions';
import { AbstractAllContentDataAsArray } from '../../../framework/ContentDefinitions';

const STACKING_WIDTH_THRESHOLD = 400;

const getColumnWidthToUse = ({
  componentsLength,
  columnWidthPx,
  windowWidth,
  columnWidthStackedPx,
}: {
  componentsLength: number;
  columnWidthPx: number;
  windowWidth: number;
  columnWidthStackedPx: number;
}) => {
  if (!componentsLength) return '100%';

  if (windowWidth > STACKING_WIDTH_THRESHOLD) return columnWidthPx;

  if (componentsLength > 1) {
    return columnWidthStackedPx;
  }

  return '100%';
};

export const LayoutColumnContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'layout-column'
> = {
  type: `layout-column`,
  label: `Columns`,
  icon: 'Columns',
  versions: {
    '1': {
      JsxFunction: (props) => {
        const { children, contentItem, formUpdateFunction, maxWidth } = props;
        const { data, id } = contentItem;
        const {
          backgroundColor,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          imageSrc,
          components,
          fullWidth,
          imageWidth,
          //TODO: Add 1/3 and 2/3 columns support
          // columnType,
        } = data;

        const windowWidth = maxWidth || window.innerWidth;

        const windowWidthToUse = windowWidth > 600 ? 600 : windowWidth;

        const columnWidthPx =
          windowWidthToUse / components.length -
          (paddingLeft / components.length + paddingRight / components.length);

        const columnWidthStackedPx =
          (600 - (paddingLeft + paddingRight)) / components.length;

        // TODO: Move to RenderedDynamicContent
        const columns = (
          <div
            style={{
              width: `${windowWidthToUse - paddingLeft - paddingRight}px`,
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection:
                windowWidth < STACKING_WIDTH_THRESHOLD ? 'column' : 'row',
            }}
          >
            {components.map((columnComponents, index) => (
              <div
                style={{
                  width: getColumnWidthToUse({
                    componentsLength: components.length,
                    columnWidthPx,
                    windowWidth,
                    columnWidthStackedPx,
                  }),
                  margin: '0 auto',
                }}
                key={`${id}_column_components_${index}`}
              >
                {columnComponents.length ? (
                  <RenderedDynamicContent
                    // Possibly you want a runtime check on this.
                    data={columnComponents as Array<AnyContentItem>}
                    contentCreationMap={contentCreationMap}
                    formUpdateFunction={formUpdateFunction}
                    maxWidth={columnWidthPx}
                  />
                ) : (
                  <NoContentItem isOver={false} text=" " />
                )}
              </div>
            ))}
          </div>
        );

        return (
          <div
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
              backgroundImage: `url(${imageSrc})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: `${imageWidth}%`,
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              boxSizing: 'border-box',
              margin: '0 auto',
              width: fullWidth ? '100%' : windowWidthToUse,
            }}
          >
            {children ? children : columns}
          </div>
        );
      },

      EmailFunction: (props) => {
        const { contentItem, ...rest } = props;
        const { data } = contentItem;
        const {
          backgroundColor,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          components,
          // fullWidth, // no fullWidth support in email
          imageSrc,
          imageWidth,
          //TODO: Add 1/3 and 2/3 columns support
          // columnType,
        } = data;

        const columnWidthPercent = 100 / components.length;

        const columnMaxWidthPx =
          600 / components.length -
          (paddingLeft / components.length + paddingRight / components.length);

        const mjmlBodyString = components.map(
          (
            columnData,
          ) => `<mj-column padding="0px 0px" width="${columnWidthPercent}%"> 
            ${
              columnData.length
                ? renderMjmlBodyString({
                    ...rest,
                    data: columnData as AnyContentItem[],
                    columnMaxWidthPx,
                  })
                : // Copying the styling of NoContentItem, but the backgroundColor is applied to the contanier so will be 100% width
                  // TODO: use mj-raw to fix the container backgroundColor
                  `<mj-text 
                container-background-color="${Colors.accent_1}"
                color="${Colors.accent_5}"
                width="100%"
                height="96px"
                text-align="center"
              >
              </mj-text>
              `
            }
            </mj-column>`,
        );

        return `
        <mj-section
          background-color="${backgroundColor}"
          background-url="${imageSrc}"
          background-repeat="no-repeat"
          background-position="center center"
          background-size="${imageWidth}%"
          padding-bottom="${paddingBottom}px"
          padding-left="${paddingLeft}px"
          padding-right="${paddingRight}px"
          padding-top="${paddingTop}px"
        >
          ${mjmlBodyString}
        </mj-section>
        `;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        backgroundColor: '#FFFFFF',
        imageWidth: 100,
        imageSrc: '',
        fullWidth: false,
        components: [[]],
        columnType: '1',
      }),
      PropertiesPanel: (props) => {
        const { contentMode } = useContext(ModeContext);
        const { variables, columnType } = props;
        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              columnType: {
                controlType: 'button-group-single-select',
                contentDataKey: 'columnType',
                data: {
                  buttonLabelValueMap:
                    columnType === 'Form'
                      ? {
                          'Form Column': 'Form',
                        }
                      : {
                          '1 Column': '1',
                          '2 Column': '2',
                          '3 Column': '3',
                          '4 Column': '4',
                          // '1/3 : 2/3': '1/3',
                          // '2/3 : 1/3': '2/3',
                        },
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              backgroundColor: {
                controlType: 'color-picker',
                contentDataKey: 'backgroundColor',
                data: {
                  label: 'Background Color',
                },
              },
              imageSrc: {
                controlType: 'variable-text-input',
                contentDataKey: 'imageSrc',
                data: {
                  label: 'Image Source',
                  variables,
                },
              },
              imageWidth: {
                controlType: 'slider',
                contentDataKey: 'imageWidth',
                data: {
                  label: 'Image Width',
                  // TODO: discuss min and max values
                  minValue: 10,
                  maxValue: 400,
                  step: 1,
                  units: '%',
                },
              },
              ...(contentMode !== 'email' && {
                fullWidth: {
                  controlType: 'boolean',
                  contentDataKey: 'fullWidth',
                  data: {
                    label: 'Full Width',
                  },
                },
              }),
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
      outputVariablesFunction: (contentItem) => {
        const contentData = contentItem.data.components.reduce(
          (acc, column) => [...acc, ...column],
          [] as Array<KnownBaseContentItem>,
        );

        const outputVariables = getOutputVariablesForContentData(
          contentData as AbstractAllContentDataAsArray<KnownContentTypeAndVersionMap>,
          contentCreationMap,
        );

        return outputVariables;
      },
    },
  },
};
