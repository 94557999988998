import { memo, useEffect } from 'react';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { contentCreationMap } from '../../../content/content';
import { useTrackingEvents } from '../../Contexts/TrackingEventsProvider';
import {
  RowOrColumnOrItemProps,
  isActualContentItemReference,
} from '../utils/types';
import { BuilderBlock } from '../BuilderBlock';
import { DRAGGABLE_TYPES } from '../utils/constants';
import * as S from './styles';

// Rename
const Component = memo(
  ({
    referenceData,
    contentMap,
    path,
    setSelectedItem,
    selectedItemId,
    handleDeleteItem,
    handleDuplicateItem,
  }: RowOrColumnOrItemProps) => {
    if (!isActualContentItemReference(referenceData)) {
      throw new Error('Component container received bad drop item reference');
    }

    const content = contentMap[referenceData.contentId];

    if (!content) {
      throw new Error('Something went wrong! Should have content to render');
    }

    const { trackContentMovedEvent } = useTrackingEvents();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, dragRef, preview] = useDrag({
      type: DRAGGABLE_TYPES.COMPONENT,
      item: {
        layoutType: DRAGGABLE_TYPES.COMPONENT,
        contentId: referenceData.contentId,
        path,
        componentType: content.type,
      },
      end: () => {
        trackContentMovedEvent({ contentType: content.type as any });
      },
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <S.Wrapper ref={dragRef} aria-label="Component">
        <BuilderBlock
          key={content.id}
          content={content}
          contentCreationMap={contentCreationMap}
          onContentSelect={setSelectedItem}
          isSelected={selectedItemId === content.id}
          onDeleteContent={() =>
            handleDeleteItem({
              path,
              id: referenceData.contentId,
              type: content.type,
            })
          }
          onContentDuplicate={() =>
            handleDuplicateItem({
              item: referenceData,
              path,
              type: content.type,
            })
          }
        />
      </S.Wrapper>
    );
  },
);

export default Component;
