import { CardMedia } from '@material-ui/core';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { parseVideoSrc } from './DisplayVideoPlayer.utils';
import { LinkHelperText } from './DisplayVideoPlayer.support';

const VIDEO_HEIGHT = 300;

export const DisplayVideoPlayerContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-video-player'
> = {
  type: 'display-video-player',
  label: 'Video Player',
  icon: 'Video',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, RenderVariableText }) => {
        const { data } = contentItem;
        const {
          src,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          backgroundColor,
        } = data;

        const renderedSrc = RenderVariableText(src);

        const { embed } = parseVideoSrc(renderedSrc);

        if (!embed) {
          return (
            <ContentContainer
              style={{
                paddingTop,
                paddingBottom,
                paddingLeft,
                paddingRight,
                backgroundColor,
              }}
            >
              <div
                style={{
                  border: 'none',
                  height: VIDEO_HEIGHT,
                }}
              >
                Video preview not available.
              </div>
            </ContentContainer>
          );
        }

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
            }}
          >
            <CardMedia
              component="iframe"
              title="Video Player"
              src={embed}
              style={{
                border: 'none',
                height: VIDEO_HEIGHT,
              }}
            />
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem: { data },
          RenderVariableText,
        } = props;
        const {
          src,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          backgroundColor,
        } = data;

        const renderedSrc = RenderVariableText(src);

        const { link, preview } = parseVideoSrc(renderedSrc);

        let previewMjml: string;
        if (preview) {
          previewMjml = `
            <mj-image
              container-background-color="${backgroundColor}"
              padding-bottom="${paddingBottom}px"
              padding-left="${paddingLeft}px"
              padding-right="${paddingRight}px"
              padding-top="${paddingTop}px"
              href="${link}"
              src="${preview}"
              height="${VIDEO_HEIGHT}px"
              css-class="image-cover"
            />`;
        } else {
          previewMjml = `
            <mj-text
              container-background-color="${backgroundColor}"
              padding-bottom="${paddingBottom}px"
              padding-left="${paddingLeft}px"
              padding-right="${paddingRight}px"
              padding-top="${paddingTop}px"
              height="${VIDEO_HEIGHT}px"
            >
              Video preview not available.
              <a 
                href="${link}"
                target="_blank"
              >
                Click here to view the video.
              </a>
            </mj-text>`;
        }

        return previewMjml;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        src: 'https://www.youtube.com/watch?v=oznr-1-poSU',
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              src: {
                controlType: 'variable-text-input',
                contentDataKey: 'src',
                data: {
                  label: 'Video URL',
                  variables,
                  helperText: (
                    <LinkHelperText
                      providers={[
                        { label: 'Youtube', icon: 'Youtube' },
                        { label: 'Vimeo', icon: 'Vimeo' },
                        { label: 'Wistia', icon: 'Wistia' },
                        { label: 'Daily Motion', icon: 'DailyMotion' },
                      ]}
                    />
                  ),
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {},
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
