import { convertVariablesToUIVariableGroups } from '@whispir/variables';
import { AbstractContentCreationMap } from '../../../../framework/ContentCreation';
import {
  AbstractContentTypes,
  AbstractVersionsForItemTypes,
  AbstractRecords,
} from '../../../../framework/ContentDefinitions';
import { getContentCreationItemForContentTypeAndVersion } from '../../../../framework/functions';
import { ContentPropertiesSubPanelProps } from '../../../../framework/ControlPanelProps';
import { useVariableGroups } from '../../../Contexts/VariableProvider';

/**
 * The sub panel that shows the dynamically generated controls for the given content item
 * @param props
 */
export const ContentPropertiesSubPanel = <
  T extends AbstractRecords,
  C extends AbstractContentCreationMap<T>,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
>(
  props: ContentPropertiesSubPanelProps<T, C, K, V>,
) => {
  const { contentItem, contentMap, onContentUpdate, inputVariables } = props;

  const variableGroups = useVariableGroups();
  const { uiVariableGroups } = convertVariablesToUIVariableGroups(
    inputVariables,
    variableGroups,
  );

  const columnType = contentItem.data['columnType']
    ? contentItem.data['columnType']
    : undefined;

  const contentCreationItem = getContentCreationItemForContentTypeAndVersion(
    contentMap,
    contentItem.type,
    contentItem.version,
  );

  const { PropertiesPanel } = contentCreationItem;

  return (
    <PropertiesPanel
      //The key is neccesary to force components to unmount
      key={contentItem.id}
      contentItem={contentItem}
      onContentUpdate={onContentUpdate}
      variables={uiVariableGroups}
      columnType={columnType}
    />
  );
};
