import { v4 as uuidv4 } from 'uuid';
import { flattenDeep } from 'lodash';
import { contentCreationMap } from '../../../content/content';
import { createNewContent } from '../../../framework/functions';

import {
  addItemToTree,
  removeItemFromTree,
  reorderElementsInTree,
} from '../../../utils/treeUtils';

import { AnyContentItem } from '../../../framework/AnyContentItem';
import {
  ActualContentItemReference,
  ColumnReference,
  DragItemPayload,
  isActualContentItemReference,
  isColumnReference,
  isRowReference,
  isSidebarReference,
  LayoutBundle,
  RowReference,
  isValidDropItem,
  DropData,
  ColumnType,
  isLayoutContentItem,
  AllDraggableReferencesWithExtraProps,
  isFormContentItem,
} from './types';
import { COLUMN_MAP, DRAGGABLE_TYPES, SINGULAR_COMPONENTS } from './constants';

// These three are just wrappers around the general treeUtils functions

export const reorderChildrenInTree = (
  children: Array<RowReference>,
  pathFrom: string[],
  pathTo: string[],
): Array<RowReference> => {
  const item = {
    children,
  };

  const result = reorderElementsInTree(
    item,
    pathFrom.map((v) => Number(v)),
    pathTo.map((v) => Number(v)),
    'children',
  );
  return result.children as Array<RowReference>;
};

export const removeChildFromTree = <T>(
  children: T[],
  pathToChild: Array<string>,
): T[] => {
  const item = {
    children,
  };

  const path = pathToChild.map((v) => Number(v));

  const result = removeItemFromTree(item, path, 'children');
  return result.children as T[];
};

export const addChildToTree = <T>(
  children: T[],
  pathToChild: Array<string>,
  itemToInsert: RowReference | ColumnReference | ActualContentItemReference,
): T[] => {
  const item = {
    children,
  };

  const path = pathToChild.map((v) => Number(v));

  const result = addItemToTree(item, path, itemToInsert, 'children');
  return result.children as T[];
};

export const handleMoveWithinParent = (
  layout: Array<RowReference>,
  contentMap: Record<string, AnyContentItem>,
  pathFrom: Array<string>,
  pathTo: Array<string>,
): LayoutBundle => {
  // It is necessary to subtract 1 from pathTo indexes that are greater than the pathFrom index
  // because there is always one more drop line than there are items
  const pathFromIndex = Number(pathFrom[pathFrom.length - 1]);
  let calculatedPathToIndex = Number(pathTo[pathTo.length - 1]);

  if (calculatedPathToIndex > pathFromIndex) {
    calculatedPathToIndex -= 1;
  }

  const calculatedPathTo = [
    ...pathTo.slice(0, pathTo.length - 1),
    `${calculatedPathToIndex}`,
  ];

  const newRows = reorderChildrenInTree(layout, pathFrom, calculatedPathTo);

  return {
    rows: newRows,
    contentMap,
  };
};

// handles moving from sidebar to parent too
export const handleMoveContentToDifferentParent = (
  layout: Array<RowReference>,
  contentMap: Record<string, AnyContentItem>,
  pathTo: Array<string>,
  item: DragItemPayload,
  pathFrom?: Array<string>,
): LayoutBundle => {
  let newRow: RowReference | null = null;
  let newColumn: ColumnReference | null = null;
  let newContentItem: ActualContentItemReference | null = null;
  let updatedContentMap = contentMap;

  switch (pathTo.length) {
    // Length 1 = top level (move to new row)
    case 1: {
      // Add new row as new content item in componentMap
      const newRowContentItem = createNewContent({
        contentKey: 'layout-column',
        contentCreationMap,
      });

      updatedContentMap = addContentItemToContentMap(
        contentMap,
        newRowContentItem,
      );

      // moving column outside into new row made on the fly
      if (isColumnReference(item)) {
        newRow = createNewRowReference(newRowContentItem.id, [item]);
      }

      // moving component outside into new row made on the fly
      else if (isActualContentItemReference(item)) {
        const { path, ...restOfItem } = item;
        newRow = createNewRowReference(newRowContentItem.id, [
          createNewColumnReference([restOfItem]),
        ]);
      }

      break;
    }
    // Length 2 - move inside row (ie. into a column)
    case 2: {
      // moving component outside into a row which creates column
      if (isActualContentItemReference(item)) {
        newColumn = createNewColumnReference([item]);
      }
      // Moving a column to a different row
      else if (isColumnReference(item)) {
        newColumn = item;
      }

      break;
    }
    case 3: {
      // we are moving an actual content item into another column
      if (!isActualContentItemReference(item)) {
        throw new Error('Dropped item is not an ActualContentItemReference');
      }

      newContentItem = item;
      break;
    }

    default: {
      throw new Error(`We had a split drop zone of length: ${pathTo.length}`);
    }
  }

  const itemToAdd = newRow || newColumn || newContentItem;

  if (!itemToAdd) {
    throw new Error('Something has gone wrong, no item to add');
  }

  let updatedLayout = layout;
  if (pathFrom) updatedLayout = removeChildFromTree(updatedLayout, pathFrom);
  updatedLayout = addChildToTree(updatedLayout, pathTo, itemToAdd);

  // keep columnType in sync for rows when dragging a new item from sidebar to a new column or creating a new column within a row
  updatedLayout.forEach((row) => {
    if (updatedContentMap[row.contentId]) {
      const currentColumnType =
        updatedContentMap[row.contentId]['data']['columnType'];

      if (COLUMN_MAP[currentColumnType] !== row.children.length) {
        updatedContentMap[row.contentId]['data'][
          'columnType'
        ] = `${row.children.length}`;
      }

      // Updating column type to a form if row contains a form item
      if (rowReferenceContainsFormItem(row, contentMap)) {
        updatedContentMap[row.contentId]['data']['columnType'] = 'Form';
      }

      // Updating column type to a normal column if there is no longer a form item
      if (
        row.children.length &&
        row.children[0].children.length === 0 &&
        updatedContentMap[row.contentId]['data']['columnType'] === 'Form'
      ) {
        updatedContentMap[row.contentId]['data']['columnType'] = '1';
      }
    }
  });

  return {
    rows: updatedLayout,
    contentMap: updatedContentMap,
  };
};

export const handleRowColumnChange = (
  rows: Array<RowReference>,
  columnType: ColumnType,
  rowIndex: number,
): LayoutBundle['rows'] => {
  const selectedRow = { ...rows[rowIndex] };
  const columns = COLUMN_MAP[columnType];

  if (!isRowReference(selectedRow)) {
    new Error('Sorry you are trying to change columns not on a row');
  }

  if (!columns) {
    new Error('Not a valid column');
  }

  const selectedRowChildren = [...rows[rowIndex].children];
  const newChildrenLength = columns - selectedRowChildren.length;

  if (newChildrenLength > 0) {
    const copiedRows = [...rows];
    const newChildrenToAdd = Array(newChildrenLength).fill(
      createNewColumnReference(),
    );
    const mergedChildren = [...selectedRowChildren, ...newChildrenToAdd];

    copiedRows[rowIndex] = { ...selectedRow, children: mergedChildren };

    return copiedRows;
  } else {
    const childrenIndexToStartRemovingFrom = columns - 1;
    const updatedSelectedRowChildren = selectedRowChildren.reduce<
      Array<ColumnReference>
    >((acc, child, index) => {
      if (index <= childrenIndexToStartRemovingFrom) {
        acc.push(child);
      } else {
        const lastChildInArray = { ...acc[acc.length - 1] };

        if (lastChildInArray) {
          acc[acc.length - 1] = {
            ...lastChildInArray,
            children: [...lastChildInArray.children, ...child.children],
          };
        }
      }

      return acc;
    }, []);

    const copiedRows = [...rows];
    copiedRows[rowIndex] = {
      ...selectedRow,
      children: updatedSelectedRowChildren,
    };

    return copiedRows;
  }
};

export const addContentIntoArray = (
  rows: Array<RowReference>,
  contentMap: Record<string, AnyContentItem>,
  contentItem: AnyContentItem,
  pathTo?: Array<string>,
): LayoutBundle => {
  const pathToUse = pathTo || [`${rows.length}`];

  if (isLayoutContentItem(contentItem)) {
    const children = new Array(COLUMN_MAP[contentItem.data.columnType]).fill(
      createNewColumnReference(),
    );
    const rowReference = createNewRowReference(contentItem.id, children);
    const newRows = addChildToTree(rows, pathToUse, rowReference);
    const newContentMap = addContentItemToContentMap(contentMap, contentItem);

    return {
      rows: newRows,
      contentMap: newContentMap,
    };
  } else {
    // it's not a layout container
    if (pathToUse.length === 1) {
      // Adding content at row level, need to add row as well
      const newRow = createNewContent({
        contentKey: 'layout-column',
        contentCreationMap,
      });

      // need to specify column type if it's a form item
      if (isFormContentItem(contentItem)) {
        newRow.data = {
          ...newRow.data,
          columnType: 'Form',
        };
      }

      const columnReference = createNewColumnReference();
      const rowReference = createNewRowFromContentItem(newRow);
      const actualContentItemReference = createNewActualContentItemReferenceFromContentItem(
        contentItem,
      );
      const updatedRowReferences = {
        ...rowReference,
        children: [
          { ...columnReference, children: [actualContentItemReference] },
        ],
      };

      const newRows = addChildToTree(rows, pathToUse, updatedRowReferences);

      const contentMapWithItemAdded = addContentItemToContentMap(
        contentMap,
        contentItem,
      );
      const newContentMapWithRowAdded = addContentItemToContentMap(
        contentMapWithItemAdded,
        newRow,
      );

      return {
        rows: newRows,
        contentMap: newContentMapWithRowAdded,
      };
    } else {
      // Adding content inside (existing) column
      const actualContentItemReference = createNewActualContentItemReferenceFromContentItem(
        contentItem,
      );
      const {
        // eslint-disable-next-line destructuring/no-rename
        rows: updatedRows,
        // eslint-disable-next-line destructuring/no-rename
        contentMap: updatedContentMap,
      } = handleMoveContentToDifferentParent(
        rows,
        contentMap,
        pathToUse,
        actualContentItemReference,
      );

      const mapToReturn = addContentItemToContentMap(
        updatedContentMap,
        contentItem,
      );
      return {
        rows: updatedRows,
        contentMap: mapToReturn,
      };
    }
  }
};

export const handleRemoveItemFromLayout = (
  layoutBundle: LayoutBundle,
  path: Array<string>,
  itemId: string,
): LayoutBundle => {
  // if the item is a form, need to change the column type of the row from Form to 1
  if (isFormContentItem(layoutBundle.contentMap[itemId])) {
    const formRowId = layoutBundle.rows[Number(path[0])].contentId;
    const formRow = layoutBundle.contentMap[formRowId];
    formRow.data = {
      ...formRow.data,
      columnType: '1',
    };
  }

  const componentsToDeleteFromRowDeletion = layoutBundle.rows.reduce<
    Array<string>
  >((acc, row) => {
    if (itemId === row.contentId) {
      row.children.forEach((column) => {
        column.children.forEach((component) => acc.push(component.contentId));
      });
    }

    return acc;
  }, []);
  const newRows = removeChildFromTree(layoutBundle.rows, path);
  const newContentMap = { ...layoutBundle.contentMap };

  // delete the row key from contentMap
  delete newContentMap[itemId];
  // delete components if there is any
  componentsToDeleteFromRowDeletion.forEach((id) => delete newContentMap[id]);

  return {
    rows: newRows,
    contentMap: newContentMap,
  };
};

// !important <---
export const handleDuplicateItemInLayout = (
  layout: Array<RowReference>,
  contentMap: Record<string, AnyContentItem>,
  path: string[],
  item: RowReference | ActualContentItemReference,
): LayoutBundle & {
  item: AnyContentItem;
} => {
  const splitDropZonePath = [
    ...path.slice(0, path.length - 1),
    `${Number(path[path.length - 1]) + 1}`,
  ];

  if (isRowReference(item)) {
    const row = item;
    const rowColumns = row.children;
    const newRowId = uuidv4();
    const oldComponentsToCopyKeyMap = {
      [row.contentId]: newRowId,
    };

    const newRowItem = {
      contentId: newRowId,
      layoutType: DRAGGABLE_TYPES.ROW,
      children: rowColumns.map((column) => ({
        layoutType: DRAGGABLE_TYPES.COLUMN,
        children: column.children.map((component) => {
          const newComponentId = uuidv4();
          oldComponentsToCopyKeyMap[component.contentId] = newComponentId;
          return { ...component, contentId: newComponentId };
        }),
      })),
    };

    const copiedComponents = Object.keys(oldComponentsToCopyKeyMap).reduce(
      (copiedComponentsAcc, oldComponentId) => {
        const updatedComponentId = oldComponentsToCopyKeyMap[oldComponentId];

        copiedComponentsAcc[updatedComponentId] = {
          ...contentMap[oldComponentId],
          id: updatedComponentId,
        };

        return copiedComponentsAcc;
      },
      {} as Record<string, AnyContentItem>,
    );

    return {
      rows: addChildToTree(layout, splitDropZonePath, newRowItem),
      contentMap: { ...contentMap, ...copiedComponents },
      item: copiedComponents[newRowItem.contentId],
    };
  } else if (isActualContentItemReference(item)) {
    const existingComponent = contentMap[item.contentId];

    if (!existingComponent) {
      throw new Error('No existing content to copy');
    }

    const uuid = uuidv4();
    const newLayoutItem = {
      ...item,
      contentId: uuid,
    };
    const newComponent = {
      ...existingComponent,
      id: uuid,
    };

    return {
      rows: addChildToTree(layout, splitDropZonePath, newLayoutItem),
      contentMap: { ...contentMap, [newComponent.id]: newComponent },
      item: newComponent,
    };
  } else throw new Error("We shouldn't have got here");
};

export const getJsxFunction = (component: AnyContentItem) => {
  const versionMap = contentCreationMap[component.type];
  const ContentConfig = versionMap.versions[component.version];

  if (!ContentConfig) {
    throw new Error(
      "Couldn't find a content creation item for that type and version",
    );
  }

  return ContentConfig.JsxFunction;
};

export const createNewColumnReference = (
  items: ActualContentItemReference[] = [],
): ColumnReference => ({
  layoutType: DRAGGABLE_TYPES.COLUMN,
  children: items,
});

export const createNewComponentReference = (
  contentItem: AnyContentItem,
): ActualContentItemReference => ({
  layoutType: DRAGGABLE_TYPES.COMPONENT,
  contentId: contentItem.id,
});

export function createNewActualContentItemReferenceFromContentItem(
  contentItem: AnyContentItem,
): ActualContentItemReference {
  if (isLayoutContentItem(contentItem)) {
    throw new Error(
      'Encountered a contentContainer when creating an actualContentItem',
    );
  }

  return {
    layoutType: DRAGGABLE_TYPES.COMPONENT,
    contentId: contentItem.id,
  };
}

export function addContentItemToContentMap(
  contentMap: Record<string, AnyContentItem>,
  item: AnyContentItem,
): Record<string, AnyContentItem> {
  const newContentMap = {
    ...contentMap,
    [item.id]: item,
  };

  return newContentMap;
}

export function createNewRowFromContentItem(
  contentItem: AnyContentItem,
): RowReference {
  if (!isLayoutContentItem(contentItem)) {
    throw new Error('Tried creating a row from a non contentContainer');
  }

  return createNewRowReference(contentItem.id);
}

export function createNewRowReference(
  rowId = uuidv4(),
  children = [createNewColumnReference()],
): RowReference {
  return {
    layoutType: DRAGGABLE_TYPES.ROW,
    contentId: rowId,
    children,
  };
}

export const handleDrop = (
  rows: Array<RowReference>,
  contentMap: Record<string, AnyContentItem>,
  originalPathTo: string,
  item: DragItemPayload,
): LayoutBundle => {
  const pathToSplit = originalPathTo.split('-');
  const parentPathTo = pathToSplit.slice(0, -1).join('-'); // Drop the last element
  // eslint-disable-next-line destructuring/no-rename
  const { path: pathFrom, ...restItem } = item;

  if (pathFrom === undefined) {
    throw new Error('Dropped item has no pathFrom');
  }

  const pathFromSplit = pathFrom.split('-');
  const parentPathFrom = pathFromSplit.slice(0, -1).join('-'); // Dropped an item

  // This is going to prevent Form sections from being able to have more then one display component
  if (pathToSplit.length > 1) {
    const foundRow = contentMap[rows[pathToSplit[0]].contentId];

    if (foundRow.data.columnType === 'Form') {
      return {
        rows: rows,
        contentMap: contentMap,
      };
    }
  }

  // 2. Splits are equal - they are at the same level
  // Pure move (no create)
  if (pathFromSplit.length === pathToSplit.length) {
    // 2.a. move within parent
    if (parentPathFrom === parentPathTo) {
      return handleMoveWithinParent(
        rows,
        contentMap,
        pathFromSplit,
        pathToSplit,
      );
    }

    return handleMoveContentToDifferentParent(
      rows,
      contentMap,
      pathToSplit,
      restItem,
      pathFromSplit,
    );
  }

  // 3. Move + Create
  // need to add new row
  const {
    // eslint-disable-next-line destructuring/no-rename
    rows: newRows,
    // eslint-disable-next-line destructuring/no-rename
    contentMap: newContentMap,
  } = handleMoveContentToDifferentParent(
    rows,
    contentMap,
    pathToSplit,
    restItem,
    pathFromSplit,
  );

  return {
    rows: newRows,
    contentMap: newContentMap,
  };
};

export const handleCanDrop = ({
  item,
  data,
}: {
  item: unknown;
  data: DropData;
}) => {
  if (isValidDropItem(item)) {
    const typedItem = item as DragItemPayload;
    const dropZonePath = data.path;
    const splitDropZonePath = dropZonePath.split('-');

    if (isSidebarReference(typedItem)) {
      // ensure that each row does not exceed 4 columns:
      if (
        // it's going to a new column
        splitDropZonePath.length === 2 &&
        // there are four columns
        data.childrenCount >= 4
      )
        return false;

      // Layouts components cannot be placed within other layouts
      if (
        typedItem.contentType.includes('layout') ||
        typedItem.contentType.includes('form')
      ) {
        return splitDropZonePath.length <= 1 ? true : false;
      }

      return true;
    } else {
      if (
        typedItem['componentType'] &&
        (typedItem as AllDraggableReferencesWithExtraProps).componentType.includes(
          'form',
        )
      ) {
        return splitDropZonePath.length <= 1 ? true : false;
      }

      const itemPath = typedItem.path;
      const splitItemPath = itemPath?.split('-');

      if (
        splitDropZonePath.length === 2 && // if the user tries to create another column to a different row
        data.childrenCount >= 4 // ensure that each row does not exceed more than 4 column items
      ) {
        return false;
      }

      if (
        splitDropZonePath.length === 2 && // if the user tries to create another column to a different row
        data.childrenCount >= 4 // ensure that each row does not exceed more than 4 column items
      ) {
        return false;
      }

      if (splitItemPath) {
        // Invalid (Can't drop a parent element (row) into a child (column))
        const parentDropInChild =
          splitItemPath.length < splitDropZonePath.length;
        if (parentDropInChild) return false;

        // Current item can't possible move to it's own location
        // We should resolve this at a later stage as it is a bit flakey
        // if (itemPath === dropZonePath) return false;

        // Current area
        if (splitItemPath.length === splitDropZonePath.length) {
          const pathToItem = splitItemPath.slice(0, -1).join('-');
          const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

          const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');
          const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

          if (pathToItem === pathToDropZone) {
            const nextDropZoneIndex = currentItemIndex + 1;
            if (nextDropZoneIndex === currentDropZoneIndex) return false;
          }
        }

        return true;
      }

      return false;
    }
  } else {
    return false;
  }
};

export const rowReferenceContainsFormItem = (
  row: RowReference,
  contentMap: Record<string, AnyContentItem>,
) => {
  const allColumnsThatHaveForms = row.children.map((column) =>
    column.children.filter(
      (component) =>
        contentMap[component.contentId] &&
        contentMap[component.contentId].type.includes('form'),
    ),
  );

  return flattenDeep(allColumnsThatHaveForms).length > 0;
};

export const rowReferenceContainsUnsubscribe = (
  row: RowReference,
  contentMap: Record<string, AnyContentItem>,
) => {
  const allColumnsThatHaveUnsubscribe = row.children.map((column) =>
    column.children.filter(
      (component) =>
        contentMap[component.contentId] &&
        SINGULAR_COMPONENTS.includes(contentMap[component.contentId].type),
    ),
  );

  return flattenDeep(allColumnsThatHaveUnsubscribe).length > 0;
};

export const getContentItemsWithinLayoutColumn = (
  contentItem: AnyContentItem,
  layoutArray: Array<RowReference>,
  contentMap: Record<string, AnyContentItem>,
) => {
  if (!isLayoutContentItem(contentItem)) {
    return [];
  }

  return layoutArray.reduce<Array<AnyContentItem>>((acc, row) => {
    if (contentItem.id === row.contentId) {
      row.children.forEach((column) => {
        column.children.forEach((component) =>
          acc.push(contentMap[component.contentId]),
        );
      });
    }
    return acc;
  }, []);
};
