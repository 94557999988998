import React from 'react';

import { EmptyStatePlaceholder } from './NoContentItem.styles';

export const NoContentItem = ({
  isOver,
  text,
}: {
  isOver: boolean;
  text?: string;
}) => {
  return text ? (
    <EmptyStatePlaceholder aria-label="No content" isOver={isOver}>
      {text}
    </EmptyStatePlaceholder>
  ) : (
    <EmptyStatePlaceholder aria-label="No content" isOver={isOver}>
      No content, drag <br />
      content from right
    </EmptyStatePlaceholder>
  );
};
