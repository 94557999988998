import { MobilePreview } from '@whispir/ui-lib-v2';
import { ResolvedVariable } from '@whispir/variables';
import { useState } from 'react';
import { RenderedDynamicContent } from '../../export';
import {
  AbstractContentCreationMap,
  FormUpdateFunction,
} from '../../framework/ContentCreation';
import {
  AbstractAllContentDataAsArray,
  AbstractRecords,
} from '../../framework/ContentDefinitions';
import { LoadingProvider } from '../Contexts/LoadingProvider';
import { ModeProvider } from '../Contexts/ModeProvider';
import { ScrollWrapper } from '../../components/ReactDnD/PreviewContent/styles';

const MOBILE_WIDTH = 344;

type RealViewerProps<T extends AbstractRecords> = {
  content: AbstractAllContentDataAsArray<T>;
  contentCreationMap: AbstractContentCreationMap<T>;
  formUpdateFunction: FormUpdateFunction;
  onSubmit: (data) => void;
  inputValues: Array<ResolvedVariable>;
  mobile?: boolean;
};
export const RealViewer = <T extends AbstractRecords>(
  props: RealViewerProps<T>,
) => {
  const {
    content,
    contentCreationMap,
    formUpdateFunction,
    onSubmit,
    inputValues,
    mobile,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    setIsSubmitting(true);
    onSubmit(e);
  };

  return mobile ? (
    <MobilePreview
      useHTMLMobileCase
      type="web"
      width={MOBILE_WIDTH}
      message={
        <ScrollWrapper>
          <LoadingProvider isLoading={isSubmitting}>
            <ModeProvider variableMode="viewer" inputValues={inputValues}>
              <form onSubmit={handleSubmit}>
                <RenderedDynamicContent
                  data={content}
                  contentCreationMap={contentCreationMap}
                  formUpdateFunction={formUpdateFunction}
                  maxWidth={MOBILE_WIDTH}
                />
              </form>
            </ModeProvider>
          </LoadingProvider>
        </ScrollWrapper>
      }
    />
  ) : (
    <LoadingProvider isLoading={isSubmitting}>
      <ModeProvider variableMode="viewer" inputValues={inputValues}>
        <form onSubmit={handleSubmit}>
          <RenderedDynamicContent
            data={content}
            contentCreationMap={contentCreationMap}
            formUpdateFunction={formUpdateFunction}
          />
        </form>
      </ModeProvider>
    </LoadingProvider>
  );
};
