import { ComponentType } from 'react';
import { FormControl } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { useOnMount } from '../../../utils/useOnmount';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { RatingProps } from './Rating';
import * as Ratings from './Ratings';

const types = {
  Stars: 'StarRating',
  Emojis: 'EmojiRating',
  Hearts: 'HeartRating',
};

const emojiFacesLabelsCount = 5;

const generateColorControlComponents = (ratingType) => {
  const colorControls = {
    [`highlightColor${ratingType}`]: {
      controlType: 'color-picker',
      contentDataKey: `highlightColor${ratingType}`,
      data: {
        label: 'Selected Color',
      },
    },
    [`iconColor${ratingType}`]: {
      controlType: 'color-picker',
      contentDataKey: `iconColor${ratingType}`,
      data: {
        label: 'Default Color',
      },
    },
  };
  return colorControls;
};

export const FormRatingsContentItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-ratings'
> = {
  type: 'form-ratings',
  label: 'Ratings',
  icon: 'Rating',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, formUpdateFunction }) => {
        const { id, data } = contentItem;
        const {
          header,
          ratingType,
          iconSize,
          backgroundColor,
          labelsMaker,
          buttonAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        } = data;

        const iconColor = data[`iconColor${ratingType}`];
        const highlightColor = data[`highlightColor${ratingType}`];

        const RatingComp = Ratings[
          types[ratingType]
        ] as ComponentType<RatingProps>;

        const onRatingChange = (event, index) => {
          formUpdateFunction(id, index);
        };

        useOnMount(() => {
          const defaultValue = 0;
          formUpdateFunction(id, defaultValue);
        });

        const { labels, includeLabels, count } = labelsMaker;

        return (
          <ContentContainer
            label={header}
            htmlFor={contentItem.id}
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
            }}
          >
            <FormControl
              id={contentItem.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent:
                  buttonAlign === 'left'
                    ? 'flex-start'
                    : buttonAlign === 'right'
                    ? 'flex-end'
                    : 'center',
              }}
            >
              <RatingComp
                size={iconSize}
                iconColor={iconColor}
                highlightColor={highlightColor}
                name={header + uuid()}
                count={ratingType === 'Emojis' ? emojiFacesLabelsCount : count}
                includeLabels={includeLabels}
                labels={labels.map((l) => l.value)}
                callback={onRatingChange}
              />
            </FormControl>
          </ContentContainer>
        );
      },

      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        header: 'Select your rating',
        ratingType: 'Stars',
        iconSize: 'large',
        iconColorStars: '#BDBDBD',
        highlightColorStars: '#FFB400',
        iconColorEmojis: '#BDBDBD',
        highlightColorEmojis: '#FFB400',
        iconColorHearts: '#BDBDBD',
        highlightColorHearts: '#DC6D6B',
        labelsMaker: {
          count: 5,
          includeLabels: false,
          labels: [
            {
              label: 'Label 1',
              value: 'Very Poor',
            },
            {
              label: 'Label 2',
              value: 'Poor',
            },
            {
              label: 'Label 3',
              value: 'OK',
            },
            {
              label: 'Label 4',
              value: 'Good',
            },
            {
              label: 'Label 5',
              value: 'Excellent',
            },
          ],
        },
        buttonAlign: 'left',
      }),
      PropertiesPanel: (props) => {
        const {
          contentItem: {
            data: { ratingType },
          },
        } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              header: {
                controlType: 'text',
                contentDataKey: 'header',
                data: {
                  label: 'Field Label',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              ratingType: {
                controlType: 'dropdown',
                contentDataKey: 'ratingType',
                data: {
                  label: 'Icon style',
                  orientation: 'horizontal',
                  options: [
                    {
                      label: 'Stars',
                      value: 'Stars',
                    },
                    {
                      label: 'Hearts',
                      value: 'Hearts',
                    },
                    {
                      label: 'Emojis',
                      value: 'Emojis',
                    },
                  ],
                },
              },
              iconSize: {
                controlType: 'dropdown',
                contentDataKey: 'iconSize',
                data: {
                  label: 'Icon Size',
                  options: [
                    {
                      label: 'Small',
                      value: 'small',
                    },
                    {
                      label: 'Medium',
                      value: 'medium',
                    },
                    {
                      label: 'Large',
                      value: 'large',
                    },
                  ],
                  orientation: 'horizontal',
                },
              },
              ...generateColorControlComponents(ratingType),
            },
          },
          {
            label: 'Options',
            controlsConfig: {
              labelsMaker: {
                controlType: 'labels-maker',
                contentDataKey: 'labelsMaker',
                data: {
                  label: 'Labels Maker',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              buttonAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'buttonAlign',
                data: {
                  label: 'Button Alignment',
                  variant: 'text',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },

      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'number',
            inputType: contentItem.type,
            label: contentItem.data.header,
          },
        ];
      },
    },
  },
};
