import { Button } from '@material-ui/core';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { generateControls } from '../../../controls/generateControls';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { getContentItemWidthToUse } from '../../../utils/contentItemUtils';

export const DisplayButtonContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-button'
> = {
  type: 'display-button',
  label: 'Button',
  icon: 'Button',
  versions: {
    '1': {
      JsxFunction: ({
        contentItem,
        RenderVariableText,
        RenderVariableHtml,
      }) => {
        const { id, data } = contentItem;
        const {
          text,
          href,
          color,
          buttonColor,
          backgroundColor,
          borderColor,
          fontSize,
          borderRadius,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          width,
        } = data;

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
              textAlign,
            }}
          >
            <Button
              id={id}
              component="a"
              href={RenderVariableText(href)}
              style={{
                color,
                backgroundColor: buttonColor,
                borderRadius,
                textTransform: 'none',
                border: `1px solid ${borderColor}`,
                padding: '10px 18px',
                width,
                fontSize,
                maxWidth: '100%',
                wordBreak: 'break-word',
              }}
            >
              <RenderVariableHtml htmlString={text} />
            </Button>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const {
          contentItem,
          RenderVariableHtml,
          RenderVariableText,
          columnMaxWidthPx,
        } = props;
        const { data } = contentItem;
        const {
          text,
          href,
          color,
          buttonColor,
          borderColor,
          backgroundColor,
          borderRadius,
          fontSize,
          textAlign,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          width,
        } = data;

        const widthToUse = getContentItemWidthToUse({
          width,
          columnMaxWidthPx,
          paddingLeft,
          paddingRight,
        });

        return `
          <mj-button
            container-background-color="${backgroundColor}"
            padding-bottom="${paddingBottom}px"
            padding-left="${paddingLeft}px"
            padding-right="${paddingRight}px"
            padding-top="${paddingTop}px"
            align="${textAlign}"
            background-color="${buttonColor}"
            border="1px solid ${borderColor}"
            border-radius="${borderRadius}px"
            color="${color}"
            font-size="${fontSize}px"
            href="${RenderVariableText(href) || 'blank'}"
            width="${widthToUse}px"
          >
            ${RenderVariableHtml(text)}
          </mj-button>`;
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        borderColor: '#5700D4',
        borderRadius: 4,
        buttonColor: '#6200EE',
        color: '#FFFFFF',
        fontSize: 14,
        href: '',
        text: 'Button Text',
        textAlign: 'center',
        width: 140,
      }),
      PropertiesPanel: (props) => {
        const { variables } = props;

        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              text: {
                controlType: 'variable-text-input',
                contentDataKey: 'text',
                data: {
                  label: 'Button Text',
                  variables,
                },
              },
              href: {
                controlType: 'variable-text-input',
                contentDataKey: 'href',
                data: {
                  label: 'Link URL',
                  variables,
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              color: {
                controlType: 'color-picker',
                contentDataKey: 'color',
                data: {
                  label: 'Text Color',
                },
              },
              buttonColor: {
                controlType: 'color-picker',
                contentDataKey: 'buttonColor',
                data: {
                  label: 'Button Color',
                },
              },
              borderColor: {
                controlType: 'color-picker',
                contentDataKey: 'borderColor',
                data: {
                  label: 'Border Color',
                  labelSize: 'large',
                },
              },
              fontSize: {
                controlType: 'slider',
                contentDataKey: 'fontSize',
                data: {
                  label: 'Font Size',
                  minValue: 1,
                  maxValue: 72,
                  step: 1,
                  units: 'px',
                },
              },
              borderRadius: {
                controlType: 'slider',
                contentDataKey: 'borderRadius',
                data: {
                  label: 'Border Radius',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              width: {
                controlType: 'slider',
                contentDataKey: 'width',
                data: {
                  label: 'Button Width',
                  minValue: 1,
                  maxValue: 600,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              textAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'textAlign',
                data: {
                  label: 'Alignment',
                  variant: 'items',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
