import React from 'react';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';

export const DisplayDividerContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-divider'
> = {
  type: 'display-divider',
  label: 'Divider',
  icon: 'Divider',
  versions: {
    '1': {
      JsxFunction: ({ contentItem }) => {
        const { data } = contentItem;
        const {
          borderStyle,
          dividerColor,
          backgroundColor,
          borderWidth,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          dividerAlign,
          dividerWidth,
        } = data;

        return (
          <ContentContainer
            style={{
              backgroundColor,
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              display: 'flex',
              flexDirection: 'column',
              alignItems:
                dividerAlign === 'center'
                  ? 'center'
                  : dividerAlign === 'left'
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <hr
              style={{
                width: `${dividerWidth}%`,
                margin: 0,
                border: 'none',
                borderTop: `${borderWidth}px ${borderStyle} ${dividerColor}`,
              }}
            />
          </ContentContainer>
        );
      },
      // DELETE IF NOT EMAIL APPROPRIATE
      EmailFunction: (props) => {
        const {
          contentItem: { data },
        } = props;
        const {
          borderStyle,
          dividerColor,
          backgroundColor,
          borderWidth,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          dividerAlign,
          dividerWidth,
        } = data;

        const marginStyle =
          dividerAlign === 'center'
            ? '0 auto'
            : dividerAlign === 'left'
            ? '0 0'
            : '0 0 0 auto';

        // Below does not render correctly in Outlook desktop clients (outlook web is fine)
        // TODO: possible solution is to use table elements with a styled border
        return `
        <mj-raw>
          <tr>
            <td
              style="
                background-color: ${backgroundColor};
                padding-bottom: ${paddingBottom}px;
                padding-left: ${paddingLeft}px;
                padding-right: ${paddingRight}px;
                padding-top: ${paddingTop}px;
              "
            >
              <hr
                style="
                  width: ${dividerWidth}%;
                  border: none;
                  border-top: ${borderWidth}px ${borderStyle} ${dividerColor};
                  margin: ${marginStyle};
                "
              />
            </td>
          </tr>
        </mj-raw>
        `;
      },
      // END DELETE
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        borderStyle: 'solid',
        borderWidth: 3,
        dividerWidth: 100,
        dividerAlign: 'center',
        dividerColor: '#E0E0E0',
      }),
      PropertiesPanel: (props) => {
        return generateControls(props, [
          {
            label: 'Styles',
            controlsConfig: {
              borderStyle: {
                controlType: 'dropdown',
                contentDataKey: 'borderStyle',
                data: {
                  label: 'Style',
                  orientation: 'horizontal',
                  options: [
                    {
                      label: 'Solid',
                      value: 'solid',
                    },
                    {
                      label: 'Dashed',
                      value: 'Dashed',
                    },
                    {
                      label: 'Dotted',
                      value: 'dotted',
                    },
                  ],
                },
              },
              borderWidth: {
                controlType: 'slider',
                contentDataKey: 'borderWidth',
                data: {
                  label: 'Weight',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              dividerWidth: {
                controlType: 'slider',
                contentDataKey: 'dividerWidth',
                data: {
                  label: 'Width',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: '%',
                },
              },
              dividerColor: {
                controlType: 'color-picker',
                contentDataKey: 'dividerColor',
                data: {
                  label: 'Divider Color',
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {
              dividerAlign: {
                controlType: 'alignment-selector',
                contentDataKey: 'dividerAlign',
                data: {
                  label: 'Alignment',
                  variant: 'items',
                },
              },
            },
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
