import { v4 as uuid } from 'uuid';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { useOnMount } from '../../../utils/useOnmount';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';
import { NumericSlider, getSliderMarks } from './NumericSlider';

export const FormSliderContentItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'form-slider'
> = {
  type: 'form-slider',
  label: 'Slider',
  icon: 'Slider',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, formUpdateFunction }) => {
        const { id, data } = contentItem;
        const {
          fieldLabel,
          requiredField,
          sliderColor,
          barColor,
          backgroundColor,
          showLabels,
          leftLabel,
          centerLabel,
          rightLabel,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          defaultValue,
        } = data;

        useOnMount(() => {
          formUpdateFunction(id, defaultValue);
        });

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft: paddingLeft,
              paddingRight: paddingRight,
              backgroundColor,
            }}
            label={`${fieldLabel} ${requiredField ? '*' : ''}`}
            htmlFor={contentItem.id}
          >
            <NumericSlider
              defaultValue={defaultValue}
              sliderColor={sliderColor}
              barColor={barColor}
              showLabels={showLabels}
              leftLabel={leftLabel}
              centerLabel={centerLabel}
              rightLabel={rightLabel}
              onChange={(value) => {
                formUpdateFunction(id, value);
              }}
            />
          </ContentContainer>
        );
      },
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        fieldLabel: 'Slider',
        requiredField: false,
        defaultValue: 5,
        sliderColor: '#6200EE',
        barColor: '#E3E3FF',
        showLabels: true,
        leftLabel: 'Not Likely',
        centerLabel: '',
        rightLabel: 'Very Likely',
      }),
      PropertiesPanel: (props) => {
        return generateControls(props, [
          {
            label: 'Content',
            controlsConfig: {
              fieldLabel: {
                controlType: 'text',
                contentDataKey: 'fieldLabel',
                data: {
                  label: 'Field Label',
                },
              },
              defaultValue: {
                controlType: 'dropdown',
                contentDataKey: 'defaultValue',
                data: {
                  label: 'Default Value',
                  orientation: 'horizontal',
                  options: getSliderMarks(),
                },
              },
              requiredField: {
                controlType: 'boolean',
                contentDataKey: 'requiredField',
                data: {
                  label: 'Required Field',
                },
              },
            },
          },
          {
            label: 'Styles',
            controlsConfig: {
              sliderColor: {
                controlType: 'color-picker',
                contentDataKey: 'sliderColor',
                data: {
                  label: 'Slider Color',
                },
              },
              barColor: {
                controlType: 'color-picker',
                contentDataKey: 'barColor',
                data: {
                  label: 'Bar Color',
                },
              },
            },
          },
          {
            label: 'Options',
            controlsConfig: {
              showLabels: {
                controlType: 'boolean',
                contentDataKey: 'showLabels',
                data: {
                  label: 'Add Labels',
                },
              },
              leftLabel: {
                controlType: 'text',
                contentDataKey: 'leftLabel',
                data: {
                  label: 'Left',
                },
                isVisible: props.contentItem.data.showLabels,
              },
              centerLabel: {
                controlType: 'text',
                contentDataKey: 'centerLabel',
                data: {
                  label: 'Center',
                },
                isVisible: props.contentItem.data.showLabels,
              },
              rightLabel: {
                controlType: 'text',
                contentDataKey: 'rightLabel',
                data: {
                  label: 'Right',
                },
                isVisible: props.contentItem.data.showLabels,
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right Padding',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
      outputVariablesFunction: (contentItem) => {
        return [
          {
            id: contentItem.id,
            valueType: 'number',
            inputType: contentItem.type,
            label: contentItem.data.fieldLabel,
            options: new Array(11).fill(true).map((v, i) => {
              return {
                label: `${i}`,
                id: uuid(),
                value: i === 5,
              };
            }),
          },
        ];
      },
    },
  },
};
