import { sanitize } from 'dompurify';
import { Typography } from '@material-ui/core';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';

export const DisplayCustomCodeContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-custom-code'
> = {
  type: 'display-custom-code',
  label: 'Custom Code',
  icon: 'CustomCode',
  versions: {
    '1': {
      JsxFunction: ({ contentItem, RenderVariableHtml }) => {
        const { data, id } = contentItem;
        const { codeEditor } = data;

        const config = {
          WHOLE_DOCUMENT: true,
        };

        const { css, html } = codeEditor;

        return (
          <ContentContainer>
            <Typography component="div">
              <RenderVariableHtml
                htmlString={sanitize(
                  `<style>${css}</style><div id="id-${id}">${html}</div>`,
                  config,
                )}
              />
            </Typography>
          </ContentContainer>
        );
      },
      EmailFunction: (props) => {
        const { contentItem, RenderVariableHtml } = props;
        const { data, id } = contentItem;
        const { codeEditor } = data;

        const { css, html } = codeEditor;

        // TODO: is it okay to style with a div like this in email?
        return `
          <mj-text>
            <style type="text/css">${css}</style>
            ${RenderVariableHtml(`<div id="id-${id}">${html}</div>`)}
          </mj-text>`;
      },
      defaultData: ({ id, contentMode }) => ({
        codeEditor: {
          scss: `.class-name {
  color: blue;
  padding: 10px; ${
    contentMode === 'email' || contentMode === 'test'
      ? "// Please be aware of limitations using <table> elements, such as the css 'padding' property cannnot be applied to <table> elements, only <tr> and <td> elements."
      : ''
  }

  // Supports SCSS!
  // https://sass-lang.com/guide
  &:hover {
    color: red;
  }
}`,
          css: `#id-${id} .class-name{color:blue;padding:10px}#id-${id} .class-name:hover{color:red}`,
          html:
            contentMode === 'email' || contentMode === 'test'
              ? `<table>
  <tr>
    <td class="class-name">Add email HTML content</td>
  </tr>
</table>`
              : `<div class="class-name">Add HTML content</div>`,
        },
      }),
      PropertiesPanel: (props) => {
        return generateControls(props, [
          {
            label: 'Code Editor',
            drawLine: false,
            controlsConfig: {
              codeEditor: {
                controlType: 'code-editor',
                contentDataKey: 'codeEditor',
                data: {},
              },
            },
          },
        ]);
      },
    },
  },
};
