import styled from 'styled-components';
import { commonStyles, RatingWrapperProps } from '../../Rating/Rating.styles';

const inactiveStyle = (iconColor) => `
  svg{
    fill: ${iconColor};
    color: #FFFFFF;
    path {
      stroke: ${iconColor};
    }
    circle {
      stroke: ${iconColor};
    }
  }
`;

const activeStyle = (highlightColor) => `
  svg{
    fill: ${highlightColor};
    color: #FFFFFF;
    path {
      stroke: ${highlightColor};
    }
    circle {
      stroke: ${highlightColor};
    }
  }
`;

export const RatingWrapper = styled.div<RatingWrapperProps>`
  [class*="MuiRating-icon"] {
    ${({ iconColor }) => inactiveStyle(iconColor)}
  }

  ${commonStyles()}

  [class*="MuiRating-iconHover"][class*="MuiRating-iconActive"] {
    ${({ highlightColor }) => activeStyle(highlightColor)}
  }

  .selected["class*="MuiRating-iconFilled"] {
    ${({ highlightColor }) => activeStyle(highlightColor)}
  }
`;
