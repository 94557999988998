export const getContentItemWidthToUse = ({
  width,
  columnMaxWidthPx,
  paddingLeft,
  paddingRight,
}: {
  width: number;
  columnMaxWidthPx: number | undefined;
  paddingLeft: number;
  paddingRight: number;
}) => {
  if (!columnMaxWidthPx) {
    return width;
  }

  const contentItemMaxWidthPx = columnMaxWidthPx - paddingLeft - paddingRight;

  return Math.min(contentItemMaxWidthPx, width);
};
