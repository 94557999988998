import { Icon } from '@whispir/ui-lib-v2';
import { RenderedDynamicContent } from '../../../export';
import {
  getAllContentCreationItemsForType,
  getMostRecentVersionForContentType,
} from '../../../framework/functions';
import {
  AbstractContentData,
  AbstractContentTypes,
  AbstractDefaultedData,
  AbstractRecords,
  AbstractVersionsForItemTypes,
} from '../../../framework/ContentDefinitions';
import { AbstractContentCreationMap } from '../../../framework/ContentCreation';
import * as OverlayStyles from '../Global.styles';
import DraggableItemControls from '../DraggableItemControls';
import * as S from './styles';

export type BuilderBlockProps<
  T extends AbstractRecords,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
> = {
  content: AbstractDefaultedData<T>;
  isSelected: boolean;
  onContentSelect: (contentItem: AbstractDefaultedData<T>) => void;
  contentCreationMap: AbstractContentCreationMap<T>;
  onDeleteContent: (contentItem: AbstractContentData<T, K, V>) => void;
  onContentDuplicate: (contentItem: AbstractContentData<T, K, V>) => void;
};

/**
 * And individual block of content in the preview area.
 * Has hover, click events etc.
 * @param props
 */
export const BuilderBlock = <
  T extends AbstractRecords,
  K extends AbstractContentTypes<T>,
  V extends AbstractVersionsForItemTypes<T, K>
>(
  props: BuilderBlockProps<T, K, V>,
) => {
  const {
    content,
    isSelected,
    onContentSelect,
    contentCreationMap,
    onDeleteContent,
    onContentDuplicate,
  } = props;
  const contentCreationItemVersion = getMostRecentVersionForContentType(
    contentCreationMap,
    content.type,
  );
  const isOutOfDate = contentCreationItemVersion !== content.version;
  const cciMeta = getAllContentCreationItemsForType(
    contentCreationMap,
    content.type,
  );
  const ariaSubject = `${contentCreationMap[content.type].label} ${content.id}`;

  return (
    // ACCESSIBILITY: We need accessibility consultants to assist with this:
    // See my question here: https://stackoverflow.com/questions/65401812/make-html-form-elements-display-only-non-in

    /* ACCESSIBILITY: - This has role 'button' - this is the element you click and drag to interact with the content */
    <S.Wrapper
      role="button"
      onClick={(e) => {
        if (e.stopPropagation) e.stopPropagation();
        !isSelected && onContentSelect(content);
      }}
      aria-label={`Draggable ${ariaSubject}`}
      isOutOfDate={isOutOfDate}
    >
      {/* ACCESSIBILITY: - I've put role 'presentation' on this - this is the content of the button - we want to say that 'the stuff inside isn't actual interactable' */}
      <OverlayStyles.Overlay
        isSelected={isSelected}
        role="presentation"
        aria-label={`Preview ${ariaSubject}`}
      >
        {/* ACCESSIBILITY: These are 'meta controls' - ie. delete, duplicate, for the content. Need to behave as buttonOverlayStyles. */}
        {isSelected && (
          <DraggableItemControls
            componentType={content.type}
            ariaSubject={ariaSubject}
            onDuplicateItem={() => onContentDuplicate(content)}
            onDeleteItem={() => onDeleteContent(content)}
          />
        )}
        <OverlayStyles.HoverIcon>
          <Icon icon={cciMeta.icon} />
        </OverlayStyles.HoverIcon>
        <OverlayStyles.ClickShield>
          <RenderedDynamicContent
            data={[content]}
            contentCreationMap={contentCreationMap}
            formUpdateFunction={() => undefined}
          />
        </OverlayStyles.ClickShield>
      </OverlayStyles.Overlay>
    </S.Wrapper>
  );
};
