import styled from 'styled-components';
import { Fonts, Colors } from '@whispir/ui-lib-v2';

export const ControlPanelHeader = styled.div`
  position: sticky;
  top: 0;
  margin-top: -24px;
  padding: 24px 24px 0;
  z-index: 3;
  background-color: white;
`;

export const ControlPanelHeaderTitle = styled.div`
  padding-top: 6px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-right: 8px;

    svg {
      fill: ${Colors.accent};
    }
  }
`;

export const StyleControlPanel = styled.div`
  overflow-y: auto;
  width: 530px;
  box-sizing: border-box;
  background-color: white;

  h3 {
    ${Fonts.subheadSerif};
    margin: 0;
  }

  @media only screen and (max-width: 1200px) {
    width: 410px;
  }

  .control-panel-contents {
    margin: 48px 24px 24px;
  }

  .divider {
    padding: 24px 0 0;
  }

  .content-group {
    margin-bottom: 20px;

    .content-group-label {
      ${Fonts.capsHeading};
      margin: 0;
    }

    .content-group-wrapper {
      /* Need negative margin to apply equal 8px margin to sides of each content item */
      margin: 0px -8px;

      > {
        margin: 8px;
      }
    }
  }
`;
