export const COMMON_DEFAULT_DATA = {
  // All content items except LayoutColumn use these defaults
  backgroundColor: '#FFFFFF00',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  paddingRight: 10,
} as const;

export const MAP_HEIGHT = 300;
