import { v4 as uuid } from 'uuid';
import { Button } from '@material-ui/core';
import { generateControls } from '../../../controls/generateControls';
import { AbstractContentCreationItemVersionMap } from '../../../framework/ContentCreation';
import { KnownContentTypeAndVersionMap } from '../../schemas/KnownContentTypeAndVersionMap';
import { ContentContainer } from '../../../components/ContentContainer/ContentContainer';
import { COMMON_DEFAULT_DATA } from '../../../utils/constants';

export const DisplayButtonGroupContentCreationItem: AbstractContentCreationItemVersionMap<
  KnownContentTypeAndVersionMap,
  'display-button-group'
> = {
  type: 'display-button-group',
  label: 'Button Group',
  icon: 'ButtonGroup',
  versions: {
    '1': {
      JsxFunction: ({ contentItem }) => {
        const { data } = contentItem;
        const {
          color,
          backgroundColor,
          borderRadius,
          buttonMargin,
          links,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          isVertical,
          width,
        } = data;

        return (
          <ContentContainer
            style={{
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              backgroundColor,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                flexDirection: isVertical ? 'column' : 'row',
                alignItems: isVertical ? 'center' : 'normal',
              }}
            >
              {links.map(({ id, text, link, background }) => (
                <Button
                  key={id}
                  component="a"
                  href={link}
                  style={{
                    backgroundColor: background,
                    borderRadius,
                    color,
                    margin: buttonMargin,
                    padding: '10px 18px',
                    textTransform: 'none',
                    width,
                  }}
                >
                  {text}
                </Button>
              ))}
            </div>
          </ContentContainer>
        );
      },
      // DELETE IF NOT EMAIL APPROPRIATE
      EmailFunction: ({ contentItem }) => {
        const { data } = contentItem;
        const {
          color,
          backgroundColor,
          borderRadius,
          buttonMargin,
          links,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          isVertical,
          width,
        } = data;

        const renderButton = ({ link, background, text }: typeof links[0]) => `
          <mj-column>
            <mj-button
              background-color="${background}"
              border-radius="${borderRadius}px"
              color="${color}"
              href="${link}"
              width="${width}px"
              padding="${buttonMargin}px"
            >
              ${text}
            </mj-button>
          </mj-column>`;

        const renderButtonGroupHorizontally = `
        <mj-section
          background-color="${backgroundColor}"
          padding-bottom="${paddingBottom}px"
          padding-left="${paddingLeft}px"
          padding-right="${paddingRight}px"
          padding-top="${paddingTop}px"
        >
          ${links.map((link) => renderButton(link))}
        </mj-section>`;

        const renderButtonGroupVertically = `
          ${links.map(
            (link) => `
            <mj-section
              background-color="${backgroundColor}"
              padding-bottom="${paddingBottom}px"
              padding-left="${paddingLeft}px"
              padding-right="${paddingRight}px"
              padding-top="${paddingTop}px"
            >
              ${renderButton(link)}
            </mj-section>`,
          )}
        `;
        return isVertical
          ? renderButtonGroupVertically
          : renderButtonGroupHorizontally;
      },
      // END DELETE
      defaultData: () => ({
        ...COMMON_DEFAULT_DATA,
        color: '#FFFFFF',
        borderRadius: 5,
        buttonMargin: 5,
        links: [
          {
            id: uuid(),
            text: 'Yes',
            link: 'Enter a link',
            background: '#6200EE',
          },
          {
            id: uuid(),
            text: 'No',
            link: 'Enter a link',
            background: '#6200EE',
          },
          {
            id: uuid(),
            text: 'Maybe',
            link: 'Enter a link',
            background: '#6200EE',
          },
        ],
        isVertical: false,
        width: 140,
      }),
      PropertiesPanel: (props) => {
        return generateControls(props, [
          {
            label: 'Styles',
            controlsConfig: {
              color: {
                controlType: 'color-picker',
                contentDataKey: 'color',
                data: {
                  label: 'Button Text Color',
                },
              },
              borderRadius: {
                controlType: 'slider',
                contentDataKey: 'borderRadius',
                data: {
                  label: 'Border Radius',
                  minValue: 1,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              width: {
                controlType: 'slider',
                contentDataKey: 'width',
                data: {
                  label: 'Button Width',
                  minValue: 1,
                  maxValue: 600,
                  step: 1,
                  units: 'px',
                },
              },
              buttonMargin: {
                controlType: 'slider',
                contentDataKey: 'buttonMargin',
                data: {
                  label: 'Space Between Buttons',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              isVertical: {
                controlType: 'boolean',
                contentDataKey: 'isVertical',
                data: {
                  label: 'Stack vertically',
                },
              },
              links: {
                controlType: 'links-generator',
                contentDataKey: 'links',
                data: {
                  label: 'Options Picker',
                  showColorPickers: true,
                },
              },
            },
          },
          {
            label: 'Position',
            drawLine: false,
            controlsConfig: {},
          },
          {
            subLabel: 'Padding',
            variant: 'grid',
            controlsConfig: {
              paddingTop: {
                controlType: 'slider',
                contentDataKey: 'paddingTop',
                data: {
                  label: 'Top',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingBottom: {
                controlType: 'slider',
                contentDataKey: 'paddingBottom',
                data: {
                  label: 'Bottom',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingRight: {
                controlType: 'slider',
                contentDataKey: 'paddingRight',
                data: {
                  label: 'Right',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
              paddingLeft: {
                controlType: 'slider',
                contentDataKey: 'paddingLeft',
                data: {
                  label: 'Left',
                  minValue: 0,
                  maxValue: 100,
                  step: 1,
                  units: 'px',
                },
              },
            },
          },
        ]);
      },
    },
  },
};
